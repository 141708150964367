import Amplify, { API, graphqlOperation } from "aws-amplify";
import { Link } from "gatsby";
import React, { Component, useEffect, useState } from "react";
import { Accordion, Button, Card, Col, DropdownButton, Row, Table,Dropdown } from "react-bootstrap";
import {
  FaPlus,
  FaTrashAlt,
  FaGripVertical,
  FaCaretDown,
} from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaInfoCircle } from "react-icons/fa";
import Select, { components } from "react-select";
import styled from "styled-components";
import config from "../../aws-exports";
import Notes from "./Notes";
import Move from "../../assets/move.svg";
import ScopeTypes from "../../constants/ScopeTypes";
import Tooltip from "rc-tooltip";
import DDLoader from '../../assets/pageload.gif';
import "rc-tooltip/assets/bootstrap.css";
import {isEmpty} from '../CommonComponents/helper';
import ScopeUnits from '../../constants/ScopeUnits';

import { getAlgorithm, deleteLineItem, updateLineItem, addDocSubLineItem, createDocLineItem } from "../../graphql/queries";
import { useSelector, useDispatch } from "react-redux";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import { GoKebabVertical } from "react-icons/go";
Amplify.configure(config);
const CaretDownIcon = () => {
  return <FaCaretDown color={"#000"} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
export default function LineItem(props) {
  const {consoleLog} = require('../commonFunctions.js');
  consoleLog("LineItem props: ", props);

  const updatedStructure = useSelector((state) => state.templateStructure);
  const [stData, setStData] = React.useState(
    useSelector((state) => state.templateStructure)
  );
  const [structureIndex, setStructureIndex] = React.useState(
    props.structureIndex
  );
  const [categoryId, setCategoryId] = React.useState(props.categoryId);
  const [defaultUnits, setDefaultUnits] = React.useState(
    useSelector((state) => state.defaultUnits)
  );
  const [UNITS, setUnits] = React.useState([]);
  const [reportUnits, setReportUnits] = React.useState([]);
  const dispatch = useDispatch();
  const [lineItemName,setLineItemName]=React.useState(props.line_item.name)
  const [reportType, setReportType] = React.useState([]);
  const [line_item_quantity, set_line_item_quantity] = React.useState(props.line_item.quantity);
  const [line_item_unit, set_line_item_unit] = React.useState(props.line_item.unit);
  const [line_item_cost, set_line_item_cost] = React.useState(Number.parseFloat(props.line_item.cost).toFixed(2));
  const [line_item_waste, set_line_item_waste] = React.useState(Number.parseFloat(props.line_item.waste).toFixed(2));

  let adjusted_quantityx = +props.line_item.quantity+(props.line_item.quantity*props.line_item.waste/100);
  adjusted_quantityx = Number.parseFloat(adjusted_quantityx).toFixed(2);
  const [line_item_adjusted_quantity, set_line_item_adjusted_quantity] = React.useState(adjusted_quantityx);

  let net_costx = 0;
  if(adjusted_quantityx == 0){
    net_costx = props.line_item.cost * props.line_item.quantity;
  }else{
    net_costx = props.line_item.cost * adjusted_quantityx;
  }
  const [line_item_net_cost, set_line_item_net_cost] = React.useState(Number.parseFloat(net_costx).toFixed(2));

  const [line_item_qty_input, set_line_item_qty_input] = React.useState(false);
  const [line_item_labor_cost, set_line_item_labor_cost] = React.useState(Number.parseFloat(props.line_item.labor_cost).toFixed(2));
  const [line_item_material_cost, set_line_item_material_cost] = React.useState(Number.parseFloat(props.line_item.material_cost).toFixed(2));
  const [line_item_overhead, set_line_item_overhead] = React.useState(Number.parseFloat(props.line_item.overhead).toFixed(2));
  const [line_item_profit, set_line_item_profit] = React.useState(Number.parseFloat(props.line_item.profit).toFixed(2));
  const [line_item_adjusted_cost, set_line_item_adjusted_cost] = React.useState(props.line_item.adjusted_cost);

  const [ddLoader, setDdLoader] = React.useState(false);

  const [onBlurFlag, setOnBlurFlag] = React.useState(true);

  const [editLineItem, toggleEditLineItem] = React.useState(false);
  const [showAdvanceOpt, toggleShowAdvanceOpt] = React.useState(false);
  const [showReport, toggleShowReport] = React.useState(false);
  const [line_item, set_line_item] = React.useState(
    props.line_item ? props.line_item : []
  );
  const [algoValue, setAlgoValue] = React.useState('');
  const [selectedReportType, setSelectedReportType] = useState(null); // null initially since it's dynamic
  const [selectedReportUnit, setSelectedReportUnit] = useState(null); // null initially since it's dynamic


  // consoleLog('props.line_item.sub_line_item>>>'+props.line_item.name,props.line_item.sub_line_item);

  let subLineItems = props.line_item.sub_line_item;
  if(!subLineItems || subLineItems === "[]"){
    subLineItems = [];
  }
  else if(typeof subLineItems == 'string'){
    consoleLog("sub line items: ", props.line_item.sub_line_item);
    consoleLog("sub line items JSON: ", JSON.parse(props.line_item.sub_line_item));
    subLineItems = JSON.parse(props.line_item.sub_line_item);
  }

  const [sub_line_item, set_sub_line_item] = React.useState(subLineItems);

  // consoleLog('props.line_item.sub_line_item after>>>'+props.line_item.name,sub_line_item);

  const [applyOPIsChecked, setApplyOPIsChecked] = useState(
    props.line_item.apply_op
  );
  const [hideUnitPriceColumn, setHideUnitPriceColumn] = useState(
    props.line_item.remove_prices
  );
  const [isChecked, setIsChecked] = useState(false);
  const [showCheckBox, toggleShowCheckBox] = React.useState(false);
  // const [dragPositionNotes, setDragPositionNotes]  = useState(sub_line_item)

  consoleLog("props.apply_op>", props.apply_op);

  function checkNumber(x) {
    if(typeof x == 'number' && !isNaN(x) && x > -1){
        if (Number.isInteger(x)) {
            console.log(`checkNumber ${x} is integer.`);
            return true;
        }
        else {
            console.log(`checkNumber ${x} is a float value.`);
            return true;
        }
    
    } else {
        console.log(`checkNumber ${x} is not a number`);
        return false;
    }
  }

  useEffect(() => {
    setStData(updatedStructure);
    setCategoryId(props.categoryId);
    set_line_item(props.line_item ? props.line_item : []);
    let subLineItems = props.line_item.sub_line_item;
    if(!subLineItems || subLineItems === "[]"){
      subLineItems = [];
    }

    if(typeof subLineItems == 'string'){
      set_sub_line_item(JSON.parse(props.line_item.sub_line_item));
    }
    else{
      set_sub_line_item(subLineItems);
    }

    // consoleLog('useEffect sub_line_item>>>',sub_line_item);
    // consoleLog('useEffect sub_line_item typeof>>>', typeof sub_line_item);
    // consoleLog('useEffect sub_line_item json parse>>>', JSON.parse(sub_line_item));
    setStructureIndex(props.structureIndex);
    consoleLog("Strucpostture on useEffect sturctureIndex: ", structureIndex);

    // setting default units as per require format
    let d_units = [{ value: "", label: "(None)" }];
    for (let i = 1; i < defaultUnits.length; i++) {
      d_units.push({
        value: defaultUnits[i].name,
        label: defaultUnits[i].name,
      });
    }
    consoleLog('d_units>>>', d_units);
    setUnits(d_units);
    let report_types = [{ value: "(None)", label: "(None)" }];
    for (let i = 0; i < ScopeTypes.length; i++) {
      report_types.push({
        value: ScopeTypes[i].id,
        label: ScopeTypes[i].title,
      });
    }
    setReportType(report_types);
    getAlgoValue(props.line_item.algorithm);
  }, [
    updatedStructure,
    props.categoryId,
    props.line_item,
    props.line_item.sub_line_item,
    structureIndex,
  ]);

  const handleLIQuantityChangeValue = (e) => {
    line_item_qty_input == false && set_line_item_qty_input(true);
    
    let val = e.target.value;
    if(e.target.value == '' || e.target.value == null){
      val = 0.00;
    }
    calculateQuantity(val);
  };

  const calculateQuantity = (val) => {
    consoleLog("handleLIQuantityChangeValue value: ",val);
      let allData = stData;

      let li_cost = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).cost;
      if(li_cost == '' || li_cost == null){
        li_cost=0;
      }
      li_cost = 1 * li_cost;
      consoleLog("handleLIQuantityChangeValue li_cost: ",li_cost);

      let li_waste = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).waste;
      if(li_waste == '' || li_waste == null){
        li_waste=0;
      }
      li_waste = 1 * li_waste;
      consoleLog("handleLIQuantityChangeValue li_waste: ",li_waste);

      let percentage = (li_waste / 100) * val;

      percentage = +val + +percentage;
      percentage = Math.round(percentage * 100) / 100;
      let r_percentage = percentage.toFixed(2);
      r_percentage = Number.parseFloat(percentage);

      var prod = r_percentage * li_cost;
      let final_cost = Number.parseFloat(prod).toFixed(2);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).net_cost = final_cost;
      set_line_item_net_cost(final_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).quantity = val;
      set_line_item_quantity(val);
      line_item.quantity = val;

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity = r_percentage;
      set_line_item_adjusted_quantity(r_percentage);

      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      props.calculateSubTotal(allData);
      props.calculateStructureTotal();
      props.calculateCategoryTotal();
    // }
  };
  
  async function handleLineItemBlurValue(e){
    // consoleLog("handleLineItemBlurValue e.target.value: ",e.target.value);
    if(onBlurFlag){
      if(typeof props.url_params.doc_id != 'undefined'){ //check if its a document [this function will only run for documents]
        let allData = stData;
        let line = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id);
        consoleLog('handleLineItemBlurValue line',line);
        consoleLog('handleLineItemBlurValue line.doc_id',line.doc_id);
        consoleLog('handleLineItemBlurValue props.url_params.doc_id',props.url_params.doc_id);
        if(line.doc_id == props.url_params.doc_id && typeof line.doc_line_item_id != 'undefined'){ // check if LineItem is created or not [update]
          consoleLog('handleLineItemBlurValue update');
          consoleLog('handleLineItemBlurValue doc_line_item_id',line.doc_line_item_id);
          consoleLog('handleLineItemBlurValue adjusted_quantity',line_item_adjusted_quantity);
          consoleLog('handleLineItemBlurValue cost',line_item_cost);
          consoleLog('handleLineItemBlurValue quantity',line_item_quantity);
          consoleLog('handleLineItemBlurValue unit',line_item_unit);
          consoleLog('handleLineItemBlurValue waste',line_item_waste);
          consoleLog('handleLineItemBlurValue labor_cost',line_item_labor_cost);
          consoleLog('handleLineItemBlurValue material_cost',line_item_material_cost);
          consoleLog('handleLineItemBlurValue overhead',line_item_overhead);
          consoleLog('handleLineItemBlurValue profit',line_item_profit);
          consoleLog('handleLineItemBlurValue apply_op',applyOPIsChecked);
          // doc_line_item_id, adjusted_quantity, cost, quantity, unit, waste, labor_cost, material_cost, overhead, profit, apply_op
          try{  
            const update_line_item = await API.graphql({
              query: updateLineItem,
              variables: {
                name:`${lineItemName}`,
                doc_line_item_id: line.doc_line_item_id,
                adjusted_quantity: line_item_adjusted_quantity,
                cost: line_item_cost,
                quantity: line_item_quantity,
                unit: line_item_unit,
                waste: line_item_waste,
                labor_cost: line_item_labor_cost,
                material_cost: line_item_material_cost,
                overhead: line_item_overhead,
                profit: line_item_profit,
                apply_op: +applyOPIsChecked,
                remove_prices: +hideUnitPriceColumn
              },
            });
            consoleLog('handleLineItemBlurValue update_line_item', update_line_item);
            props.updateDocOnElasticSearch();
          }catch(err){
            console.error(err);
          }
        }else{ // create
          setOnBlurFlag(false);
          consoleLog('handleLineItemBlurValue create');
          let doc_struct_id = allData.structures[structureIndex].doc_structure_id;
          let doc_cat_id = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).doc_category_id;
          consoleLog('handleLineItemBlurValue doc_struct_id', doc_struct_id);
          consoleLog('handleLineItemBlurValue doc_cat_id', doc_cat_id);

          if(typeof line.color == 'undefined'){
            line.color = '';
          }
          if(typeof line.remove_prices == 'undefined'){
            line.remove_prices = 0;
          }
          if(typeof line.sort_order == 'undefined'){
            line.sort_order = 0;
          }
          try{
            const create_line_item = await API.graphql({
              query: createDocLineItem,
              variables: {
                // doc_type_id
                // status
                doc_id:props.url_params.doc_id*(1),
                doc_structure_id:doc_struct_id*(1),
                doc_category_id:doc_cat_id*(1),
                line_item_id:line.line_item_id, //
                manufacturer_id:line.manufacturer_id*(1), //
                sku:line.sku, //
                scope_type_id:line.scope_type_id*(1), //
                line_item_code:line.line_item_code, //
                name:line.name,
                description:line.description, //
                cost:line.cost*(1), //
                quantity:line.quantity*(1), //
                adjusted_quantity:line.adjusted_quantity, //
                unit:line.unit, //
                convert_to:line.convert_to, //
                ratio_from:line.ratio_from*(1), //
                ratio_to:line.ratio_to*(1), //
                convert_round:line.convert_round*(1), //
                algorithm:line.algorithm, //
                labor_cost:line.labor_cost*(1), //
                material_cost:line.material_cost*(1), //
                profit:line.profit*(1), //
                overhead:line.overhead*(1), //
                waste:line.waste*(1), //
                notes:line.notes, //
                sub_line_item:line.sub_line_item, //
                manufacturer:line.manufacturer,//
                apply_op:line.apply_op, //
                product:line.product, //
                color:line.color,
                remove_prices:line.remove_prices,
                sort_order:line.sort_order
              },
            });
            consoleLog('handleLineItemBlurValue line.sub_line_item', typeof line.sub_line_item);
            consoleLog('handleLineItemBlurValue create_line_item', create_line_item);
            let new_line_item_id = JSON.parse(create_line_item.data.createDocLineItem).data.doc_line_item_id;
            consoleLog('handleLineItemBlurValue new_line_item_id', new_line_item_id);

            allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).doc_line_item_id = new_line_item_id;
            allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).doc_structure_id = doc_struct_id*(1);
            allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).doc_category_id = doc_cat_id*(1);
            allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).doc_id = props.url_params.doc_id*(1);
            consoleLog('handleLineItemBlurValue allData', allData);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
            setStData(allData);
            props.updateDocOnElasticSearch();
          }catch(err){
              console.error(err);
          }
          setOnBlurFlag(true);
        }
      }
    }
  }
  const handleLINameChange = (e) => {
    let val = e.target.value;
   
      let allData = stData;
      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).name = val;
      setLineItemName(e.target.value)
      line_item.name = val;
      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
  };
  const handleLIAdjQuantityChangeValue = (e) => {
    line_item_qty_input && set_line_item_qty_input(false);
    
    let val = e.target.value;
    consoleLog("handleLIAdjQuantityChangeValue e.target.value: ",e.target.value);
    if(e.target.value == '' || e.target.value == null){
      val = 0.00;
    }
      let allData = stData;

      let li_cost = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).cost;
      if(li_cost == '' || li_cost == null){
        li_cost=0;
      }
      li_cost = 1 * li_cost;
      consoleLog("handleLIAdjQuantityChangeValue li_cost: ",li_cost);

      let li_waste = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).waste;
      if(li_waste == '' || li_waste == null){
        li_waste=0;
      }
      li_waste = 1 * li_waste;
      consoleLog("handleLIAdjQuantityChangeValue li_cost: ",li_waste);

      let r_percentage = val * 100 / (100 + li_waste);
      r_percentage = Number.parseFloat(r_percentage).toFixed(2);

      var prod = val * li_cost;
      let final_cost = Number.parseFloat(prod).toFixed(2);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).quantity = r_percentage;
      set_line_item_quantity(r_percentage);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).net_cost = final_cost;
      set_line_item_net_cost(final_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity = val;
      set_line_item_adjusted_quantity(val);
      line_item.adjusted_quantity = val;
      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      props.calculateSubTotal(allData);
      props.calculateStructureTotal();
      props.calculateCategoryTotal();
  };
  const handleLIWasteFactorChangeValue = (e) => {
      let val = e.target.value;
      consoleLog("handleLIWasteFactorChangeValue e.target.value: ",e.target.value);
      if(e.target.value == '' || e.target.value == null){
        val = 0.00;
      }

      let allData = stData;
      let currentLineItem =  allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id);

      let li_cost = currentLineItem.cost;
      if(li_cost == '' || li_cost == null){
        li_cost=0;
      }
      li_cost = 1 * li_cost;
      consoleLog("handleLIWasteFactorChangeValue li_cost: ",li_cost);

      let li_quantity = line_item_quantity;
      if(li_quantity == '' || li_quantity == null){
        li_quantity=0;
      }
      li_quantity = 1 * li_quantity;

      let li_adjusted_quantity = line_item_adjusted_quantity;
      if(li_adjusted_quantity == '' || li_adjusted_quantity == null){
        li_adjusted_quantity=0;
      }
      li_adjusted_quantity = 1 * li_adjusted_quantity;

      let valx = 1 * val;
      let qty = 0;

      qty = li_quantity + ((valx * li_quantity)/ 100);
      qty = Number.parseFloat(qty).toFixed(2);
      
      currentLineItem.adjusted_quantity = qty;
      set_line_item_adjusted_quantity(qty);

      let final_cost = qty * li_cost;
      final_cost = Number.parseFloat(final_cost).toFixed(2);
  
      currentLineItem.net_cost = final_cost;
      set_line_item_net_cost(final_cost);

      currentLineItem.waste = val;
      set_line_item_waste(val);
      line_item.waste = val;

      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      props.calculateSubTotal(allData);
      props.calculateStructureTotal();
      props.calculateCategoryTotal();
    // }
  };
  const handleChangeScopeUnit = (e) => {
    // alert(e.value)
    setSelectedReportUnit(e);
    consoleLog("handleChangeScopeUnit e: ", e);
    if(typeof e.value === 'object') {
      let val = e.value;
      let qty = parseFloat(val.quantity);
      let algo = val.algo;
      let unit = val.unit;

      if(algo == 'ice_water_shield_eave1'){
        qty = qty * 3;
      }
      else if(algo == 'ice_water_shield_eave2'){
        qty = qty * 6;
      }
      else if(algo == 'ice_water_shield_valleys'){
        qty = qty * 3;
      }
      else if(algo == 'ice_water_shield_eaves_valleys'){
        qty = qty * 3;
      }
      
      calculateQuantity(qty);
      updateAlgo(algo);
      
      if(!isEmpty(unit)) {
          updateUnit(unit);
      }
      
      setAlgoValue(e.label);

      handleLineItemBlurValue();

    }
    else if(e.label == '(None)') {
      setAlgoValue('None');      
    }

    /*set_line_item_unit(e.value);
    line_item.unit = e.value;

    let allData = stData;
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find((l) => l.line_item_id == line_item.line_item_id).unit =
      e.value;
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });*/
  };
  const handleLIUnitChangeValue = (e) => {
    consoleLog("handleLIUnitChangeValue e: ", e);
    updateUnit(e.value);
  }

  const updateUnit = (value) => {
    set_line_item_unit(value);
    line_item.unit = value;

    let allData = stData;
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find((l) => l.line_item_id == line_item.line_item_id).unit = value;
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);
    props.calculateStructureTotal();
    props.calculateCategoryTotal();
  };

  const updateAlgo = (value) => {
      let allData = stData;
      allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l.line_item_id == line_item.line_item_id).algorithm = value;
      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
  }

  const getAlgoValue = (algorithm) => {
      if(!isEmpty(algorithm)) {
          let constantValue = true;
          let quantities = stData.quantities;

          if(typeof quantities === 'string'){
              quantities = JSON.parse(stData.quantities);
          }
          
          let structure = quantities && quantities.length
              ? quantities.find(quantity => quantity.structure_id == props.structureId)
              : null;
          
          if(!isEmpty(structure)) { 

              for(const scope of structure.scopes) {
                  for (const [key, value] of Object.entries(scope.quantities)) { 
                      if(key === algorithm){
                          constantValue = false;
                          setAlgoValue(`${value.name} (${value.quantity} ${value.unit})`);
                          break;
                      }
                  }
              }
          }
          if(constantValue) {
              for(const type of Object.keys(ScopeUnits)) {
                  for (const [key, value] of Object.entries(ScopeUnits[type])) { 
                      if(key === algorithm){
                          setAlgoValue(`${value.name} (${value.quantity} ${value.unit})`);
                          break;
                      }
                  }
              }
          }
      }
  }
  const handleLReportTypeChangeValue = (e) => {
    setSelectedReportType(e);
    consoleLog("handleLReportTypeChangeValue e: ", e);
    // alert(e.value);
    if(e.label !== '(None)') {
      fetchAlgo(e.label);
     
    }
    setSelectedReportUnit({ label: '(None)', value: '(None)' });
    setAlgoValue('None');  
    // set_line_item_unit(e.value)
    // line_item.unit = e.value

    // let allData = stData;
    // allData.structures[structureIndex].categories.find(c => c.category_id == categoryId).line_items.find(l => l.line_item_id == line_item.line_item_id).unit = e.value;
    // dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
  };
  async function fetchAlgo(scopeType) {
    setDdLoader(true);

    let scopes = [];
    let type = scopeType;
    if(scopeType === 'RoofScopeX' || scopeType === 'RoofScope+') {
      type = 'RoofScope';
    }

    let reportUnits = [{ value: "(None)", label: "(None)" }];
    let scopetUnits = ScopeUnits[type];

    if(scopeType === 'RoofScope+') {
      scopetUnits = {...scopetUnits, ...ScopeUnits['GutterScope']}
    }

    if(!isEmpty(stData.quantities)) {
      let quantities = stData.quantities;
      
      if(typeof quantities === 'string'){
        quantities = JSON.parse(stData.quantities);
      }
      
      let structure = quantities && quantities.length
        ? quantities.find(quantity => quantity.structure_id == props.structureId)
        : null;
        
      if(!isEmpty(structure)) {
        scopes = structure.scopes;
        let quantityList = scopes && scopes.length
          ? scopes.find(scope => scope.scope_type_name.toLowerCase() == type.toLowerCase())
          : null;

        if(!isEmpty(quantityList)) {
          scopetUnits = {...scopetUnits, ...quantityList.quantities};
        }

        if(scopeType === 'RoofScope+') {
          let gutterScopeList = scopes && scopes.length
            ? scopes.find(scope => scope.scope_type_name.toLowerCase() == 'gutterscope')
            : null;
          if(!isEmpty(gutterScopeList)) {
            scopetUnits = {...scopetUnits, ...gutterScopeList.quantities};
          }
        }
      }
    }

    for (const [key, value] of Object.entries(scopetUnits)) { 
      if(scopeType === 'RoofScopeX' && key !== 'total_squares' && isNaN(parseInt(value.name))) {
        continue;
      }
      reportUnits.push({
        value: value,
        label: `${value.name} (${value.quantity} ${value.unit})`,
      });
    }

    if(scopes.length > 0 && type !== 'RoofScope') {
      let roofScopeList = scopes && scopes.length
            ? scopes.find(scope => scope.scope_type_name.toLowerCase() == 'roofscope')
            : null;
          if(!isEmpty(roofScopeList)) {
            for (const [key, value] of Object.entries(roofScopeList.quantities)) { 
              if(isNaN(parseInt(value.name))) {
                continue;
              }
              reportUnits.push({
                value: value,
                label: `${value.name} (${value.quantity} ${value.unit})`,
              });
            }
          }
    }

    setReportUnits(reportUnits);
    
    setDdLoader(false);
  }
  const handleLICostChangeValue = (e) => {
    let val = e.target.value;
    consoleLog("handleLICostChangeValue e.target.value: ",e.target.value);
    if(e.target.value == '' || e.target.value == null){
      val = 0.00;
    }
      let allData = stData;

      let li_adj_quantity = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity;
      consoleLog("handleLICostChangeValue li_adj_quantity: ", li_adj_quantity);
      if(li_adj_quantity == '' || li_adj_quantity == null){
        li_adj_quantity=0;
      }
      consoleLog("handleLICostChangeValue line_item_adjusted_quantity: ", line_item_adjusted_quantity);
      // if(li_adj_quantity==0 && line_item_adjusted_quantity > 0){
        li_adj_quantity = line_item_adjusted_quantity;
        // allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity = line_item_adjusted_quantity;
      // }
      //line_item_adjusted_quantity
      li_adj_quantity = 1 * li_adj_quantity;
      consoleLog("handleLICostChangeValue li_adj_quantity: ", li_adj_quantity);

      let final_cost = val * li_adj_quantity;
      final_cost = Math.round(final_cost * 100) / 100;
      final_cost = Number.parseFloat(final_cost).toFixed(2);
      consoleLog("handleLICostChangeValue final_cost: ", final_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).net_cost = final_cost;
      set_line_item_net_cost(final_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).cost = val;
      set_line_item_cost(val);
      line_item.cost = val;

      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      props.calculateSubTotal(allData);
      props.calculateStructureTotal();
      props.calculateCategoryTotal();
  };
  const handleLINetCostChangeValue = (e) => {
    consoleLog("handleLINetCostChangeValue e.target.value: ", e.target.value);
    set_line_item_net_cost(e.target.value);
    line_item.net_cost = e.target.value;

    let allData = stData;
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find(
        (l) => l.line_item_id == line_item.line_item_id
      ).net_cost = e.target.value;
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);
    props.calculateStructureTotal();
    props.calculateCategoryTotal();
  };
  const handleLILaborCostChangeValue = (e) => {
    let val = e.target.value;
    consoleLog("handleLILaborCostChangeValue e.target.value: ",e.target.value);
    if(e.target.value == '' || e.target.value == null){
      val = 0.00;
    }
      let allData = stData;

      let li_material_cost = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).material_cost;
      let li_overhead = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).overhead;
      let li_profit = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).profit;
      let li_adj_quantity = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity;
      consoleLog("handleLILaborCostChangeValue li_adj_quantity: ", li_adj_quantity);

      if(li_material_cost == '' || li_material_cost == null){
        li_material_cost=0;
      }else{
        li_material_cost = 1 * li_material_cost;
      }
      if(li_overhead == '' || li_overhead == null){
        li_overhead=0;
      }else{
        li_overhead = 1 * li_overhead;
      }
      if(li_profit == '' || li_profit == null){
        li_profit=0;
      }else{
        li_profit = 1 * li_profit;
      }
      if(li_adj_quantity == '' || li_adj_quantity == null){
        li_adj_quantity=0;
      }
      consoleLog("handleLILaborCostChangeValue line_item_adjusted_quantity: ", line_item_adjusted_quantity);
      // if(li_adj_quantity==0 && line_item_adjusted_quantity > 0){
        li_adj_quantity = line_item_adjusted_quantity;
        // allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity = line_item_adjusted_quantity;
      // }
      li_adj_quantity = 1 * li_adj_quantity;
      consoleLog("handleLILaborCostChangeValue li_adj_quantity: ",li_adj_quantity);
      consoleLog("handleLILaborCostChangeValue li_material_cost: ",li_material_cost);
      consoleLog("handleLILaborCostChangeValue li_overhead: ",li_overhead);
      consoleLog("handleLILaborCostChangeValue li_profit: ",li_profit);
      
      let li_adj_unit_price = 1 * val + 1 * li_material_cost;

      let li_overhead_percentage = (li_overhead / 100) * li_adj_unit_price;
      let li_profit_percentage = (li_profit / 100) * li_adj_unit_price;

      let total_percentage = Math.round(li_overhead_percentage * 100) / 100 + Math.round(li_profit_percentage * 100) / 100;

      let x_cost = li_adj_unit_price + total_percentage;

      x_cost = Number.parseFloat(x_cost).toFixed(2);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).cost = x_cost;

      set_line_item_cost(x_cost);
      consoleLog("handleLILaborCostChangeValue x_cost: ",x_cost);

      let final_cost = (li_adj_unit_price + total_percentage) * li_adj_quantity;

      final_cost = Number.parseFloat(final_cost).toFixed(2);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).net_cost = final_cost;
      set_line_item_net_cost(final_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_cost = x_cost;
      set_line_item_adjusted_cost(x_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).labor_cost = val;
      set_line_item_labor_cost(val);
      line_item.labor_cost = val;

      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      props.calculateSubTotal(allData);
      props.calculateStructureTotal();
      props.calculateCategoryTotal();
  };
  const handleLIMaterialCostChangeValue = (e) => {
    let val = e.target.value;
    consoleLog("handleLIMaterialCostChangeValue e.target.value: ",e.target.value);
    if(e.target.value == '' || e.target.value == null){
      val = 0.00;
    }
      let allData = stData;

      let li_labor_cost = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).labor_cost;
      let li_overhead = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).overhead;
      let li_profit = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).profit;
      let li_adj_quantity = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity;
      consoleLog("handleLIMaterialCostChangeValue li_adj_quantity: ", li_adj_quantity);

      if(li_labor_cost == '' || li_labor_cost == null){
        li_labor_cost=0;
      }else{
        li_labor_cost = 1 * li_labor_cost;
      }
      if(li_overhead == '' || li_overhead == null){
        li_overhead=0;
      }else{
        li_overhead = 1 * li_overhead;
      }
      if(li_profit == '' || li_profit == null){
        li_profit=0;
      }else{
        li_profit = 1 * li_profit;
      }
      if(li_adj_quantity == '' || li_adj_quantity == null){
        li_adj_quantity=0;
      }
      consoleLog("handleLIMaterialCostChangeValue line_item_adjusted_quantity: ", line_item_adjusted_quantity);
      // if(li_adj_quantity==0 && line_item_adjusted_quantity > 0){
        li_adj_quantity = line_item_adjusted_quantity;
        // allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity = line_item_adjusted_quantity;
      // }
      li_adj_quantity = 1 * li_adj_quantity;
      consoleLog("handleLIMaterialCostChangeValue li_adj_quantity: ",li_adj_quantity);
      consoleLog("handleLIMaterialCostChangeValue li_labor_cost: ",li_labor_cost);
      consoleLog("handleLIMaterialCostChangeValue li_overhead: ",li_overhead);
      consoleLog("handleLIMaterialCostChangeValue li_profit: ",li_profit);

      let li_adj_unit_price = 1 * val + 1 * li_labor_cost;

      let li_overhead_percentage = (li_overhead / 100) * li_adj_unit_price;
      let li_profit_percentage = (li_profit / 100) * li_adj_unit_price;

      let total_percentage = Math.round(li_overhead_percentage * 100) / 100 + Math.round(li_profit_percentage * 100) / 100;

      let x_cost = li_adj_unit_price + total_percentage;

      x_cost = Number.parseFloat(x_cost).toFixed(2);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).cost =x_cost;
      set_line_item_cost(x_cost);
      consoleLog("handleLIMaterialCostChangeValue x_cost: ",x_cost);

      let final_cost = (li_adj_unit_price + total_percentage) * li_adj_quantity;

      final_cost = Number.parseFloat(final_cost).toFixed(2);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).net_cost = final_cost;
      set_line_item_net_cost(final_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_cost = x_cost;
      set_line_item_adjusted_cost(x_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).material_cost = val;
      set_line_item_material_cost(val);
      line_item.material_cost = val;

      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      props.calculateSubTotal(allData);
      props.calculateStructureTotal();
      props.calculateCategoryTotal();
  };
  const handleLIOverheadChangeValue = (e) => {
    let val = e.target.value;
    consoleLog("handleLIOverheadChangeValue e.target.value: ",e.target.value);
    if(e.target.value == '' || e.target.value == null){
      val = 0.00;
    }
      let allData = stData;

      let li_labor_cost = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).labor_cost;
      let li_material_cost = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).material_cost;
      let li_profit = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).profit;
      let li_adj_quantity = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity;
      consoleLog("handleLIOverheadChangeValue li_adj_quantity: ", li_adj_quantity);

      if(li_labor_cost == '' || li_labor_cost == null){
        li_labor_cost=0;
      }else{
        li_labor_cost = 1 * li_labor_cost;
      }
      if(li_material_cost == '' || li_material_cost == null){
        li_material_cost=0;
      }else{
        li_material_cost = 1 * li_material_cost;
      }
      if(li_profit == '' || li_profit == null){
        li_profit=0;
      }else{
        li_profit = 1 * li_profit;
      }
      if(li_adj_quantity == '' || li_adj_quantity == null){
        li_adj_quantity=0;
      }
      consoleLog("handleLIOverheadChangeValue line_item_adjusted_quantity: ", line_item_adjusted_quantity);
      // if(li_adj_quantity==0 && line_item_adjusted_quantity > 0){
        li_adj_quantity = line_item_adjusted_quantity;
        // allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity = line_item_adjusted_quantity;
      // }
      li_adj_quantity = 1 * li_adj_quantity;
      consoleLog("handleLIOverheadChangeValue li_adj_quantity: ",li_adj_quantity);
      consoleLog("handleLIOverheadChangeValue li_labor_cost: ",li_labor_cost);
      consoleLog("handleLIOverheadChangeValue li_material_cost: ",li_material_cost);
      consoleLog("handleLIOverheadChangeValue li_profit: ",li_profit);

      let li_adj_unit_price = 1 * li_material_cost + 1 * li_labor_cost;

      let li_overhead_percentage = (val / 100) * li_adj_unit_price;
      let li_profit_percentage = (li_profit / 100) * li_adj_unit_price;

      let total_percentage = Math.round(li_overhead_percentage * 100) / 100 + Math.round(li_profit_percentage * 100) / 100;

      let x_cost = li_adj_unit_price + total_percentage;
      x_cost = Number.parseFloat(x_cost).toFixed(2);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).cost = x_cost;
      set_line_item_cost(x_cost);
      consoleLog("handleLIOverheadChangeValue x_cost: ",x_cost);

      let final_cost = (li_adj_unit_price + total_percentage) * li_adj_quantity;
      final_cost = Number.parseFloat(final_cost).toFixed(2);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).net_cost = final_cost;
      set_line_item_net_cost(final_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_cost = x_cost;
      set_line_item_adjusted_cost(x_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).overhead = val;
      set_line_item_overhead(val);
      line_item.overhead = val;

      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      props.calculateSubTotal(allData);
      props.calculateStructureTotal();
      props.calculateCategoryTotal();
  };
  const handleLIProfitChangeValue = (e) => {
    let val = e.target.value;
    consoleLog("handleLIProfitChangeValue e.target.value: ",e.target.value);
    if(e.target.value == '' || e.target.value == null){
      val = 0.00;
    }
      let allData = stData;

      let li_labor_cost = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).labor_cost;
      let li_material_cost = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).material_cost;
      let li_overhead = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).overhead;
      let li_adj_quantity = allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity;
      consoleLog("handleLIProfitChangeValue li_adj_quantity: ", li_adj_quantity);

      if(li_labor_cost == '' || li_labor_cost == null){
        li_labor_cost=0;
      }else{
        li_labor_cost = 1 * li_labor_cost;
      }
      if(li_material_cost == '' || li_material_cost == null){
        li_material_cost=0;
      }else{
        li_material_cost = 1 * li_material_cost;
      }
      if(li_overhead == '' || li_overhead == null){
        li_overhead=0;
      }else{
        li_overhead = 1 * li_overhead;
      }
      if(li_adj_quantity == '' || li_adj_quantity == null){
        li_adj_quantity=0;
      }
      consoleLog("handleLIProfitChangeValue line_item_adjusted_quantity: ", line_item_adjusted_quantity);
      // if(li_adj_quantity==0 && line_item_adjusted_quantity > 0){
        li_adj_quantity = line_item_adjusted_quantity;
        // allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_quantity = line_item_adjusted_quantity;
      // }
      li_adj_quantity = 1 * li_adj_quantity;
      consoleLog("handleLIProfitChangeValue li_adj_quantity: ",li_adj_quantity);
      consoleLog("handleLIProfitChangeValue li_labor_cost: ",li_labor_cost);
      consoleLog("handleLIProfitChangeValue li_material_cost: ",li_material_cost);
      consoleLog("handleLIProfitChangeValue li_overhead: ",li_overhead);

      let li_adj_unit_price = 1 * li_material_cost + 1 * li_labor_cost;

      let li_overhead_percentage = (li_overhead / 100) * li_adj_unit_price;
      let li_profit_percentage = (val / 100) * li_adj_unit_price;

      let total_percentage = Math.round(li_overhead_percentage * 100) / 100 + Math.round(li_profit_percentage * 100) / 100;

      let x_cost = li_adj_unit_price + total_percentage;
      x_cost = Number.parseFloat(x_cost).toFixed(2);
      
      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).cost = x_cost;
      set_line_item_cost(x_cost);
      consoleLog("handleLIProfitChangeValue x_cost: ",x_cost);

      let final_cost = (li_adj_unit_price + total_percentage) * li_adj_quantity;
      final_cost = Number.parseFloat(final_cost).toFixed(2);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).net_cost = final_cost;
      set_line_item_net_cost(final_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).adjusted_cost = x_cost;
      set_line_item_adjusted_cost(x_cost);

      allData.structures[structureIndex].categories.find((c) => c.category_id == categoryId).line_items.find((l) => l.line_item_id == line_item.line_item_id).profit = val;
      set_line_item_profit(val);
      line_item.profit = val;

      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      props.calculateSubTotal(allData);
      props.calculateStructureTotal();
      props.calculateCategoryTotal();
  };
  const handleLIAdjCostChangeValue = (e) => {
    consoleLog("handleLIAdjCostChangeValue e.target.value: ", e.target.value);
    set_line_item_adjusted_cost(e.target.value);

    line_item.adjusted_cost = e.target.value;
    let allData = stData;
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find(
        (l) => l.line_item_id == line_item.line_item_id
      ).adjusted_cost = e.target.value;
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);
    props.calculateStructureTotal();
    props.calculateCategoryTotal();
  };

  const handleOnChangeCheckBox = () => {
    setIsChecked(!isChecked);
  };
  const handleApplyOPIsChecked = () => {
    let copy = applyOPIsChecked;
    setApplyOPIsChecked(!applyOPIsChecked);
    let val = 0;
    if (copy == false || copy == "0") {
      val = 1;
    }
    // if(copy == true || copy == '1'){
    //     let val = 0;
    // }
    let allData = stData;
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find(
        (l) => l.line_item_id == line_item.line_item_id
      ).apply_op = val;
    consoleLog("handleApplyOPIsChecked", applyOPIsChecked);
    consoleLog("handleApplyOPIsChecked copy", copy);
    consoleLog("handleApplyOPIsChecked val", val);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    props.calculateSubTotal(allData);
    props.calculateStructureTotal();
    props.calculateCategoryTotal();
  };

  const handleHideUnitPriceColumn = () => {
    consoleLog("handleHideUnitPriceColumn");
    let copy = hideUnitPriceColumn;
    setHideUnitPriceColumn(!hideUnitPriceColumn);
    let val = 0;
    if (copy == false || copy == "0") {
      val = 1;
    }
    let allData = stData;
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find(
        (l) => l.line_item_id == line_item.line_item_id
      ).remove_prices = val;
    consoleLog("handleHideUnitPriceColumn", hideUnitPriceColumn);
    consoleLog("handleHideUnitPriceColumn copy", copy);
    consoleLog("handleHideUnitPriceColumn val", val);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    // props.calculateSubTotal(allData); no need to calculate
  };

  // Not new subline item / note
  const addNoteFunc = () => {
    var sub_line_item_copy = [...sub_line_item, ""];
    set_sub_line_item(sub_line_item_copy);
    consoleLog("addNoteFunc sub_line_item_copy: ", sub_line_item_copy);

    let allData = stData;
    consoleLog("addNoteFunc allData: ", allData);
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find(
        (l) => l.line_item_id == line_item.line_item_id
      ).sub_line_item = [...sub_line_item_copy];
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
  };

  // on remove subline item
  async function handleSubLineItemChange(sub_line_item_index, line_item_id) {
    consoleLog(
      "handleSubLineItemChange sub_line_item_index: " +
        sub_line_item_index +
        ", line_item_id: " +
        line_item_id
    );
    var sub_line_item_copy = [...sub_line_item];
    sub_line_item_copy.splice(sub_line_item_index, 1);
    consoleLog("sub_line_item_copy: ", sub_line_item_copy);
    set_sub_line_item(sub_line_item_copy);

    let allData = stData;
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find(
        (l) => l.line_item_id == line_item.line_item_id
      ).sub_line_item = [...sub_line_item_copy];

    
    if(typeof props.url_params.doc_id != 'undefined'){
    
      consoleLog( "handleSubLineItemChange doc_line_item_id", line_item.doc_line_item_id);
      consoleLog("handleSubLineItemChange sub_line_item_copy", sub_line_item_copy);
      try{
        const add_sub_line_item = await API.graphql({
          query: addDocSubLineItem,
          variables: {
            doc_line_item_id: line_item.doc_line_item_id,
            sub_line_item: JSON.stringify(sub_line_item_copy)
          }
        });
        consoleLog("handleSubLineItemChange add_sub_line_item", add_sub_line_item);
      }catch(err){
        console.error(err);
      }
    }

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    if(typeof props.url_params.doc_id != 'undefined'){
      props.updateDocOnElasticSearch();
    }

    props.calculateSubTotal(allData);
    props.calculateStructureTotal();
    props.calculateCategoryTotal();
  }

  // input text change
  async function handleAddNote(index, value) {
    consoleLog("handleAddNote sub_line_item_index: ", index);
    var sub_line_item_copy = [...sub_line_item];
    sub_line_item_copy[index] = value;
    set_sub_line_item(sub_line_item_copy);

    let allData = stData;
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find(
        (l) => l.line_item_id == line_item.line_item_id
      ).sub_line_item = [...sub_line_item_copy];
    consoleLog("handleAddNote stData", allData);

    if(typeof props.url_params.doc_id != 'undefined'){
      
      consoleLog( "handleAddNote doc_line_item_id", line_item.doc_line_item_id);
      consoleLog("handleAddNote sub_line_item_copy", sub_line_item_copy);
      try{
        const add_sub_line_item = await API.graphql({
          query: addDocSubLineItem,
          variables: {
            doc_line_item_id: line_item.doc_line_item_id,
            sub_line_item: JSON.stringify(sub_line_item_copy)
          }
        });
        consoleLog("handleAddNote add_sub_line_item", add_sub_line_item);
      }catch(err){
        console.error(err);
      }
    }
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    if(typeof props.url_params.doc_id != 'undefined'){
      props.updateDocOnElasticSearch();
    }
  }

  // on remove line item
  function handleRemoveLineItem(line_item_id) {
    consoleLog("handleRemoveLineItem: line_item_id: ", line_item_id);
    toggleShowAdvanceOpt(!showAdvanceOpt);
    toggleEditLineItem(!editLineItem);

    // var sub_line_item_copy = [...sub_line_item];
    // sub_line_item_copy.splice(0);
    // consoleLog("sub_line_item_copy: ", sub_line_item_copy)
    // set_sub_line_item(sub_line_item_copy);

    props.onChange(line_item_id);
  }
  async function handleOnDragEndSubLine(results) {
    consoleLog("handleOnDragEndSubLine results: ", results);
    if (!results.destination) return;

    const itemsNotes = Array.from(sub_line_item);
    const [reorderedItemNotes] = itemsNotes.splice(results.source.index, 1);
    itemsNotes.splice(results.destination.index, 0, reorderedItemNotes);

    //setDragPositionNotes(itemsNotes);
    set_sub_line_item(itemsNotes);
    let allData = stData;
    allData.structures[structureIndex].categories
      .find((c) => c.category_id == categoryId)
      .line_items.find(
        (l) => l.line_item_id == line_item.line_item_id
      ).sub_line_item = [...itemsNotes];
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    setStData(allData);
    consoleLog("handleOnDragEndSubLine itemsNotes: ", itemsNotes);
    if(typeof props.url_params.doc_id != 'undefined'){
      props.updateDocOnElasticSearch();

      try{
        const add_sub_line_item = await API.graphql({
          query: addDocSubLineItem,
          variables: {
            doc_line_item_id: line_item.doc_line_item_id,
            sub_line_item: JSON.stringify(itemsNotes)
          }
        });
        consoleLog("handleOnDragEndSubLine add_sub_line_item:", add_sub_line_item);
      }catch(err){
        console.error(err);
      }
    }
  }
  const [isActive, setActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive);
  };

  async function deleteLineItm(line_item_id){ //Delete a line item
    consoleLog('deleteLineItm>', line_item_id);
    consoleLog('deleteLineItm>', line_item.line_item_id);
    let allData = { ...stData };
    let line_data = allData.structures[props.structureIndex].categories.find(c => c.category_id == props.categoryId).line_items.filter(l => l.line_item_id != line_item.line_item_id);
    consoleLog('deleteLineItm>',line_data);
    allData.structures[props.structureIndex].categories.find(c => c.category_id == props.categoryId).line_items = line_data;
    // allData.structures[structureIndex].categories = allData.structures[structureIndex].categories.filter((c) => c.category_id != props.category.category_id);
    // consoleLog('deleteLineItm>',JSON.stringify(allData));
    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    setStData(allData);

    props.setShow(true);
    props.setShowMessage('Line Item has been deleted!');
    props.setShowMessageType('Success');
    if(typeof props.url_params.doc_id != 'undefined'){
      try{
        const delete_line_item = await API.graphql({
            query: deleteLineItem,
            variables: {
                doc_line_item_id: line_item_id,
            },
        });
        consoleLog('deleteLineItm>',delete_line_item);
        props.updateDocOnElasticSearch();
      }catch(err){
        console.error(err);
      }
    }
}

const getBackgroundColor = (snapshot) => {
  consoleLog('getBackgroundColor', snapshot);
  if (snapshot.isDropAnimating) {
    return 'none';
  }
  if (snapshot.isDragging) {
    return '0 0 .5rem #000';
  }
  return 'none';
};

  return (
    <Master>
      <Row className="XS m-0 pt-2 pb-2" style={{ padding: "10px 0" }}>
        <Col sm={12} xs={12} md={3} lg={6} xl={6} className="text-left pl-5 padding">
          <div className="showXS">
            <h5 className="m-0 item-name">ITEM NAME</h5>
          </div>
          <div
            className="h-100 d-flex align-items-center"
            style={{
              fontSize: "14px",
              color: "#212E33",
              fontFamily: "Roboto",
            }}
          >
            <input
                    name="note"
                    placeholder="Enter Notes"
                    className="ip my-2 ellipsis input-border"
                    style={{position:'relative'}}
                    value={lineItemName?lineItemName:""}
                    onChange={handleLINameChange}
                    onBlur={handleLineItemBlurValue}
                />
          </div>
        </Col>
        <Col sm={12} xs={12} md={2} lg={1} xl={1} className="text-left centre  ">
          <div className="showXS ">
            <h5>QUANTITY</h5>
          </div>
          { line_item_unit != '' &&
            <input
              type="text"
              name="quantity"
              className="ip2 input-border"
              placeholder="0"
              value={line_item_adjusted_quantity > 0 ? line_item_adjusted_quantity : ''}
              onChange={handleLIAdjQuantityChangeValue}
              onBlur={handleLineItemBlurValue}
            />
          }
        </Col>
        <Col sm={12} xs={12} md={2} lg={1} xl={1} className="text-left ">
          <div className="showXS">
            <h5>UNIT</h5>
          </div>
          <Select
            value={UNITS.find((obj) => obj.value === line_item_unit)} // set selected value
            options={UNITS} // set list of the UNIT
            onChange={handleLIUnitChangeValue} // assign onChange function
            onBlur={handleLineItemBlurValue}
            className="w-100"
            components={{ DropdownIndicator }}
            defaultValue=""
            styles={{
              placeholder: (base) => ({
                ...base,
                fontSize: "1em",
                fontFamily: "Roboto",
                color: "#B4BEBF",
              }),
              control: (provided, state) => ({
                ...provided,
                boxShadow: "none",
                border: state.isFocused ? '1px solid #f7941c' : '1px solid #B4BEBF',
                overflowX: "hidden",
              }),
              menu: (provided, state) => ({
                ...provided,
                width: "75px",
                border: "1px solid #B4BEBF",
                overflowX: "hidden",
              }),
              option: (provided, state) => ({
                ...provided,
                width: "75px",
                overflowX: "hidden",
                backgroundColor: state.isFocused && "#F7F9FA",
                color: state.isFocused && "#212E33",
                borderBottom: "1px solid #B4BEBF",
              }),
            }}
          />
        </Col>

        <Col sm={12} xs={12} md={2} lg={1} xl={1} className="text-left toggle-block ">
          <div className="showXS">
            <h5>UNIT PRICE</h5>
          </div>
          { line_item_unit != '' &&
            <div
              style={{ position: "relative" }}
              className="d-flex align-items-center"
            >
              <input
                type="text"
                className=" ip2 pl-4 input-border"
                placeholder="0.00"
                value={line_item_cost > 0 ? line_item_cost : ''}
                onChange={handleLICostChangeValue}
                onBlur={handleLineItemBlurValue}
              />
              <span
                style={{ position: "absolute", left: "4px", fontSize: "14px" }}
              >
                $
              </span>
            </div>
          }
        </Col>
        <Col sm={11} xs={11} md={1} lg={1} xl={1} className="text-left px-1 toggle-block toggle-net-cost">
          <div className="showXS">
            <h5>NET COST</h5>
          </div>
          { line_item_unit != '' &&
            <div
              style={{ position: "relative" , width : "135px" }}
              className="d-flex align-items-center"
            >
              <input
                type="number"
                className=" ip22 border-none input-border"
                placeholder="$0.00"
                value={line_item_net_cost}
                disabled={true}
              />
              <span
                style={{ position: "absolute", left: "0px", fontSize: "14px" }}
              >
                $
              </span>
            </div>
          }
        </Col>
        <Col
          sm={12}
          xs={12}
          md={1}
          lg={1}
          xl={1}
          className="text-center d-flex align-items-center justify-content-start toggle-blocks"
        >
          <div className="showXS">{/* <h5>NOTE</h5> */}</div>
          <DropdownButton className={isActive ? "box-m-btn-active" : "box-m-btn"} title='...' onClick={() => setActive(!isActive)} onBlur={() => setActive(false)}>
            <div>
              <Dropdown.Item className="custom-li-font w-100" onClick={() => addNoteFunc()}>

                <span className="link-text">Add Sub-line Note</span>

              </Dropdown.Item>
              <Dropdown.Item className="custom-li-font w-100" onClick={() => deleteLineItm(props.line_item.doc_line_item_id)}><span className="link-text">Delete Line</span></Dropdown.Item>
              <Dropdown.Item className="custom-li-font w-100"><span className="link-text">Save Line as Default</span></Dropdown.Item>

            </div>
          </DropdownButton>
        </Col>
        <Col sm={12} xs={12} md={1} lg={1} xl={1} className="text-center toggle-blocks ">
          <div className="showXS">{/* <h5>EDIT</h5> */}</div>
          <button
            className="custombtnn ml-auto ml-md-0 h-100 d-flex align-items-center"
            onClick={() => toggleEditLineItem(!editLineItem)}
            style={{
              marginLeft: "10px",
              fontFamily: "Rajdhani",
              color: "#F7941C",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {editLineItem ? "CLOSE" : "EDIT"}
          </button>
        </Col>
      </Row>

      {editLineItem && (
        <NestedAccordian className="pt-2">
          <div className="c-line"></div>
          <Row className="px-xl-5 mx-auto">
            <Col
              xs={12}
              sm={12}
              col-lg-3
              col-md-3
              col-sm-12
              col-12
              className="edit_line_item customBorder fs-smaller"
            >
              <div className="row mx-auto py-3 align-items-center row-reverse">
                <div className="col-xl-6 px-0">
                  <div className="row mx-auto">
                    <div class="col-xl px-md-0 col-md my-3 my-md-0">
                      <p className="m-0 fs-12px mb-2">QTY</p>
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="ip2x mr-1 input-border"
                          value={line_item_quantity > 0? line_item_quantity : ''}
                          onChange={handleLIQuantityChangeValue}
                          onBlur={handleLineItemBlurValue}
                          placeholder='0'
                        />
                      </div>
                    </div>
                    <div class="col-xl px-xl-0 col-md my-3 my-md-0">
                      <p className="m-0 fs-12px mb-2">Waste Factor</p>
                      <div className="d-flex align-items-center position-relative">
                        <input
                          type="text"
                          className="ip2x mr-1 input-border"
                          style={{ position: "relative" }}
                          value={line_item_waste > 0 ? line_item_waste : ''}
                          onChange={handleLIWasteFactorChangeValue}
                          onBlur={handleLineItemBlurValue}
                          placeholder='0.00'
                        />
                        <span
                          class="percent-sign"
                          style={{
                            position: "absolute",
                            right: "16%",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                    <div class="col-xl px-xl-0 col-md my-3 my-md-0">
                      <p className="m-0 fs-12pxs mb-2">Adjusted QTY</p>
                      <div className="d-flex align-items-center">
                        <input
                          disabled
                          type="text"
                          className="ip2x mr-1 input-border"
                          value={line_item_adjusted_quantity}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 px-md-0 ml-auto">
                  <div className="row mx-auto">
                    <div className="ml-xl-auto">
                      <div className="right-side-check">
                        <div className="content-w">
                          <input
                            type="checkbox"
                            onClick={() => {handleApplyOPIsChecked(); handleLineItemBlurValue();}}
                            checked={applyOPIsChecked}
                          />
                          <p>Apply O&P to Item</p>
                          <Tooltip
                            overlay={
                              <div
                                style={{
                                  width: "250px",
                                  height: "100px",
                                  background: "#fff",
                                  color: "#000",
                                  padding: "0 5px",
                                  fontSize: "14px",
                                  fontFamily: "Roboto",
                                }}
                                className="d-flex align-items-center justify-content-center"
                              >
                                Check this box to apply the values you set on
                                the Taxes & Markups page, or uncheck the box to
                                remove the values from this line item.
                              </div>
                            }
                            placement="left"
                          >
                            <small
                              style={{ marginLeft: "5px", marginTop: "5px" }}
                            >
                              <FaInfoCircle
                                size={15}
                                style={{ color: "#F7941C" }}
                              />
                            </small>
                          </Tooltip>
                        </div>
                        <div className="content-w">
                          <input
                            type="checkbox"
                            onClick={()=>{handleHideUnitPriceColumn(); handleLineItemBlurValue();}}
                            checked={hideUnitPriceColumn}
                          />
                          <p>Hide Unit/Net Pricing Columns</p>
                          <Tooltip
                            overlay={
                              <div
                                style={{
                                  width: "250px",
                                  height: "100px",
                                  background: "#fff",
                                  color: "#000",
                                  padding: "0 5px",
                                  fontSize: "14px",
                                  fontFamily: "Roboto",
                                }}
                                className="d-flex align-items-center justify-content-center"
                              >
                                Check this box to remove the Unit/Net prices
                                values for this line item in ProDocs.
                              </div>
                            }
                            placement="left"
                          >
                            <small style={{marginLeft: "5px", marginTop: "5px" }}>
                              <FaInfoCircle
                                size={15}
                                style={{ color: "#F7941C" }}
                              />
                            </small>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <>
                <Row className="py-2 mobile-row w-75 pl-4">
                  <div className="wd-20 pl-2 pr-3 my-md-5 my-3">
                    <p className=" fs-12px">Labor Cost</p>
                    <div
                      className="d-flex align-items-center"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        className="ip2x mr-2 input-border"
                        value={line_item_labor_cost > 0 ? line_item_labor_cost : ''}
                        style={{ paddingLeft: "18px" }}
                        onChange={handleLILaborCostChangeValue}
                        onBlur={handleLineItemBlurValue}
                        placeholder='0.00'
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          position: "absolute",
                          left: "3%",
                        }}
                        className="mx-1"
                      >
                        $
                      </span>
                    </div>
                  </div>
                  <div className="wd-20 pl-2 pr-3 my-md-5 my-3">
                    <p className=" fs-12px">Material Cost</p>
                    <div
                      className="d-flex align-items-center"
                      style={{ position: "relative" }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          position: "absolute",
                          left: "3%",
                        }}
                        className="mx-1"
                      >
                        $
                      </span>
                      <input
                        type="text"
                        className="ip2x mr-1 input-border"
                        style={{ paddingLeft: "18px" }}
                        value={parseFloat(line_item_material_cost) ? line_item_material_cost : null}
                        onChange={handleLIMaterialCostChangeValue}
                        onBlur={handleLineItemBlurValue}
                        placeholder='0.00'
                      />
                    </div>
                  </div>
                  <div className="wd-20 pl-2 pr-3 my-md-5 my-3">
                    <p className=" fs-12px">Overhead</p>
                    <div
                      className="d-flex align-items-center"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        className="ip2x mr-1 input-border"
                        value={parseFloat(line_item_overhead) ? line_item_overhead : null}
                        onChange={handleLIOverheadChangeValue}
                        onBlur={handleLineItemBlurValue}
                        placeholder='0.00'
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          position: "absolute",
                          right: "15%",
                        }}
                        className="mx-1 percent-sign"
                      >
                        %
                      </span>
                    </div>
                  </div>
                  <div className="wd-20 pl-2 pr-3 my-md-5 my-3">
                    <p className=" fs-12px">Profit</p>
                    <div
                      className="d-flex align-items-center"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        className="ip2x mr-1 input-border"
                        value={parseFloat(line_item_profit) ? line_item_profit : null}
                        onChange={handleLIProfitChangeValue}
                        onBlur={handleLineItemBlurValue}
                        placeholder='0.00'
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          position: "absolute",
                          right: "15%",
                        }}
                        className="mx-1 percent-sign"
                      >
                        %
                      </span>
                    </div>
                  </div>
                  <div className="wd-20 pl-2 pr-3 my-md-5 my-3">
                    <p className=" fs-12pxs">Adjusted Unit Price</p>
                    <div
                      className="d-flex align-items-center"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        className="ip2x mr-1 input-border"
                        value={line_item_adjusted_cost}
                        style={{ paddingLeft: "18px" }}
                        onChange={handleLIAdjCostChangeValue}
                        disabled
                      />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          position: "absolute",
                          left: "3%",
                        }}
                        className="mx-1"
                      >
                        $
                      </span>
                    </div>
                  </div>
                </Row>

                <Row className="pt-2 mx-auto">
                  <div className="col-md-6 col-12 col-lg-5 col-xl-4 px-md-0">
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#212E33",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      Calculate QTY From: <b>{algoValue}</b>
                      <span>
                        <Tooltip
                            overlay={
                              <div
                              style={{
                                width: "250px",
                                padding: "10px",
                                background: "#fff",
                                color: "#000",
                                fontSize: "14px",
                                fontFamily: "Roboto",
                              }}
                              className="d-flex flex-column justify-content-center"
                              >
                                <b>Selecting Your Calculation</b><br />
                                You can set or change the calculation used to auto-calculate your item‘s quantity. If you‘ve added a custom item you must select a calculation in order for this to work.
                              </div>
                            }
                            placement="left"
                            >
                            <small
                            className="info-circle"
                              style={{ marginLeft: "5px", marginTop: "5px" }}
                            >
                            <FaInfoCircle
                            size={15}
                            style={{ color: "#F7941C" }}
                            />
                          </small>
                        </Tooltip>
                      </span>
                    </p>
                    {/* <p><b>Inside Corners (0 EA)</b></p> */}
                    <Select
                      value={selectedReportType} // Dynamic selected value
                      // value={reportType.find(obj => obj.value === line_item_unit)} // set selected value
                      options={reportType} // set list of the UNIT
                      onChange={handleLReportTypeChangeValue}
                      components={{ DropdownIndicator }}
                      // onChange={}
                      className="custom-input-y w-100 border-none my-3"
                      styles={{
                        placeholder: (base) => ({
                          ...base,
                          fontSize: "1em",
                          fontFamily: "Roboto",
                          color: "#B4BEBF",
                        }),
                        control: (provided, state) => ({
                          ...provided,

                          boxShadow: "none",
                          border: state.isFocused ? '1px solid #f7941c' : '1px solid #B4BEBF',
                          overflowX: "hidden",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          border: state.isFocused && "1px solid #B4BEBF",
                          border: "1px solid #B4BEBF",
                          overflowX: "hidden",
                        }),
                        option: (provided, state) => ({
                          ...provided,

                          overflowX: "hidden",
                          backgroundColor: state.isFocused && "#F7F9FA",
                          color: state.isFocused && "#212E33",
                          borderBottom: "1px solid #B4BEBF",
                        }),
                      }}
                    />
                  </div>
                  <Col xs={12} md={6} lg={5} className="text-left mt-auto">
                    <Select
                      value={selectedReportUnit} // Dynamic selected value for the second select
                      options={reportUnits}
                      components={{ DropdownIndicator }}
                      onChange={handleChangeScopeUnit}
                      className="custom-input-yy border-none my-3"
                      styles={{
                        placeholder: (base) => ({
                          ...base,
                          fontSize: "1em",
                          fontFamily: "Roboto",
                          color: "#B4BEBF",
                        }),
                        control: (provided, state) => ({
                          ...provided,

                          boxShadow: "none",
                          border: state.isFocused ? '1px solid #f7941c' : '1px solid #B4BEBF',
                          overflowX: "hidden",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          border: state.isFocused && "1px solid #B4BEBF",
                          border: "1px solid #B4BEBF",
                          overflowX: "hidden",
                        }),
                        option: (provided, state) => ({
                          ...provided,

                          overflowX: "hidden",
                          backgroundColor: state.isFocused && "#F7F9FA",
                          color: state.isFocused && "#212E33",
                          borderBottom: "1px solid #B4BEBF",
                        }),
                      }}
                      disabled={ddLoader}
                    />
                  </Col>
                    { ddLoader &&
                    <div className="loader-section">
                      <img className='dd-loader' src={DDLoader} alt="" width="35px" height="35px"/>
                    </div>
                    } 
                </Row>
              </>
            </Col>
          </Row>
        </NestedAccordian>
      )}

      <div className="my-3 w-100">
        <DragDropContext onDragEnd={handleOnDragEndSubLine}>
          <Droppable droppableId="droppable-1">
            {(provided, _) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {Array.isArray(sub_line_item) &&
                  sub_line_item.map((note, i) => (
                    <Draggable key={Math.random()} draggableId={"draggable-" + i} index={i}>
                      {(provided, snapshot) => (
                        <div
                          className="pl-xl-5"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: getBackgroundColor(snapshot)
                          }}
                        >
                          <Handle {...provided.dragHandleProps}>
                            <img
                              src={Move}
                              alt=""
                              style={{ paddingLeft: "13px" }}
                            />
                          </Handle>
                          <Notes
                            note={note}
                            line_item_id={props.line_item.line_item_id}
                            onAddNote={handleAddNote}
                            onChange={handleSubLineItemChange}
                            count={i}
                            // onAddNote={handleAddNote}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Master>
  );
}
const Handle = styled.div`
  position: absolute;
  left: 0px;
  margin: 17px 5.5em;
  cursor: grab;
  @media (max-width: 768px) {
    margin: 17px 0.5em;
  }
`;

const customStyles = {
  control: (base) => ({
    ...base,
    height: 30,
    minHeight: 30,
  }),
};

const Master = styled.div`
  width: 100%;
  min-height: 61px;
  padding-top: 10px;
  border-bottom: 2px solid #f7f9fa;
  background: #fff;

  @media (max-width: 600px) {
    padding: 20px;
  }
  .ip{
    min-height:unset !important;
    width:100%;
    outline:none;
    padding:10px;
    border-radius:4px;
    border:none !important;
    color:#212E33;
    font-style: normal;
    font-size:14px;
}
  .dropdown-menu {
    inset: unset !important;
    background: white !important;
    min-width: 180px !important;
    padding: 0;
    transform: translate(-200px, -47px) !important;
  }
  .dropdown-item {
    width: 180px !important;
    padding: 0;
  }
  .dropdown-toggle:focus{
    background: #ebebeb !important;
  }
  .dropdown-item:hover {
    background: #ebebeb !important;
  }
  .dropdown-menu:before {
    display: none !important;
  }
  .dropdown-menu:after {
    display: none !important;
  }
  .dropdown-menu.show{
    left:0px !important;
  }
  .box-m-btn {
    height: 36px;
    width: 36px;
    border-radius: 3px;
    .dropdown-toggle {
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-size: 18px;
      color: black;
      border: none;
      box-shadow: unset;
      background-color: #d6e1e5 !important;
      margin : 0;
      line-height: 1;
      height: 36px;
      width: 36px;
    }
  }
  .custom-li-font {
    text-decoration: none;
    font-family: Roboto;
    color: #737f82 !important;
  }
  .link-text {
	  margin: 15px;
	  line-height: 2.6;
	}
  .box-m-btn-active {
    height: 36px;
    width: 36px;
    border-radius: 3px;
    .dropdown-toggle {
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-size: 18px;
      color: black;
      border: none;
      box-shadow: unset;
      background-color: #f7941c !important;
      margin : 0;
      line-height: 1;
      height: 36px;
      width: 36px;
    }
  }

  .szh-menu {
    left: -185.45312px !important;
    top: -20px !important;
    padding: 0;
    @media(max-width:600px){
      left:10px !important;
    }
  }

  .centre { 
    @media(min-width: 1024px){
      display: flex;
      justify-content: center !important;
    }
  }
  .szh-menu__item:active {
    background: #f7941c !important;
  }
  .ext-font {
    font-size: 12px;
    outline: none;
    color: #212e33;
    font-family: Roboto;
  }
  .ip2 {
    height: 40px;
    width: 100%;
    outline: none;
    font-style: normal;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #b4bebf;
    color: #212e33;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .ip2x {
    height: 40px;
    width: 90%;
    outline: none;
    padding: 10px;
    font-style: normal;
    border-radius: 4px;
    border: 1px solid #b4bebf;
    color: #212e33;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .ip22 {
    height: 40px;
    width: 100%;
    outline: none;
    padding: 10px;
    font-style: normal;
    border-radius: 4px;
    border: 1px solid #fff;
    color: #212e33;
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .toggle-block {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: center !important;
      padding-inline: 15px !important;
    }
  }

  .toggle-net-cost {
    @media(min-width: 1200px) {
      padding-inline : 0 !important;
    }
  }

  .toggle-blocks {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: center !important;
      padding-inline: 0 !important;
    }
  }

  .css-mi0zgc-control {
    height: 40px !important;
    width: 80px !important;
    outline: none !important;

    @media (max-width: 1024px) {
      flex-wrap: nowrap !important;
    }
  }
  .css-h4172c-control {
    height: 40px !important;
    width: 80px !important;
    outline: none !important;
  }

  .css-5b9gaa-menu {
    div:last-child {
       border-bottom: none;
    }
  }

  .item-name {
    @media(max-width: 767px) {
      font-family: Roboto;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 7px !important;
    }
  }

  .XS {
    @media (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .padding {
    @media(max-width: 767px) {
      padding-left: 15px !important;
    }
  }
  .showXS {
    display: none;
    @media (max-width: 600px) {
      display: block;
    }
  }

  .subline_note {
    padding-left: 5em;
    padding-top: 0.3em;

    .custombtnn {
      &:hover  {
        color: #f7941c;
      }
    }


    @media (max-width: 767px) {
      padding-left: 0;
    }
  }

  .fs-small {
    font-size: small;
  }

  .fs-smaller {
    font-size: smaller;
  }
  .custom-input-y {
    height: 44px;
    width: 100%;
    outline: none;
    border-radius: 4px;
    margin-bottom: 40px;
    font-size: 14px;
    color: #212e33;
    font-family: Roboto;

    @media(max-width: 767px) {
      margin-bottom: 0 !important;
    }
  }
  .custom-input-yy {
    height: 44px;
    width: 100%;
    margin-top: 32px;
    outline: none;
    border-radius: 4px;
    margin-bottom: 40px;
    font-size: 14px;
    color: #212e33;
    font-family: Roboto;

    @media(max-width: 767px) {
      margin-top: 5px !important;
    }
  }
  .fs-12px {
    font-size: 14px;
    font-family: Roboto;
    color: #212e33;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  .fs-12pxs {
    font-size: 14px;
    font-family: Roboto;
    color: #f7941c;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .text-underline {
    text-decoration: underline !important;
  }
`;
const NestedAccordian = styled.div`
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background: #eef2f4;
  position: relative;

  .c-line {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 127px;
    background: #f7f9fa;
  }

  @media (max-width: 1050px) {
    .row-reverse {
      flex-direction: column-reverse;
    }
    .c-line {
      top: 191px;
    }
  }

  @media (max-width: 768px) {
    .c-line {
      top: 190px;
    }
  }

  @media (max-width: 500px) {
    .c-line {
      top: 368px;
    }
  }

  .right-side-check {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px 0;

    .content-w {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 5px 0;

      p {
        font-size: 14px;
        color: #737f82;
        font-family: Roboto;

        margin: 5px 0 0 5px;
      }

      small {
        @media(max-width: 767px){
          margin-top: 5px;
          margin-left: 5px !important;
        }
      }
    }
  }

  .info-circle {
    @media(max-width: 767px) {
      margin-top: 0px !important;
      margin-left: 5px !important;

      svg {
        margin-top: -3px !important;
      }
    }
  }
  .mobile-row {
    @media (max-width: 1024px) {
      flex-direction: row;
      width: 100% !important;
    }
    @media (max-width: 767px) {
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0px;
    }
  }

  .loader-section {
    width: 100% !important;
    display: flex !important;
    justify-content: center;
  }

  .dd-loader{
    margin-bottom: 10px;

    @media(min-width: 1024px){
      position: absolute;
      margin-bottom: 0;
      right: 245px;
      top: 281px;
    }
  }

  .edit_line_item {
    display: table-row;
  }

  .wd-20 {
    width: 20% !important;

    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .fs-small {
    font-size: small;
  }

  .fs-smaller {
    font-size: smaller;
  }
  .fs-12px {
    font-size: 14px;
    font-family: Roboto;
    color: #212e33;
  }

  .text-red {
    color: red !important;
  }

  .text-underline {
    text-decoration: underline !important;
  }

  .customCol {
    border: 1px solid #c2c2c2;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    padding: 5px;
    text-decoration: initial;
  }

  .custombtnn {
    background: transparent;
    border: none;
    outline: none;
    text-decoration: underline;
    cursor: pointer;
    height: 100%;
    
  }

  .col-btn {
    height: 35px;
    width: 100%;
    background-color: red;
    color: #ffffff;
    border-radius: 5px;
    outline: none;
    border: none;
    margin: 5px 0;
    font-size: smaller;
    font-weight: 600;
  }

  .ChooseReport {
    min-height: 300px;
    width: 100%;
    border: 1px solid black;

    .header_Report {
      background-color: gray;
      padding: 5px;
      .title {
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .body_Report {
      overflow-y: scroll;
      text-align: left;
      color: red;
      margin: 5px 0;
      padding: 10px;

      .title {
        border-bottom: 1px solid #c2c2c2;
      }

      .checkbox {
        width: 100%;
        padding: 10px;
        margin: 5px;
        font-size: 22px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  .catBox_child {
    width: 98.5%;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 0px 0px;
    button {
      border-bottom: 1px solid #c2c2c2;
    }
  }
`;
