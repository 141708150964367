import React, { Component, useState, useEffect } from 'react'
import styled from 'styled-components';
import { Accordion, Card, Button, Table, Row, Col } from 'react-bootstrap';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { FaPlus, FaCaretDown, FaCircleNotch } from "react-icons/fa";
import {Link} from 'gatsby';
import Select, { components } from 'react-select';
import { isEmpty } from '../../CommonComponents/helper';
import LineItem from './LineItem';
import Plus from '../../../assets/plus.svg';
import { getManufacturerLineItemRedis, getLineItemByIDs, searchAllLineItems, getDefaultManufacturer, createDocLineItem, createLineItem, getLineItemByMan, getLineItemByID, deleteDocumentCategory, updateDocCategoryName, updateDocLineItemSortOrder} from '../../../graphql/queries';
import EdiText from 'react-editext';
import CLight from "../../../assets/add_cus.svg";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import config from '../../../aws-exports';
import Move from '../../../assets/move.svg';
import { useSelector, useDispatch } from "react-redux";
import Cancel from '../../../assets/cancel_line.svg';
import { FaTrashAlt } from "react-icons/fa";
import { GoKebabVertical } from "react-icons/go";
import { FaGripVertical } from "react-icons/fa";
import Loaderx from "../../../assets/pageload.gif";
import ExpandUp from "../../../assets/expand.svg";
import ExpandDown from "../../../assets/down-arrow.svg";
import ScopeTypes from "../../../constants/ScopeTypes";
import ScopeUnits from '../../../constants/ScopeUnits';
import  secureLocalStorage  from  "react-secure-storage";

Amplify.configure(config);
const CaretDownIcon = () => {
    return <FaCaretDown color={'#000'} />;
  };
  
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
export default function Category(props) {
    const {consoleLog} = require('../../commonFunctions.js');
    consoleLog("Category props: ", props);
    consoleLog("Category props.category.line_items: ", props.category.line_items);

    const [reportTypes, setReportTypes] = React.useState([]);
    const [reportUnits, setReportUnits] = React.useState([]);

    const updatedStructure = useSelector((state) => state.templateStructure);
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));
    const [structureIndex, setStructureIndex] = React.useState(props.structureIndex)
    const [defaultManufacturers, setDefaultManufacturers] = React.useState(useSelector((state) => state.defaultManufacturer));
    const [default_category, setDCategory] = React.useState(useSelector((state) => state.defaultCategories));
    const dispatch = useDispatch();

    let df_units = useSelector((state) => state.defaultUnits);
    const [defaultUnits, setDefaultUnits] = React.useState(df_units);
    const [UNITS, setUnits] = React.useState([]);
    const [selectedScopeCalc, setSelectedScopeCalc] = React.useState({});
    const [selectedScopeType, setSelectedScopeType] = React.useState({});
    const [selectedWaste, setSelectedWaste] = React.useState(0);
    const [selectedUnitTo, setSelectedUnitTo] = React.useState('');
    const [selectedRatioFrom, setSelectedRatioFrom] = React.useState(0);
    const [selectedRatioTo, setSelectedRatioTo] = React.useState(0);
    const [selectedConvRound, setSelectedConvRound] = React.useState(false);
    const [selectedCategory, setSelectedCategory] = React.useState(0);
    
    const [manufacturersLineItems, setManufacturersLineItems] = React.useState([]);
    const [showAddCat, toggleShowAddCat] = React.useState(true);
    const [manufecturerValue, setManufecturerValue] = React.useState('Select');
    const [lineItems, setLineItems] = React.useState(props.category.line_items ? props.category.line_items : [] )
    
    const [manufecturerLineItemToggle, setManufecturerLineItemToggle] = React.useState(false);
    const [lineItemSearchText, setLineItemSearchText] = React.useState('');
    const [showLineItem, toggleShowLineItem] = React.useState(false);
    const [inputList, setInputList] = React.useState([]);
    const [dragPositionCategory, setDragPositionCategory]  = useState(lineItems);

    const [lineItemSearchLoader, setLineItemSearchLoader] = useState(false);
    const [lineItemAddLoader, setLineItemAddLoader] = useState(false);

    const [lineItemAdd, setLineItemAdd] = useState(false);

    let report_types = [{ value: "(None)", label: "(None)" }];
    for (let i = 0; i < ScopeTypes.length; i++) {
        report_types.push({
            value: ScopeTypes[i].id,
            label: ScopeTypes[i].title,
        });
    }

    let d_units = [{ value: "", label: "(None)" }];
    for (let i = 1; i < defaultUnits.length; i++) {
        consoleLog("defaultUnits: ", defaultUnits[i]);
        d_units.push({ value: defaultUnits[i].name, label: defaultUnits[i].name });
    }

    useEffect(() => {
        setStData(updatedStructure);
        setLineItems(props.category.line_items ? props.category.line_items : [] );
        setStructureIndex(props.structureIndex);
        setDragPositionCategory(props.category.line_items);
        consoleLog("Category on useEffect sturctureIndex: ", structureIndex);
        setReportTypes(report_types);
        setUnits(d_units);
    }, [props.category.line_items, structureIndex]);

    ////
    async function fetchAlgo(scopeType) {
        let scopes = [];
        let type = scopeType;
        if (scopeType === 'RoofScopeX' || scopeType === 'RoofScope+') {
            type = 'RoofScope';
        }

        let reportUnits = [{ value: "(None)", label: "(None)" }];
        let scopetUnits = ScopeUnits[type];
        if (scopeType === 'RoofScope+') {
            scopetUnits = { ...scopetUnits, ...ScopeUnits['GutterScope'] }
        }

        if (!isEmpty(stData.quantities)) {
            let quantities = stData.quantities;
            if (typeof quantities === 'string') {
                quantities = JSON.parse(stData.quantities);
            }

            let structure = quantities && quantities.length
                ? quantities.find(quantity => quantity.structure_id == props.structureId)
                : null;

            if (!isEmpty(structure)) {
                scopes = structure.scopes;
                let quantityList = scopes && scopes.length
                    ? scopes.find(scope => scope.scope_type_name.toLowerCase() == type.toLowerCase())
                    : null;
                if (!isEmpty(quantityList)) {
                    scopetUnits = { ...scopetUnits, ...quantityList.quantities };
                }
                if (scopeType === 'RoofScope+') {
                    let gutterScopeList = scopes && scopes.length
                        ? scopes.find(scope => scope.scope_type_name.toLowerCase() == 'gutterscope')
                        : null;
                    if (!isEmpty(gutterScopeList)) {
                        scopetUnits = { ...scopetUnits, ...gutterScopeList.quantities };
                    }
                }
            }
        }

        for (const [key, value] of Object.entries(scopetUnits)) {
            if (scopeType === 'RoofScopeX' && key !== 'total_squares' && isNaN(parseInt(value.name))) {
                continue;
            }
            reportUnits.push({
                value: value.algo,
                label: `${value.name} (${value.unit})`,
                unit: value.unit
            });
        }
        if (scopes.length > 0 && type !== 'RoofScope') {
            let roofScopeList = scopes && scopes.length
                ? scopes.find(scope => scope.scope_type_name.toLowerCase() == 'roofscope')
                : null;

            if (!isEmpty(roofScopeList)) {
                for (const [key, value] of Object.entries(roofScopeList.quantities)) {
                    if (isNaN(parseInt(value.name))) {
                        continue;
                    }
                    reportUnits.push({
                        value: value.algo,
                        label: `${value.name}${value.unit})`,
                        unit: value.unit
                    });
                }
            }
        }
        setReportUnits(reportUnits);
    }
    const handleChangeUnitTo = (e) => {
        consoleLog("handleChangeUnitTo e: ", e.value);
        setSelectedUnitTo(e.value);
    }
    const handleChangeWaste = (e) => {
        consoleLog("handleChangeWaste e: ", e.target.value);
        setSelectedWaste(e.target.value)
    }
    const handleChangeScopeType = (e) => {
        consoleLog("handleChangeScopeType e: ", e);
        if (e.label !== '(None)') {
          setSelectedScopeType(e);
          fetchAlgo(e.label);
        }
    }

    const handleChangeScopeCalc = (e) => {
        if (typeof e === 'object') {
          consoleLog("handleChangeScopeCalc e: ", e);
          if(e.value != "(None)"){
            setSelectedScopeCalc(e);
          }else{
            setSelectedScopeCalc({});
          }
        }
    }

    const handleSelectedCategory = (e) => {
        consoleLog('handleSelectedCategory', e.value);
        setSelectedCategory(e.value);
    }

    const handleSelectedRatioFrom = (e) => {
        consoleLog('handleSelectedRatioFrom', e.target.value);
        setSelectedRatioFrom(e.target.value);
    }

    const handleSelectedRatioTo = (e) => {
        consoleLog('handleSelectedRatioTo', e.target.value);
        setSelectedRatioTo(e.target.value);
    }

    const handleSelectedConvRound = (e) => {
        consoleLog('handleSelectedConvRound', e.target.value);
        setSelectedConvRound(!selectedConvRound);
    }

    function handleAddItemAdd() {
        if (lineItemSearchText == '') {
            props.setShow(true);
            props.setShowMessage('Please enter Line Item name.');
            props.setShowMessageType('Error');
            return false;
        }
        else {
            setLineItemAdd(true);
        }
    }
    ////

    async function manufecturerLineItems(manufacturer_id) { // redis
        consoleLog("manufecturerLineItems: manufacturer_id: ", manufacturer_id);
        const r_menufecturer_line_items = await API.graphql({
            query: getManufacturerLineItemRedis,
            variables: {
                id: manufacturer_id,
            },
        });
        let rm_lines = JSON.parse(r_menufecturer_line_items.data.getManufacturerLineItemRedis);
        consoleLog("rd_menufecturer_line_items: ", rm_lines);

        if(rm_lines.body == null){
            const menufecturer_line_items = await API.graphql({
                query: getLineItemByMan,
                variables: {
                    manufacturer_id: manufacturer_id
                }
            })
            consoleLog("menufecturer_line_items: ", menufecturer_line_items)
            setManufacturersLineItems(menufecturer_line_items.data.getLineItemByMan);
        }else{
            let rm_linex = JSON.parse(rm_lines.body);
            consoleLog("menufecturer_line_items: else:", rm_linex);
            setManufacturersLineItems(rm_linex);
        }
    }

    async function manufecturerLineItemsById(line_item_id) {
        consoleLog("line_item_id: ", line_item_id)
        const menufecturer_line_items_by_id = await API.graphql({
            query: getLineItemByID,
            variables: {
                line_item_id: line_item_id
            }
        });

        let adj_quantity = (1 * menufecturer_line_items_by_id.data.getLineItemByID.adjusted_quantity);
        let cost = (1 * menufecturer_line_items_by_id.data.getLineItemByID.cost);

        if(menufecturer_line_items_by_id.data.getLineItemByID.sub_line_item == "[]"){
            menufecturer_line_items_by_id.data.getLineItemByID.sub_line_item = [];
        }else{
            menufecturer_line_items_by_id.data.getLineItemByID.sub_line_item = JSON.parse(menufecturer_line_items_by_id.data.getLineItemByID.sub_line_item);
        } 

        let netCost = adj_quantity * cost;

        netCost =  Number.parseFloat(netCost).toFixed(2);

        let temp_object = {};
        temp_object.net_cost = netCost;

        let li_object = Object.assign(menufecturer_line_items_by_id.data.getLineItemByID, temp_object);

        consoleLog("menufecturer_line_items_by_id: ", menufecturer_line_items_by_id.data.getLineItemByID)
        return menufecturer_line_items_by_id.data.getLineItemByID;
    }
    
    function handleManufecturerChange(e) {
        // when we select a manufacturer from the dropdown
        consoleLog("handleManufecturerChange e.value: ", e.value)
        setManufecturerValue(e.value);
        if(typeof e.value != 'string'){
            manufecturerLineItems(e.value);
        }else{
            searchAllLineItemsFunc(lineItemSearchText);
        }
    }

    async function searchAllLineItemsFunc(search){
        if(search != ''){
            setLineItemSearchLoader(true);
            consoleLog('searchAllLineItemsFunc',defaultManufacturers);
            let def_arr = [];
            for (let def of defaultManufacturers) {
                def_arr.push(def.value);
            }
            consoleLog('searchAllLineItemsFunc',def_arr.join());
            let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
            let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
            const mu_line_items = await API.graphql({
                query: searchAllLineItems,
                variables: {
                client_id: client_id,
                team_id: team_id,
                search: search,
                man_ids: def_arr.join()
                },
            });
            consoleLog('searchAllLineItemsFunc',mu_line_items);
            let mu_line_itemx = JSON.parse(mu_line_items.data.searchAllLineItems);
            consoleLog('searchAllLineItemsFunc',mu_line_itemx.body);
            setManufecturerLineItemToggle(true);
            setManufacturersLineItems(mu_line_itemx.body);
            setLineItemSearchLoader(false);
        }
    }

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        // consoleLog(index,list[index]);
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { notes: ""}]);
    };

    function handleLineItemChange(line_item_id){
        consoleLog("handleLineItemChange line_item_id: ", line_item_id)
        var lineItems_copy = [...lineItems];
        lineItems_copy = lineItems_copy.filter(lic => lic.line_item_id !== line_item_id);
        consoleLog("handleLineItemChange lineItems_copy: ", lineItems_copy)
        setLineItems(lineItems_copy);
        consoleLog("handleLineItemChange lineItems_copy: ", lineItems_copy)

    
        let allData = {...stData};
        allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).line_items=[...lineItems_copy];
        consoleLog('OnHandleLineItemChange: ', allData);
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
    }

    function handleLineItemChangeText(e){
        consoleLog('handleLineItemChangeText>',manufecturerValue);
        if(e.target.value == "" && manufecturerValue == 'Select'){
            consoleLog('handleLineItemChangeText> 1', e.target.value);
            setLineItemSearchText(e.target.value);
            setManufecturerLineItemToggle(false);
        }
        if(e.target.value == "" && manufecturerValue != 'Select'){
            consoleLog('handleLineItemChangeText> 2', e.target.value);
            setLineItemSearchText(e.target.value);
            setManufecturerLineItemToggle(false);
        }
        if(e.target.value != "" && manufecturerValue == 'Select'){
            consoleLog('handleLineItemChangeText> 3', e.target.value);
            setLineItemSearchText(e.target.value);
            // setManufecturerLineItemToggle(false);
            searchAllLineItemsFunc(e.target.value);
        }
        if(e.target.value != "" && manufecturerValue != 'Select'){
            consoleLog('handleLineItemChangeText> 4', e.target.value);
            setLineItemSearchText(e.target.value);
            setManufecturerLineItemToggle(true);
        }
    }

    function resetNewLineData(){
        setSelectedScopeCalc({});
        setSelectedScopeType({});
        setSelectedWaste(0);
        setSelectedUnitTo('');
        setSelectedRatioFrom(0);
        setSelectedRatioTo(0);
        setSelectedConvRound(false);
        setSelectedCategory(0);
    }

    async function handleCreateLineItem() { // Create a custom line item
        consoleLog('handleCreateLineItem lineItemSearchText>', lineItemSearchText.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '-'));
        consoleLog('handleCreateLineItem selectedScopeType>', selectedScopeType);
        consoleLog('handleCreateLineItem selectedScopeCalc>', selectedScopeCalc);
        
        if(lineItemSearchText == ''){
            props.setShow(true);
            props.setShowMessage('Please enter Line Item name.');
            props.setShowMessageType('Error');
            return false;
        }
        let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
        let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;
        let li_name = lineItemSearchText;
        setLineItemSearchText('');
        setManufecturerLineItemToggle(false);
        setManufecturerValue('Select');
        toggleShowLineItem(!showLineItem);
        const create_line_item = await API.graphql({ // Create a line item in am_est_line_item table
            query: createLineItem,
            variables: {
                doc_type_id: props.url_params.doc_type_id != 'undefined' ? props.url_params.doc_type_id : allData.doc_type_id,
                manufacturer_id: 0,
                sku: '',
                scope_type_id: selectedCategory, //
                line_item_code: li_name.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '-'),
                name: li_name,
                description: "",
                cost: 0,
                quantity: 0,
                adjusted_quantity: 0,
                unit: selectedScopeCalc.unit ? selectedScopeCalc.unit : 'SQ',
                convert_to: selectedUnitTo,
                ratio_from: selectedRatioFrom, //
                ratio_to: selectedRatioTo, //
                convert_round: selectedConvRound ? 1 : 0, //
                algorithm: selectedScopeCalc.value ? selectedScopeCalc.value : '',
                labor_cost: 0,
                material_cost: 0,
                profit: 0,
                overhead: 0,
                waste: selectedWaste, //
                notes: '',
                sub_line_item: '[]',
                manufacturer: '',
                apply_op: 1,
                product: '',
                team_id: team_id,
                user_id: client_id,
                status: 1
            }
        });

        let line_item_id = JSON.parse(create_line_item.data.createLineItem).insertId; // Get the id of newly created line item

        props.setShow(true);
        props.setShowMessage('New Line Item has been added!');
        props.setShowMessageType('Success');

        consoleLog('handleCreateLineItem>',JSON.parse(create_line_item.data.createLineItem));
        consoleLog('handleCreateLineItem',line_item_id);
        consoleLog('handleCreateLineItem props.url_params.doc_id',props.url_params.doc_id);
      
        let allData = { ...stData };

        let new_line_item = {
            line_item_id: line_item_id,
            manufacturer_id: 0,
            sku: '',
            scope_type_id: selectedCategory,
            line_item_code: li_name.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '-'),
            name: li_name,
            description: "",
            cost: 0,
            quantity: 0,
            adjusted_quantity: 0,
            unit: selectedScopeCalc.unit ? selectedScopeCalc.unit : 'SQ',
            convert_to: selectedUnitTo,
            ratio_from: selectedRatioFrom,
            ratio_to: selectedRatioTo,
            convert_round: selectedConvRound ? 1 : 0,
            algorithm: selectedScopeCalc.value ? selectedScopeCalc.value : '',
            labor_cost: 0,
            material_cost: 0,
            profit: 0,
            overhead: 0,
            waste: selectedWaste,
            notes: '',
            sub_line_item: '[]',
            manufacturer: '',
            apply_op: 1,
            product: '',
            color:'', //new
            remove_prices: 0,
            sort_order: 0,
            status: 1
        } // Display on the front end
        if(typeof props.url_params.doc_id != 'undefined'){
            let doc_cat_id = allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_category_id;
            let doc_struct_id = allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_structure_id;
            new_line_item.doc_id=props.url_params.doc_id;
            new_line_item.doc_type_id=props.url_params.doc_type_id;
            new_line_item.doc_structure_id=doc_struct_id*(1);
            new_line_item.doc_category_id=doc_cat_id*(1);
        }else{
            new_line_item.doc_type_id=allData.doc_type_id;
        }

        resetNewLineData();

        consoleLog('handleCreateLineItem new_line_item',new_line_item);
      
        let current_line_items = props.category.line_items;
        consoleLog('handleCreateLineItem current_line_items >',current_line_items);

        current_line_items.push(new_line_item);
        consoleLog('handleCreateLineItem current_line_items >>',current_line_items);
      
        allData.structures[structureIndex].categories.find((c) => c.category_id == props.category.category_id).line_items = current_line_items;
      
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        setStData(allData);

        if(typeof props.url_params.doc_id != 'undefined'){
            props.updateDocOnElasticSearch();
        }
        // await handleLineItemSelect(line_item_id);
    }

    async function handleLineItemSelect(selected_line_item_id, selected_line_item){ // Add manufacturer line item
        consoleLog("handleLineItemSelect selected_line_item_id:", selected_line_item_id);
        consoleLog("handleLineItemSelect selected_line_item:",selected_line_item);
        setLineItemAddLoader(true);
        let selected_line_item_size = Object.keys(selected_line_item).length;
        consoleLog("handleLineItemSelect selected_line_item_size:",selected_line_item_size);
        if(selected_line_item_size > 20){
            var new_line_item = selected_line_item;
        }else{
            var new_line_item = await manufecturerLineItemsById(selected_line_item_id); // Get line item
        }

        setLineItemSearchText('');
        setManufecturerLineItemToggle(false);
        setManufecturerValue('Select');
        toggleShowLineItem(!showLineItem);
        setLineItemAddLoader(false);

        consoleLog('handleLineItemSelect new_line_item', new_line_item);
        
        let allData = stData;

        if(typeof new_line_item.color == 'undefined' || new_line_item.color == null){
            new_line_item.color = '';
        }
        if(typeof new_line_item.manufacturer == 'undefined' || new_line_item.manufacturer == null){
            new_line_item.manufacturer = '';
        }
        if(typeof new_line_item.notes == 'undefined' || new_line_item.notes == null){
            new_line_item.notes = '';
        }
        if(typeof new_line_item.product == 'undefined' || new_line_item.product == null){
            new_line_item.product = '';
        }
        if(typeof new_line_item.remove_prices == 'undefined' || new_line_item.remove_prices == null){
            new_line_item.remove_prices = 0;
        }
        if(typeof new_line_item.sort_order == 'undefined' || new_line_item.sort_order == null){
            new_line_item.sort_order = 0;
        }

        let net_cost = (+new_line_item.adjusted_quantity)*(+new_line_item.cost);
        net_cost = Number.parseFloat(net_cost).toFixed(2);

        let line_item_to_create = {
            line_item_id: new_line_item.line_item_id,
            manufacturer_id: new_line_item.manufacturer_id,
            sku: new_line_item.sku,
            scope_type_id: new_line_item.scope_type_id,
            line_item_code: new_line_item.line_item_code,
            name: new_line_item.name,
            description: new_line_item.description,
            cost: new_line_item.cost,
            quantity: new_line_item.quantity,
            adjusted_quantity: new_line_item.adjusted_quantity,
            unit: new_line_item.unit,
            convert_to: new_line_item.convert_to,
            ratio_from: new_line_item.ratio_from,
            ratio_to: new_line_item.ratio_to,
            convert_round: new_line_item.convert_round*(1),
            algorithm: new_line_item.algorithm,
            labor_cost: new_line_item.labor_cost,
            material_cost: new_line_item.material_cost,
            profit: new_line_item.profit,
            overhead: new_line_item.overhead,
            waste: new_line_item.waste,
            notes: new_line_item.notes,
            sub_line_item: new_line_item.sub_line_item,
            manufacturer: new_line_item.manufacturer,
            apply_op: new_line_item.apply_op,
            product: new_line_item.product,
            color:new_line_item.color,
            remove_prices: new_line_item.remove_prices,
            sort_order: new_line_item.remove_prices,
            status: 1,
            net_cost: net_cost
        } // Display on the front end

        if(typeof props.url_params.doc_id != 'undefined'){
            let doc_cat_id = allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_category_id;
            let doc_struct_id = allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_structure_id;
            line_item_to_create.doc_id=props.url_params.doc_id;
            line_item_to_create.doc_type_id=props.url_params.doc_type_id;
            line_item_to_create.doc_structure_id=doc_struct_id*(1); //new
            line_item_to_create.doc_category_id=doc_cat_id*(1); //new
          }else{
            line_item_to_create.doc_type_id=allData.doc_type_id;
          }

        consoleLog('handleLineItemSelect line_item_to_create >',line_item_to_create);

        let current_line_items = props.category.line_items;
        consoleLog('handleLineItemSelect current_line_items >>',current_line_items);

        current_line_items.push(line_item_to_create);
        consoleLog('handleLineItemSelect current_line_items >>>',current_line_items);

        allData.structures[structureIndex].categories.find((c) => c.category_id == props.category.category_id).line_items = current_line_items;
        props.calculateSubTotal(allData);
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
        setStData(allData);
        
        if(typeof props.url_params.doc_id != 'undefined'){
            props.updateDocOnElasticSearch();
        }

        props.setShow(true);
        props.setShowMessage('New Line Item has been added!');
        props.setShowMessageType('Success');

        // const create_line_item = await API.graphql({
        //     query: createDocLineItem,
        //     variables: {
        //         doc_id:props.url_params.doc_id*(1),
        //         doc_structure_id:doc_struct_id*(1),
        //         doc_category_id:doc_cat_id*(1),
        //         line_item_id:new_line_item.line_item_id,
        //         manufacturer_id:new_line_item.manufacturer_id,
        //         sku:new_line_item.sku,
        //         scope_type_id:new_line_item.scope_type_id,
        //         line_item_code:new_line_item.line_item_code,
        //         name:new_line_item.name,
        //         description:new_line_item.description,
        //         cost:new_line_item.cost,
        //         quantity:new_line_item.quantity,
        //         adjusted_quantity:new_line_item.adjusted_quantity,
        //         unit:new_line_item.unit,
        //         convert_to:new_line_item.convert_to,
        //         ratio_from:new_line_item.ratio_from,
        //         ratio_to:new_line_item.ratio_to,
        //         convert_round:new_line_item.convert_round*(1),
        //         algorithm:new_line_item.algorithm,
        //         labor_cost:new_line_item.labor_cost,
        //         material_cost:new_line_item.material_cost,
        //         profit:new_line_item.profit,
        //         overhead:new_line_item.overhead,
        //         waste:new_line_item.waste,
        //         notes:new_line_item.notes,
        //         sub_line_item:new_line_item.sub_line_item,
        //         manufacturer:new_line_item.manufacturer,
        //         apply_op:new_line_item.apply_op,
        //         product:new_line_item.product,
        //         color:'',
        //         remove_prices:new_line_item.remove_prices,
        //         sort_order:new_line_item.sort_order,
        //     }
        // });

        // let create_line_item_id = JSON.parse(create_line_item.data.createDocLineItem).insertId;
        // consoleLog('handleLineItemSelect create_line_item:',create_line_item_id);
        // new_line_item.doc_line_item_id = create_line_item_id;

        // consoleLog("handleLineItemSelect new_line_item: ", new_line_item);

        // var lineItems_copy = [...lineItems, {...new_line_item}];
        // consoleLog("handleLineItemSelect lineItems_copy after:", lineItems_copy);
        // setLineItems(lineItems_copy);

        // allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).line_items=[...lineItems_copy];
        // dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

        // consoleLog('handleLineItemSelect doc_cat_id:',doc_cat_id);
        // consoleLog('handleLineItemSelect doc_struct_id:',doc_struct_id);
        // consoleLog('handleLineItemSelect props.url_params.doc_type_id:',props.url_params.doc_type_id);
        // consoleLog('handleLineItemSelect props.category.category_id:',props.category.category_id);

    }

    async function updateCategoryTitle(updated_title){
        consoleLog("updateCategoryTitle updated_title: ", updated_title);
        consoleLog("updateCategoryTitle props.category.category_id: ", props.category.category_id);

        let allData = { ...stData }; //updateStructureName structureIndex
        allData.structures[structureIndex].categories.find(c=>c.category_id == props.category.category_id).name = updated_title;

        let id_to_update = allData.structures[structureIndex].categories.find(c=>c.category_id == props.category.category_id).doc_category_id;
        props.onChange(allData);

        props.setShow(true);
        props.setShowMessage('Category title has been updated.');
        props.setShowMessageType('Success');

        consoleLog("updateCategoryTitle id_to_update: ", id_to_update);

        if(typeof props.url_params.doc_id != 'undefined'){
            const update_cat_name = await API.graphql({
                query: updateDocCategoryName,
                variables: {
                name: updated_title,
                doc_category_id: id_to_update
                }
            });
            consoleLog("updateCategoryTitle update_cat_name: ", update_cat_name);
            props.updateDocOnElasticSearch();
        }
    }

    async function removeCategory(){ // to remove category in work order
        consoleLog("removeCategory category_id: ", props.category.category_id)
        consoleLog("removeCategory structureIndex: ", structureIndex)

        let allData = { ...stData };

        let id_to_del = allData.structures[structureIndex].categories.find(c => c.category_id == props.category.category_id);
        consoleLog('removeCategory id:',id_to_del.doc_category_id);

        allData.structures[structureIndex].categories = allData.structures[structureIndex].categories.filter(c => c.category_id != props.category.category_id);
        props.onChange(allData);

        props.setShow(true);
        props.setShowMessage('Category has been deleted.');
        props.setShowMessageType('Success');

        if(typeof props.url_params.doc_id != 'undefined'){
            const del_category = await API.graphql({
                query: deleteDocumentCategory,
                variables: {
                    doc_category_id: id_to_del.doc_category_id
                }
            });
            consoleLog('removeCategory >',del_category);
            props.updateDocOnElasticSearch(); //done
        }
    }

    function ShowLineItemRender(){
        toggleShowLineItem(!showLineItem)
    }

    function handleOnDragEndCategory(results){
        if(!results.destination) return;
        const itemsCategory = Array.from(dragPositionCategory);
        const  [reorderedItemCategory] = itemsCategory.splice(results.source.index, 1);
        itemsCategory.splice(results.destination.index,0,reorderedItemCategory);

        if(typeof props.url_params.doc_id != 'undefined'){
            itemsCategory.forEach((item, i)=>{
                item.sort_order = i;
                API.graphql({
                    query: updateDocLineItemSortOrder,
                    variables: {
                        doc_line_item_id: item.doc_line_item_id,
                        sort_order: i
                    }
                });
            });
        }
          
        consoleLog("handleOnDragEndLineItem itemsCategory", itemsCategory);

        setLineItems([...itemsCategory]);
        let allData = {...stData};
        // consoleLog('see catttt',props.structureIndex,itemsCategory);
        allData.structures[props.structureIndex].categories.find(c=>c.category_id == props.category.category_id).line_items=[...itemsCategory];
        dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

        if(typeof props.url_params.doc_id != 'undefined'){
            props.updateDocOnElasticSearch();
        }
        //props.onReorder([...itemsCategory]);
    }

    async function loadDefaultLineItems(){
        consoleLog('loadDefaultLineItems', props.category.category_id);
        // consoleLog('loadDefaultLineItems', default_category);
    
        let d_data = default_category.find((c) => c.category_id == props.category.category_id).default_data;
        if(d_data != null){
          props.setShowLoaderx(true);
          d_data = JSON.parse(d_data);
          consoleLog('loadDefaultLineItems d_data', d_data[2].line_items);
    
          let new_line_ids = d_data[2].line_items;
          new_line_ids = new_line_ids.join();
          consoleLog('loadDefaultLineItems new_line_ids', new_line_ids);
    
          const get_line_items_data = await API.graphql({
            query: getLineItemByIDs,
            variables: {
              ids: new_line_ids,
            },
          });
    
          let new_line_items = JSON.parse(get_line_items_data.data.getLineItemByIDs);
          new_line_items = JSON.parse(new_line_items).data;
          consoleLog('loadDefaultLineItems new_line_items>>>',new_line_items);
    
          let current_line_items = props.category.line_items;
    
          consoleLog('loadDefaultLineItems current_line_items', current_line_items);
    
          let results = [];
          results = new_line_items.filter(({ line_item_id: id1 }) => !current_line_items.some(({ line_item_id: id2 }) => id2 === id1));
    
          consoleLog('loadDefaultLineItems results', results);
    
          if(results.length > 0){
            for(let i=0; i<results.length; i++){
              current_line_items.push(results[i]);
            }
            let allData = { ...stData };
            allData.structures[structureIndex].categories.find((c) => c.category_id == props.category.category_id).line_items = new_line_items;
          
            dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });
            setStData(allData);
          }
          consoleLog('loadDefaultLineItems >>>>>>>>>>', current_line_items);
          props.setShowLoaderx(false);
        }
    }

    const defaultManufacturerx = [...defaultManufacturers];
    defaultManufacturerx.unshift({value: 'Select', label: 'No Manufacturer'});
	return (
      
        <Cat>
            <Card.Body>
                <div className="addcatagory" onClick={()=>toggleShowAddCat(!showAddCat)}>
                    <div className="accordian-icon">
                        <img src={showAddCat ? ExpandUp : ExpandDown} style={{ width: "23px", height: "auto" }} alt="" />
                    </div>
                    <EdiText 
                        onSave={updateCategoryTitle}
                        viewContainerClassName='my-custom-view-wrapper'
                        editButtonContent={<p className="mb-0" style={{fontFamily:'Rajdhani',marginLeft:'-5px', fontWeight:'600',  fontSize:'14px', color:'#D6E1E5'}}>EDIT</p> }
                        saveButtonContent={<p className="mb-0" style={{fontFamily:'Rajdhani',marginLeft:'-5px', fontWeight:'600',  fontSize:'14px', color:'#D6E1E5',marginTop:'5px'}}>DONE</p> }
                        cancelButtonContent={ <FaTrashAlt size={16} color={'#D6E1E5'}/>}
                        onCancel={removeCategory}
                        hideIcons={true}
                        type='text'
                        value={props.category.name} 
                    />
                </div>
                
                { showAddCat &&
                <div className="catBox">
                    <Row className="p-2 hide-xs m-0 border-bottom" style={{background:'#D6E1E5', minHeight:'41px'}}>
                        <Col className="text-left pl-5 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 customCol">
                            ITEM NAME
                        </Col>
                        <Col className="text-left col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 customCol">
                            QUANTITY
                        </Col>
                        <Col className="text-left col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 customCol">
                            UNIT
                        </Col>
                        <Col className="text-left p-lg-0 p-md-0 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 customCol">
                            Manufacturer
                        </Col>
                        <Col className="text-left col-xl-2 col-lg-2 col-md-2 col-sm-11 col-11 customCol">
                           Color
                        </Col>
                        <Col className="text-left col-xl-1 col-lg-1 col-md-1 col-sm-11 col-11 customCol">
                            Unit Price
                        </Col>
                        <Col className="text-left px-1 col-xl-1 col-lg-1 col-md-1 col-sm-11 col-11 customCol">
                            Net Cost
                        </Col>
                        {/* <Col className="customCol col-1 text-center">
                            NOTE 
                        </Col>
                        <Col className="customCol col-1 text-center">
                            EDIT
                        </Col> */}
                    </Row>          
                    <Row className="d-flex flex-column m-0">
                        <DragDropContext onDragEnd={handleOnDragEndCategory}>
                            <Droppable droppableId="droppable-1">
                                {(provided, _)=>(
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {lineItems.map((line_item,i) => (
                                            <Draggable key={line_item.line_item_id} draggableId={"draggable-"+line_item.line_item_id} index={i}>
                                                {(provided, snapshot)=>(
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            boxShadow: snapshot.isDragging ? "0 0 .7rem #000" : "none",
                                                            background: (i%2 == 1) ? "#E3E3E3" : "",
                                                        }}
                                                    >
                                                       <Handle  {...provided.dragHandleProps}>
                                                         <img src={Move} alt="" />
                                                       </Handle>
                                                        <LineItem
                                                            structureIndex={props.structureIndex}
                                                            structureId={props.category.doc_structure_id}
                                                            // defaultManufacturers={props.defaultManufacturers}
                                                            userManufacturers={props.userManufacturers}
                                                            setUserManufacturers={props.setUserManufacturers}
                                                            categoryId={props.category.category_id}
                                                            line_item={line_item}
                                                            onChange={handleLineItemChange}
                                                            url_params={props.url_params}
                                                            show={props.show}
                                                            showMessage={props.showMessage}
                                                            showMessageType={props.showMessageType}
                                                            setShow={props.setShow}
                                                            setShowMessage={props.setShowMessage}
                                                            setShowMessageType={props.setShowMessageType}
                                                            updateDocOnElasticSearch={props.updateDocOnElasticSearch}
                                                            calculateSubTotal={props.calculateSubTotal}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>  
                    </Row>      

                    {inputList.map((x, i) => {
                        return (
                            <div className="box">
                                <input
                                    name="note"
                                    placeholder="Enter Notes"
                                    className="ip my-2"
                                    value={x.note}
                                    onChange={e => handleInputChange(e, i)} />
                                <div className="btn-box">
                                    <button
                                        className="mr10"
                                        onClick={() => handleRemoveClick(i)}>Remove
                                    </button>
                                </div>
                            </div>
                        );
                    })}


                    { !inputList.length == 0 && 
                        <button
                            className="mr10"
                            onClick={() => handleAddClick()}>Add Another Line
                        </button>
                    }
                    
                    <div className="addItems" style={{ background: "#D6E1E5", minHeight: "41px", borderRadius: "0 0 6px 6px" }}>
                        <div className="pt-3 pb-3">
                            <button
                                className="custombtnn pl-4"
                                onClick={ShowLineItemRender}
                            >
                            <p className="m-0" style={{fontFamily: "Rajdhani", fontSize:'16px', fontWeight:'500'}}>
                                <span className="mr-3 h-auto">  <img src={CLight} style={{width:'25px', height:'auto'}}  alt=""/></span> <b>ADD A LINE</b>
                            </p>
                            </button>
                        </div>
                        {showLineItem &&  
                            <div className="lineItemBox w-100 p-4 pl-5 pr-5 mt-2" style={{background: "#EEF2F4"}}>
                                <div className="w-100 d-flex align-items-center justify-content-between">
                                <div className="w-100 d-flex align-items-center justify-content-between">
                                    <h5 className="m-0" style={{fontSize:'18px', fontFamily:'Roboto', color:'#212E33'}}><strong>Add a Line</strong></h5> 
                              
                                </div>
                                     <img src={Cancel} alt="" style={{width:'26px', height:'auto'}} onClick={ShowLineItemRender} />    
                                </div>
                                                  
                            <div className="my-5">
                                <p className="text" style={{fontSize:'14px', fontFamily:'Roboto', color:'#212E33', fontWeight:'500', marginBottom:'7px'}}>Filter by manufacturer:</p>
                                <Select                                              
                                    options={defaultManufacturerx} // set list of the data
                                    onChange={handleManufecturerChange}
                                    components={{DropdownIndicator }}
                                    className="custom-input-y border-none"
                                    placeholder="No Manufacturer"
                                    styles={{
                                        placeholder: (base) => ({
                                          ...base,
                                          fontSize: "1em",
                                          fontFamily: "Roboto",
                                          color: "#B4BEBF",
                                        }),
                                        control: (provided, state) => ({
                                          ...provided,
                
                                          boxShadow: "none",
                                          border: "1px solid #B4BEBF",
                                          overflowX: "hidden",
                                          border: state.isFocused && "1px solid #B4BEBF",
                                        }),
                                        menu: (provided, state) => ({
                                          ...provided,
                                          border: state.isFocused && "1px solid #B4BEBF",
                                          border: "1px solid #B4BEBF",
                                          overflowX: "hidden",
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                
                                          overflowX: "hidden",
                                          backgroundColor: state.isFocused && "#F7F9FA",
                                          color: state.isFocused && "#212E33",
                                          borderBottom: "1px solid #B4BEBF",
                                        }),
                                      }}
                                />
                            </div>
                        
                            <div className="my-5">
                                <p className="text" style={{fontSize:'14px', fontFamily:'Roboto', color:'#212E33', fontWeight:'500', marginBottom:'7px'}}>Start by typing the name of a line item you‘d like to add here</p>
                               <div className="d-flex align-items-center">
                                <input 
                                    onChange={handleLineItemChangeText}
                                    className="w-100 custom-input-x" 
                                    placeholder="Enter Line Item Name"
                                    value={lineItemSearchText}
                                />
                                 <a
                                    className="add-btn"
                                    onClick={() => handleAddItemAdd()}
                                >
                                    {lineItemSearchLoader ?
                                        <FaCircleNotch size={22} className="awesome_spinner" style={{color:'#414141'}}/>
                                    :
                                        <img src={Plus} alt="" />
                                    }
                                </a>
                                </div>
                                {lineItemAdd &&
                                    <div className='lineItem-selection'>
                                        <Select
                                            options={reportTypes} // set list of the data
                                            value={reportTypes.find(obj => obj.value === selectedScopeType.value) ? reportTypes.find(obj => obj.value === selectedScopeType.value) : '(None)'} 
                                            onChange={handleChangeScopeType}
                                            components={{ DropdownIndicator }}
                                            placeholder="Select a Report Type"
                                            className="custom-input-y border-none line-item-add-first line-spacing"
                                        />
                                        <Select
                                            options={reportUnits} // set list of the data
                                            value={reportUnits.find(obj => obj.value === selectedScopeCalc.value) ? reportUnits.find(obj => obj.value === selectedScopeCalc.value) : '(None)'} 
                                            onChange={handleChangeScopeCalc}
                                            components={{ DropdownIndicator }}
                                            placeholder="Select a Calculation to Use"
                                            className="custom-input-y border-none line-item-add-first line-spacing"
                                        />
                                        { Object.keys(selectedScopeCalc).length === 0 ?
                                            <button className="custombtnn add-line-item-btn" onClick={() => handleCreateLineItem()}>
                                                <p
                                                    className="text"
                                                    style={{
                                                    fontSize: "14px",
                                                    fontFamily: "Roboto",
                                                    color: "rgb(247, 148, 28)",
                                                    fontWeight: "500",
                                                    marginBottom: "0px",
                                                    }}
                                                >
                                                    Skip
                                                </p>
                                            </button>
                                            :
                                            <button className="custombtnn add-line-item-btn" onClick={resetNewLineData}>
                                                <p
                                                    className="text"
                                                    style={{
                                                    fontSize: "14px",
                                                    fontFamily: "Roboto",
                                                    color: "rgb(247, 148, 28)",
                                                    fontWeight: "500",
                                                    marginBottom: "0px",
                                                    }}
                                                >
                                                    Cancel
                                                </p>
                                            </button>
                                        }
                                    </div>
                                }
                                { Object.keys(selectedScopeCalc).length !== 0 &&
                                    <div style={{ marginTop: '3rem', borderTop: '2px solid #D6E1E5' }}>
                                        <hr/>
                                        <p  style={{fontSize:'14px', fontFamily:'Roboto', color:'#212E33', fontWeight:'500', marginBottom:'7px'}}>Waste Factor & Unit Converter</p>
                                        <p>Add a waste factor and/or convert your measurement for your work order by selecting a unit to convert to and specifying the conversion amount.</p>
                                        <div>
                                            <p style={{ fontFamily: 'Roboto', fontWeight: '500', marginBottom: '8px' }}>Waste Factor</p>
                                            <input className='unit-changing-input' onChange={handleChangeWaste} value={selectedWaste} type='number' placeholder='' />
                                        </div>
                                        <div style={{ marginTop: '1rem' }} className="flex-column flex-md-row">
                                            <p style={{ fontFamily: 'Roboto', fontWeight: '500', marginBottom: '8px' }}>Conversion</p>
                                            <div className='unit-conversion-section'>
                                                <input className='line-items-spacing' type='text' disabled={true} defaultValue={selectedScopeCalc.unit} style={{ background: '#f5f5f5', width: '6em', padding: '0.5em', border: '1px solid hsl(0, 0%, 80%)', borderRadius: '4px', height:'41px' }} placeholder='' />
                                                <p style={{ fontFamily: 'Roboto', fontWeight: '500', margin: '0', marginInline: '1.5rem' }}>to</p>
                                                <Select
                                                    options={UNITS} // set list of the data
                                                    value={UNITS.find(obj => obj.value === selectedUnitTo) ? UNITS.find(obj => obj.value === selectedUnitTo) : '(None)'}
                                                    onChange={handleChangeUnitTo}
                                                    components={{ DropdownIndicator }}
                                                    placeholder="(None)"
                                                    className="custom-input-y border-none line-item-add-first line-items-spacing"
                                                />
                                                { selectedUnitTo != '' &&
                                                    <div className='d-flex align-items-center'>
                                                        <input type='text' defaultValue={selectedRatioFrom} onChange={handleSelectedRatioFrom} style={{ color: '#212E33', background: '#f5f5f5', width: '6em', padding: '0.5em', border: '1px solid hsl(0, 0%, 80%)', borderRadius: '4px', height:'41px',  marginRight:'10px', marginLeft:'10px' }} placeholder='' />
                                                        <p className="fs-12px line-item-add-first" style={{marginBottom : '0px'}}>{selectedScopeCalc.unit} = </p>
                                                        <div style={{position:'relative', width: '6em'}}>
                                                            <input className="line-item-add-first" type='text' defaultValue={selectedRatioTo} onChange={handleSelectedRatioTo} style={{ color: '#212E33', background: '#f5f5f5', width: '6em', padding: '0.5em 0em 0.5em 0.5em', border: '1px solid hsl(0, 0%, 80%)', borderRadius: '4px', height:'41px' }} placeholder='' />
                                                            <span
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "-5%",
                                                                    fontSize: "14px",
                                                                    marginTop: "16%"
                                                                }}
                                                                className="fs-12pxs">{selectedUnitTo}
                                                            </span>
                                                        </div>
                                                                
                                                        <input type="checkbox" className='ml-5'
                                                            onClick={handleSelectedConvRound}
                                                            checked={selectedConvRound}
                                                        />
                                                        <label className='ml-2' style={{marginBottom:'-7px'}} onClick={handleSelectedConvRound}>Round up to the nearest whole converted unit</label>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ marginTop: '1rem' }}>
                                            <p style={{ fontFamily: 'Roboto', fontWeight: '500', marginBottom: '8px' }}>Line Item Category:</p>
                                            <div className='add-line-item'>
                                                <Select
                                                    options={reportTypes} // set list of the data
                                                    value={reportTypes.find(obj => obj.value === selectedCategory) ? reportTypes.find(obj => obj.value === selectedCategory) : '(None)'}
                                                    onChange={handleSelectedCategory}
                                                    components={{ DropdownIndicator }}
                                                    placeholder="All Categories"
                                                    className="custom-input-y border-none line-item-add-first conversion-type"
                                                />
                                                <button className="custombtnn add-line-item-btn" onClick={() => handleCreateLineItem()}>
                                                    <p
                                                        className="text"
                                                        style={{
                                                            fontSize: "14px",
                                                            fontFamily: "Roboto",
                                                            color: "rgb(247, 148, 28)",
                                                            fontWeight: "500",
                                                            marginBottom: "0px",
                                                        }}
                                                    >
                                                        Add Line
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                            manufecturerLineItemToggle && 
                            <div className="line-item-listing">
                                <Row className="search-title m-0">
                                    <h5 className="col-12 ">Select an existing line item or add a custom line item by clicking the plus icon.</h5>
                                </Row>
                                <div className="item-list line-item-item-list">
                                    { manufacturersLineItems.filter( (manu_li => manu_li.name.toLowerCase().includes(lineItemSearchText.toLocaleLowerCase()))).map( (manufecturer_item, i) => (
                                    <div className="item item-value line-item-add-item search-result" key={i}  onClick={() => handleLineItemSelect(manufecturer_item.line_item_id, manufecturer_item) }>
                                        <div className="checkbox item-label">
                                            <div className="label-col col xs-11 left">
                                                <b className="fa fa-plus on-new"></b>
                                                <div className="label">{manufecturer_item.name}</div>
                                            </div>
                                            <div className="remove-col col xs-1 right"></div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>                            
                            }
                        </div>
                        }
                    </div>    
                </div>
                }
            </Card.Body>
        </Cat>
	)
}

const Handle = styled.div`
    position:absolute;
    left:0px;
    margin:2.5rem 0px 0px 1em;
    z-index:99;
    cursor:grab;
`

const Cat = styled.div`
    min-height:1px;
    width: 100%;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    justify-content: space-around;
    margin-bottom: 1em;
    font-size: 12px;

    .line-spacing {

        @media(max-width:1024px){
            width: 100% !important;
        }

        @media(max-width:767px){
            margin-top: 10px;
        }
    }

    .line-items-spacing {
        @media(max-width:1024px){
            margin-right: 0 !important;
            width: 100% !important;
        }
    }

    .line-item-add-first {
        margin-right: 10px;

        @media(max-width:767px){
            margin-right: 0;
        }
    }

    .conversion-type {
        @media(max-width:1024px){
            width: 85% !important;
        }

        @media(max-width:767px){
            width: 100% !important;
        }
    }

    .custom-input-y{
        width:25%;
        @media(max-width:600px){
            width:100% !important;
        }
    }


    .custom-input-x{
        height:44px ;
        outline:none ;
        padding:10px ;
        border-radius:4px ;
        border:1px solid #B4BEBF ;
        font-style: normal;
        color:#212E33;
        font-size:14px;
    }
    .add-btn{
        height:44px;
        width:44px;
        background:#D6E1E5;
        margin:0 5px;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:5px;
        cursor: pointer;
    }
    .fDlEee{
        margin: 0.5em;
    }

    .w-25{
        width: 25%;
    }

    .w-20{
        width: 20%;
    }

    .w-12{
        width: 12%;
    }

    .w-10{
        width: 10%;
    }

    .w-7{
        width: 7%;
    }

    /* .css-1hb7zxy-IndicatorsContainer{
        background: #414141;
        color: #ffffff;
    }
    .css-yk16xz-control{
        border-color: #414141;
    }
    .css-yk16xz-control:hover{
        border-color: #414141;
    }
    .css-1okebmr-indicatorSeparator{
        background-color: #414141;
    } */
    .css-yk16xz-control{
        height:41px;
    }
    .border-bottom{
        border-bottom:1px solid #c2c2c2;
        display:flex;
        align-items:center;
    }

    .card-body{
        padding: 0px;
        margin-bottom: 5px;
    }
    .unit-changing-input {
        color: rgb(33, 46, 51);
        width: 6em;
        padding: 0.5em;
        margin-right: 0.5em;
        border: 1px solid rgb(204, 204, 204);
        border-radius: 4px;
        height: 41px;
        @media(max-width: 1024px){
            width: 100%;
        }
    }

    .unit-conversion-section {
        display: flex;
        align-items: center;
    }

    .css-83bb2w-menu {
        div:last-child {
           border-bottom: none;
        }
      }

    .label {
        color: #414141 !important;
        padding: .5em;
        cursor: pointer;
    }

    .item-label {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .item-list {
        background: white;
        border: 1px solid #414141;
        border-radius: 5px;
        overflow: hidden;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
    
    .search-title {
        color: white;
        background: #6a6e6f;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: .5em;
        border: 1px solid #414141;
        border-bottom: 0;
    }

    .search-result {
        cursor: pointer;
        border-bottom: 1px solid #B4BEBF;
    }


    .addcatagory{
        position: relative;
        cursor: pointer;
        color: white;
        background-color: #737F82;
        padding-left: 2em;
        height:62px;
        padding-top: 9px;
        padding-right: 53px;
        border-radius:6px 6px 0px 0px;
    }

    .accordian-icon {
        position: absolute;
        right: 30px;
        top: 17px;
      }

    .bg-color {
        background-color: lightgrey;
    }

    .line-item-item-list{
        max-height: 200px;
        overflow-y: scroll;
    }

    .selectLine{
        width:100%;
        min-height:20px;
        text-align:center;
        background:#6a6e6f;
        padding:5px;
        margin:10px 0;

        .text{
            width:100%;
            padding:5px;
            border-top:1px solid #c2c2c2;
            border-bottom:1px solid #c2c2c2;
        }
    }
    .my-custom-view-wrapper{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding-right:10px;
        font-size:18px;
        font-weight:600;
        margin-left:-2px;
        font-family:Roboto;
    }
    .styles-module_Editext__button__sxYQX{
        background-color:transparent !important;
        content:'Edit' !important;
        border:none !important;
        outline:none !important;
    }
    .styles-module_Editext__input__2-M50{
        color:black !important;
    }
    .styles-module_Editext__edit_button__310_J {
        color:#c2c2c2 !important;
    }
    .styles-module_Editext__save_button__1Dlwo {
        color:#c2c2c2 !important;
    }
    .styles-module_Editext__cancel_button__26sqr{
        color:#c2c2c2 !important;
    }
    .css-1uccc91-singleValue{
        top: 50% !important;
    }

    .hide-xs{
        
        @media(max-width:600px){
            display:none;
        }
    }
    .customCol{
        text-align: left;
        font-weight: 600;
        font-size: 12px;
        color:#212E33;
        text-transform:uppercase;
        font-family:Rajdhani;
        text-decoration: initial;

        @media(max-width:600px){
            padding: 0;
        }
    }
    .ip{
        height:40px ;
        width:308px;
        outline:none ;
        border-radius:4px ;
    }

    .addItems{
        width:100%;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .add-line-item {
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: unset;
          }
    }

    .lineItem-selection {
        display: flex;
        margin-top: 3rem;

        @media(max-width: 1024px){
            margin-top: 1rem;
        }
    
        @media (max-width: 767px) {
          flex-direction: column;
        }
      }

    .custombtnn{
        background:transparent;
        border:none;
        outline:none;
        cursor: pointer;
        display:flex;
        align-items:center;
        
    }

    .add-line-item-btn {
        @media(max-width: 767px) {
            margin-top: 10px;
        }
    }

    .search-title{
        font-size: .9em;
        font-weight: 600;
        color: white;
        background: #6a6e6f;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: .5em;
        border: 1px solid #414141;
        border-bottom: 0;
    }
`

const NestedAccordian = styled.div`
    min-height:200px;
    width:100%;
    display:flex;
    align-items:flex-start;
    justify-content:center;
    flex-direction:column;
`