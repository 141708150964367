import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import { Accordion, Card, Button, Table, Row, Col } from "react-bootstrap";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { FaRegWindowClose } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { FaTrashAlt, FaPercent, FaInfoCircle, FaCircleNotch } from "react-icons/fa";
import config from "../../aws-exports";
import Trashh from "../../assets/trash.svg";
import TransparentTrash from "../../assets/trash_transparent.svg";
import Plus from "../../assets/plus.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  removeCategory,
  saveCategory,
  getCategoryByUserID,
  AddMarkupOrTax,
  saveDocMarkup,
  deleteMarkupOrTax,
  deleteDocMarkup,
  updateMarkup,
  createOnRedis
} from "../../graphql/queries";
import RadioBoxRow from "./radioBoxRow";
import Cancel from "../../assets/cancel_line.svg";
import { func } from "prop-types";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import  secureLocalStorage  from  "react-secure-storage";

Amplify.configure(config);

export default function AddMarkups(props) {
  const {consoleLog} = require('../commonFunctions.js');
  
  const [addStructureTitle, setAddStructureTitle] = useState(false);
  const [stData, setStData] = React.useState(
    useSelector((state) => state.templateStructure)
  );
  const [default_manufecturer, setDManufecturer] = React.useState(
    useSelector((state) => state.defaultManufacturer)
  );
  const [default_category, setDCategory] = React.useState(
    useSelector((state) => state.defaultCategories)
  );

  const [addTaxLoader, setAddTaxLoader] = React.useState(0);

  var taxesArray = [];
  var taxesIdArray = [];

  var allTaxes = Object.entries(props.stData.markups);

  if (allTaxes.length > 0) {
   
    let i;

    let taxesElems = {};

    for (i = 0; i < allTaxes.length; i++) {
      if (allTaxes[i][1].type == "markup" || allTaxes[i][1].type == "op") {
        // we have this bug in the legacy prodocs type must not be 'op'
        // taxesElems.id = allTaxes[i][1].markup_id;
        taxesElems.name = allTaxes[i][1].name;
        taxesElems.value = allTaxes[i][1].value;
        taxesElems.type = allTaxes[i][1].type;
        // taxesElems.sort_order = allTaxes[i][1].sort_order;
        taxesElems.percent = allTaxes[i][1].percent;
        taxesElems.for = allTaxes[i][1].for;
        taxesElems.markup_id = allTaxes[i][1].markup_id;
        taxesElems.default = allTaxes[i][1].default;
        // consoleLog('AddMarkups i: ', taxesElems);
        taxesArray.push(taxesElems);
        taxesIdArray.push(allTaxes[i][1].markup_id);
        taxesElems = {};
      }
    }
    consoleLog("AddMarkups array: ", taxesArray);
    consoleLog("AddMarkups array ids: ", taxesIdArray);
  }

  const [savedMarkup, addSavedMarkup] = React.useState(taxesArray);

  ////////////////////////////  default / saved taxes (Select Tax Fields to Add)

  var defTaxesArray = [];

  let defaultMarkups = props.default_tNm;
  if (props.default_tNm.length > 0) {
    consoleLog("fetchDefaultTaxesMarkups addTaxes>", props.default_tNm);

    let i;

    var taxeElems = {};
    for (i = 0; i < props.default_tNm.length; i++) {
      if (
        props.default_tNm[i].type == "markup" ||
        props.default_tNm[i].type == "op"
      ) {
        if (!taxesIdArray.includes(props.default_tNm[i].markup_id)) {
          // taxeElems.id = props.default_tNm[i].markup_id;
          taxeElems.name = props.default_tNm[i].name;
          taxeElems.value = props.default_tNm[i].value;
          taxeElems.type = props.default_tNm[i].type;
          // taxeElems.sort_order = props.default_tNm[i].sort_order;
          taxeElems.percent = props.default_tNm[i].percent;
          taxeElems.for = props.default_tNm[i].for;
          taxeElems.markup_id = props.default_tNm[i].markup_id;
          taxeElems.default = props.default_tNm[i].default;
          // consoleLog('AddTaxes i: ', taxesElems);
          defTaxesArray.push(taxeElems);
          taxeElems = {};
        }
      }
    }
    consoleLog("defTaxesArray", defTaxesArray);
  }

  const [markup_by_user_id, setDMarkupByUserId] = React.useState(defTaxesArray);
  ///////////////////////////////

  const dispatch = useDispatch();

  function showHideMarkupForm() {
    addStructureTitle
      ? setAddStructureTitle(false)
      : setAddStructureTitle(true);
    consoleLog("addStructureTitle: ", addStructureTitle);
  }

  async function addDefaultMarkup(markup) {
    // Add Default Markup to Template Markups

    consoleLog("addDefaultMarkup> ", markup);
    setAddTaxLoader(markup.markup_id);

    var doc_idx = props.url_params.doc_id;
    if(typeof props.url_params.doc_id=='undefined'){
      doc_idx = 0;
    }

    try{
      const add_tax = await API.graphql({
        query: saveDocMarkup,
        variables: {
          doc_id: doc_idx,
          markup_id: markup.markup_id,
          type: markup.type,
          for: markup.for,
          name: markup.name,
          percent: markup.percent,
          sort_order: 0,
          value: markup.value,
        },
      });

      let taxxx = JSON.parse(add_tax.data.saveDocMarkup);

      consoleLog("addDefaultMarkup add_tax>", taxxx);

      let doc_id = taxxx.insertId;

      var markupCopy = [...savedMarkup];
      const markupData = {};
      markupData.markup_id = markup.markup_id;
      markupData.type = markup.type;
      markupData.value = markup.value;
      markupData.name = markup.name;
      markupData.sort_order = 0;
      markupData.percent = markup.percent;
      markupData.for = markup.for;
      markupData.default = markup.default;
      markupCopy.push(markupData);
      addSavedMarkup(markupCopy);
      let allData = stData;
      let tempObj = {};
      tempObj[doc_id] = markupData;

      if(Array.isArray(allData.markups)){
        allData.markups = {};
      }

      let newObj = Object.assign(allData.markups, tempObj);
      allData.markups = newObj;

      // remove the object from default taxes
      var defTaxCopy = [...markup_by_user_id];
      defTaxCopy = defTaxCopy.filter((t) => t.markup_id !== markup.markup_id);
      setDMarkupByUserId(defTaxCopy);

      setStData(allData);

      dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

      setAddTaxLoader(0);

      if(typeof props.url_params.doc_id != 'undefined'){
        props.updateDocOnElasticSearch();
      }

      props.calculateSubTotal(allData);

      consoleLog("MARKUP DATA", newObj);
      consoleLog("MARKUP DATA x", allData.markups);
    }catch(err){
      console.error(err);
    }
  }

  async function deleteTempMarkup(markup) {
    // delete template markups
    var markupCopy = [...savedMarkup]; // template markups
    var defMarkupCopy = [...markup_by_user_id]; // defaults markups [to add back this markup to default markups]
    consoleLog("deleteTempMarkup>", markup);
    consoleLog("deleteTempMarkup markupCopy>", markupCopy);
    consoleLog("deleteTempMarkup defMarkupCopy>", defMarkupCopy);

    markupCopy = markupCopy.filter((m) => m.markup_id !== markup.markup_id);
    addSavedMarkup(markupCopy);
    consoleLog("deleteTempMarkup markupCopy updated>", markupCopy);

    let markupObj = {};
    markupObj.name = markup.name;
    markupObj.value = markup.value;
    markupObj.type = markup.type;
    markupObj.percent = markup.percent;
    markupObj.for = markup.for;
    markupObj.markup_id = markup.markup_id;
    defMarkupCopy.push(markupObj);
    consoleLog("deleteTempMarkup>>", defMarkupCopy);
    setDMarkupByUserId(defMarkupCopy); // markup added to default markups

    let allData = {...stData};
    consoleLog("deleteTempMarkup allData.markups>>>", allData.markups);

    let ind = Object.entries(allData.markups).find(m=>(m[1].markup_id==markup.markup_id))[0];

    consoleLog("deleteTempMarkup markup.markup_id>>>", markup.markup_id);
    consoleLog("deleteTempMarkup ind>>>", ind);

    delete allData.markups[ind];

    consoleLog("deleteTempMarkup allData>>>", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);
    
    try{
      const del_markup = await API.graphql({
        query: deleteDocMarkup,
        variables: {
          doc_markup_id: ind,
        },
      });
      consoleLog("deleteTempMarkup del_markup>>>", del_markup);
    }catch(err){
      console.error(err);
    }

    if(typeof props.url_params.doc_id != 'undefined'){
      props.updateDocOnElasticSearch();
    }
  }

  async function deleteDefMarkup(markup) {
    // delete default markup
    let defaultTaxes = props.default_tNm;
    consoleLog('deleteDefMarkup defaultTaxes:', defaultTaxes);
    consoleLog('deleteDefMarkup markup_id:', markup.markup_id);
    defaultTaxes = defaultTaxes.filter((t) => t.markup_id !== markup.markup_id);
    props.set_default_tNm(defaultTaxes);
    consoleLog('deleteDefMarkup defaultTaxes>:', defaultTaxes);

    var markupCopy = [...markup_by_user_id];
    consoleLog(markup, markupCopy);
    markupCopy = markupCopy.filter((m) => m.markup_id !== markup.markup_id);
    setDMarkupByUserId(markupCopy);
    let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    try{
      const del_def_markup = await API.graphql({
        query: deleteMarkupOrTax,
        variables: {
          markup_id: markup.markup_id,
        },
      });
      consoleLog('deleteDefMarkup>>>', del_def_markup);

      const create_tm_redis = await API.graphql({
        query: createOnRedis,
        variables: {
          id: userId,
          index: 'prodocs_taxes_and_markups',
          data: JSON.stringify(defaultTaxes)
        }
      });
      consoleLog('deleteDefTax create_tm_redis:', create_tm_redis);
    }catch(err){
      consoleLog('deleteDefMarkup>>>',err);
    }
  }

  async function addMarkup(category) {
    consoleLog("addMarkup category: ", category);
    category.line_items = [];
    props.onAdd(category);
  }

  const [markupName, setMarkupName] = useState('');
  const [creatingTax, setCreatingTax] = useState(false);

  let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
  let team_id = JSON.parse(secureLocalStorage.getItem("client_data")).team_id;

  async function saveTemplateMarkup() {
    // Add a Default Markup

    consoleLog('saveTemplateMarkup', markupName);

    if(!markupName || markupName==''){
      props.setShow(true);
      props.setShowMessage('Please enter Markup name.');
      props.setShowMessageType('Error');
      return false;
    }

    setCreatingTax(true);
    
    try{
      const markupObj = {
        user_id: client_id,
        team_id: team_id,
        type: "markup",
        name: markupName,
        value: 10,
        for: "all",
        percent: 50.0,
        default: 1,
        status: 1,
      };
      const add_def_tax = await API.graphql({
        query: AddMarkupOrTax,
        variables: markupObj,
      });
      
      consoleLog("saveTemplateMarkup add_def_tax>", add_def_tax.data.AddMarkupOrTax);

      let taxxx = JSON.parse(add_def_tax.data.AddMarkupOrTax);

      consoleLog("saveTemplateMarkup add_def_tax>", taxxx.insertId);

      let tax_id = taxxx.insertId;

      var markupCopy = [...markup_by_user_id];
      consoleLog("saveTemplateMarkup add_def_tax>", markupCopy.length);
      const markup = markupObj;
      markup.markup_id = tax_id;
      markupCopy.push(markup);
      defaultMarkups.push(markup);

      consoleLog("saveTemplateMarkup defaultMarkups>", defaultMarkups);

      setMarkupName('');

      await API.graphql({
        query: createOnRedis,
        variables: {
          id: client_id,
          index: 'prodocs_taxes_and_markups',
          data: JSON.stringify(defaultMarkups)
        }
      });
      
      setDMarkupByUserId(markupCopy);
      consoleLog("object", markupCopy);
      props.setShow(true);
      props.setShowMessage('Markup has been added!');
      props.setShowMessageType('Success');
      setCreatingTax(false);
    }catch(err){
      console.error(err);
      setCreatingTax(false);
    }
  }

  function changeMarkupName(e) {
    // markupName = e.target.value;
    setMarkupName(e.target.value);
  }

  function handleValue(e, i) {
    consoleLog("handleValue e: ", e);
    consoleLog("handleValue i: ", i);

    const decimalPattern = /^-?\d*\.?\d{0,3}$/; // Allows negative numbers and up to 3 decimal places

    // Check if the input matches the pattern (up to 3 decimal places)
    if (!decimalPattern.test(e)) {
      return;
    }

    //replace values in json with input

    let allData = stData;

    consoleLog("original", stData);

    let markupJson = stData.markups;

    Object.values(markupJson).forEach((item) => {
      if (item.markup_id == i) {
        item.value = e;
      }
    });

    consoleLog("markupJson", markupJson);

    allData.markups = markupJson;

    consoleLog("tempered", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);

    // display mark up data on front end

    let allTaxes = Object.entries(allData.markups);

    let taxesArray = [];

    if (allTaxes.length > 0) {
     
      let i;
      let taxesElems = {};
      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "markup" || allTaxes[i][1].type == "op") {
          // we have this bug in the legacy prodocs type must not be 'op'
          // taxesElems.id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddMarkups i: ', taxesElems);
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      consoleLog("AddTaxes array: ", taxesArray);
      addSavedMarkup(taxesArray);
    }
  }

  function handleApplyToAllItems(e, i, markup) {
    consoleLog("handleApplyToAllItems e: ", e);
    consoleLog("handleApplyToAllItems i: ", i);
    consoleLog("handleApplyToAllItems markup: ", markup);

    //replace values in json with input

    let allData = stData;

    consoleLog("original", stData);

    let markupJson = stData.markups;

    Object.values(markupJson).forEach((item) => {
      if (item.markup_id == i) {
        item.for = "all";
        item.type = "markup";
      }
    });

    consoleLog("markupJson", markupJson);

    allData.markups = markupJson;

    consoleLog("tempered", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);

    // display mark up data on front end

    let allTaxes = Object.entries(allData.markups);

    let taxesArray = [];

    if (allTaxes.length > 0) {
      let i;
      let taxesElems = {};
      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "markup" || allTaxes[i][1].type == "op") {
          // we have this bug in the legacy prodocs type must not be 'op'
          // taxesElems.id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddMarkups i: ', taxesElems);
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      consoleLog("AddTaxes array: ", taxesArray);
      addSavedMarkup(taxesArray);
    }
    updateMarkups(markup);
  }

  function handleDefault(e, i) {
    consoleLog("handleDefault e: ", e);
    consoleLog("handleDefault i: ", i);

    //replace values in json with input

    let allData = stData;

    consoleLog("original", stData);

    let markupJson = stData.markups;

    let copy = e;

    let val = 0;
    if (copy == false || copy == "0" || copy == 0) {
      val = 1;
    }

    Object.values(markupJson).forEach((item) => {
      if (item.markup_id == i) {
        item.default = val;
        // consoleLog('matched', item.markup_id);
      }
    });

    consoleLog("markupJson", markupJson);

    allData.markups = markupJson;

    consoleLog("tempered", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    // props.calculateSubTotal(allData); // no need to calculate total

    // display mark up data on front end

    let allTaxes = Object.entries(allData.markups);

    let taxesArray = [];

    if (allTaxes.length > 0) {
     
      let i;
      let taxesElems = {};
      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "markup" || allTaxes[i][1].type == "op") {
          // we have this bug in the legacy prodocs type must not be 'op'
          // taxesElems.id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddMarkups i: ', taxesElems);
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      consoleLog("AddTaxes array: ", taxesArray);
      addSavedMarkup(taxesArray);
    }
  }

  function handleApplyToOnPItems(e, i, markup) {
    consoleLog("handleApplyToOnPItems e: ", e);
    consoleLog("handleApplyToOnPItems i: ", i);
    consoleLog("handleApplyToOnPItems markup: ", markup);

    //replace values in json with input

    let allData = stData;

    consoleLog("original", stData);

    let markupJson = stData.markups;

    Object.values(markupJson).forEach((item) => {
      if (item.markup_id == i) {
        item.for = "all";
        item.type = "op"; // as its op
      }
    });

    consoleLog("markupJson", markupJson);

    allData.markups = markupJson;

    consoleLog("tempered", allData);

    setStData(allData);

    dispatch({ type: "TEMPLATE_STRUCTURE", value: allData });

    props.calculateSubTotal(allData);

    // display mark up data on front end

    let allTaxes = Object.entries(allData.markups);

    let taxesArray = [];

    if (allTaxes.length > 0) {
      
      let i;
      let taxesElems = {};
      for (i = 0; i < allTaxes.length; i++) {
        if (allTaxes[i][1].type == "markup" || allTaxes[i][1].type == "op") {
          // we have this bug in the legacy prodocs type must not be 'op'
          // taxesElems.id = allTaxes[i][1].markup_id;
          taxesElems.name = allTaxes[i][1].name;
          taxesElems.value = allTaxes[i][1].value;
          taxesElems.type = allTaxes[i][1].type;
          // taxesElems.sort_order = allTaxes[i][1].sort_order;
          taxesElems.percent = allTaxes[i][1].percent;
          taxesElems.for = allTaxes[i][1].for;
          taxesElems.markup_id = allTaxes[i][1].markup_id;
          taxesElems.default = allTaxes[i][1].default;
          // consoleLog('AddMarkups i: ', taxesElems);
          taxesArray.push(taxesElems);
          taxesElems = {};
        }
      }
      consoleLog("AddTaxes array: ", taxesArray);
      addSavedMarkup(taxesArray);
    }
    updateMarkups(markup);
  }

  async function updateMarkups(markup){
    if(typeof props.url_params.doc_id != 'undefined'){
      let doc_id = props.url_params.doc_id;
      consoleLog('updateMarkups', markup);

      let allData = stData;
      let allDataEntries = Object.entries(allData.markups);
      consoleLog("updateMarkups allDataEntries>>>", allDataEntries);

      let i;
      let ind;
      for (i = 0; i < allDataEntries.length; i++) {
        if (allDataEntries[i][1].markup_id == markup.markup_id) {
          ind = allDataEntries[i][0];
        }
      }
      consoleLog("updateMarkups ind>>>", ind);

      try{
        const update_markup = await API.graphql({
          query: updateMarkup,
          variables: {
            doc_markup_id: ind,
            type: markup.type,
            value: markup.value,
            for: markup.for,
            percent: markup.percent
          },
        });
        consoleLog("updateMarkups update_markup", update_markup);
        props.updateDocOnElasticSearch();
      }catch(err){
        console.error(err);
      }
    }
  }

  // useEffect(() => {
  //     props.getData(subTotalPercentage, oNpCostTotalPercentage);
  // }, []);

  return (
    <AddStruct>
      
      <Row className="mb-2">
        <div className="col-xs-9 text-left d-flex align-items-center justify-content-between w-100">
          <h4 className="tax_text">
            {" "}
            Add Markup and Discounts{" "}
              <Tooltip
                  overlay={
                    <div
                    style={{
                      width: "250px",
                      padding: "10px",
                      background: "#fff",
                      color: "#000",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    className="d-flex flex-column justify-content-center"
                    >
                      <b>Markups</b><br />
                      Markups will be added to the total before taxes have been applied. To save the value of a markup field for future use, check “Save Default”.
                    </div>
                  }
                  placement="left"
                  >
                  <small
                    style={{ marginLeft: "5px", marginTop: "5px" }}
                  >
                  <FaInfoCircle
                  size={15}
                  style={{ color: "#F7941C" }}
                  />
                </small>
              </Tooltip>
          </h4>
          <button
            className="border-0 px-0 outline-none bg-trans"
            onClick={() => props.hideMarkupOrTaxes('markups')}
          >
            <img
              src={Cancel}
              alt=""
              style={{ height: "auto", cursor: "pointer", width: "26px" }}
            />
          </button>
        </div>
      </Row>

      {savedMarkup.map((markupD, i) => (
        <div className="d-flex  justify-content-center w-100 flex-column">
          <p className="m-0 mt-2">{markupD.name} (%)</p>

          <div className="d-flex align-items-center justify-direction w-100">
            <input
              name={"markup-" + i}
              placeholder="Enter Percentage"
              className="custom-link-input width my-2 w-100"
              value={markupD.value}
              onChange={(e) => handleValue(e.target.value, markupD.markup_id)}
              onBlur={()=>updateMarkups(markupD)}
            />                 
              <div className="save-input w-25 d-flex align-items-center justify-content-start ml-5 test-center">
                <input
                className="mt-0"
                  type="checkbox"
                  checked={markupD.default}
                  onClick={() =>
                    handleDefault(markupD.default, markupD.markup_id)
                  }
                />
                <small
                  style={{
                    color: "#737F82",
                    marginTop:"2px",
                    marginLeft: "5%",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    letterSpacing: "0",
                    lineHeight: "16px",
                  }}
                >
                  Save as default
                </small>
              </div>
            <div className="ex-tax mr-4">
              <img
                src={TransparentTrash}
                style={{ cursor: "pointer" }}
                onClick={() => deleteTempMarkup(markupD)}
                alt=""
              />
            </div>
          </div>
          <Row className="align-items-center justify-content-between w-75 content-section">
            <div className="col-xl-6 col-md-12 d-flex align-items-center justify-content-start test-center my-2">
              <input
              className="mt-0"
                type="radio"
                onClick={() => handleApplyToAllItems("all", markupD.markup_id, markupD)}
                checked={markupD.type == "markup" ? true : false}
              />
              <small
              className="content-block"
                style={{
                  color: "#737F82",
                  marginTop: "3px",
                  marginLeft: "5%",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  letterSpacing: "0",
                  lineHeight: "16px",
                }}
              >
                Apply to all line items
              </small>
            </div>

            <div className="col-xl-6 col d-flex align-items-center justify-content-start test-center my-2">
              <input
                type="radio"
                className="mt-0"
                onClick={() => handleApplyToOnPItems("op", markupD.markup_id, markupD)}
                checked={markupD.type == "op" ? true : false}
              />
              <small
                className="d-flex text-left align-items-center justify-content-center content-block"
                style={{
                  color: "#737F82",
                  marginTop: "3px",
                  marginLeft: "5%",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  letterSpacing: "0",
                  lineHeight: "16px",
                }}
              >
                Apply only to line items that check for O&P
              </small>
            </div>
          </Row>
        </div>
      ))}

      <div className="row buttons structure-buttons">
        <a
          className={`show-item-adder text-left ${
            !addStructureTitle ? "hide" : ""
          }`}
          onClick={showHideMarkupForm}
        >
          <span className="mr-2">
            <FaPlus color={"#F7941C"} size={15} />
          </span>
          <span className="text red-on-hover">Add Additional Markup Field</span>
        </a>

        <Col
          xs={12}
          className={`section structure-adder item-adder col text-left ${
            addStructureTitle ? "hide" : ""
          }`}
        >
          <div className="catBox">
            <p
              className="mt-4"
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                color: "#212E33",
                fontWeight: "500",
                textAlign: "left",
                width: "100%",
                marginBottom: '2px'
              }}
            >
              Select Markup Fields to Add
            </p>

            <div className="catBox_child bg-white">
              {
                (Array.isArray(markup_by_user_id) && markup_by_user_id.length)
                  ? ( markup_by_user_id.map((markup, i) => (
                  <div className="d-flex align-items-center">
                    <button
                      key={i}
                      className="w-100 text-dark custombtnn hover-state bg-white text-left"
                    >
                      <a
                        href="javascript:;"
                        className="hover-opt"
                        onClick={() => addDefaultMarkup(markup)}
                      >
                        {markup.name}
                      </a>
                      <span className="float-right">
                      { addTaxLoader == markup.markup_id ?
                        <FaCircleNotch
                          size={18}
                          className="awesome_spinner"
                          style={{color:'#212e33'}}
                        />
                        :
                        <img
                          src={Trashh}
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteDefMarkup(markup)}
                          alt=""
                        />
                      }
                      </span>
                    </button>
                  </div>
                  )))
                  : <span style={{marginLeft:"5px", paddingBottom: "2px"}}>You don't have default Markups. Add a custom Markup name and click on the + button to add.</span>
              }
            </div>
          </div>
          <div className="create-form form pb-5">
            <input type="hidden" name="type" value="markup" />
            <p
              className="mt-5"
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                color: "#212E33",
                fontWeight: "500",
                marginBottom: '2px'
              }}
            >
              Add a Custom Markup Field
            </p>
            <div className="d-flex align-items-center justify-content-between">
              <input
                onInput={(e) => changeMarkupName(e)}
                type="text"
                className="custom-link-input"
                name="name"
                placeholder="Add Custom Tax Name"
                data-item-adder-focus="true"
                value={markupName}
              />

              <button
                className="search-submit ml-3 small"
                onClick={() => saveTemplateMarkup()}
              >
                {creatingTax ? 
                  <FaCircleNotch
                      size={18}
                      className="awesome_spinner"
                      style={{color:'#212e33'}}
                  />
                :
                  <img src={Plus} alt="" />
                }
              </button>
            </div>
          </div>
        </Col>
      </div>
   
    </AddStruct>
  );
}

const AddStruct = styled.div`
  margin-top: 1%;
  min-height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 20px 45px;

  border-radius: 6px;
  background-color: #eef2f4;
  @media (max-width: 600px) {
    padding: 10px 20px;
  }

  .close{
        right:20px !important;
        top:1rem !important;
    }

    .toast-header{
        padding:0 20px;
    }
    .toast-body{
        padding:0 20px;
    }


  .hover-opt {
    text-decoration: none;
    color: #212e33;
    &:hover {
      color: #f7941c;
    }
  }
  .width {
    width: 98.5%;
  }
  .search-submit {
    height: 40px !important;
    width: 40px !important;
  }

  .content-section {
    @media(max-width: 1024px){
      width: 100% !important;
      margin-top: 5px !important;
    }
  }

  .content-block {
    @media(max-width: 1024px){
      justify-content: start !important;
      margin-left: 3% !important;
      margin-top: 5px !important;
    }
  }
  .custom-link-input {
    height: 40px;
    width: 100%;
    border: 1px solid #b4bebf;
    outline: none;
    border-radius: 4px;
    color: #212e33;
    font-style: normal;
    padding: 10px;
  }

  .width{
    @media(max-width: 1024px) {
      width: 60% !important;
    } 

    @media(max-width: 767px) {
      width: 40% !important;
    } 
  }


  .justify-direction {
    @media(max-width: 767px) {
      justify-content: space-between !important;
    }
  }
  .save-input {
    @media(max-width: 767px){
      margin-left: 0 !important;
      width: auto !important;

      small {
        margin-left: 5px !important;
        font-size: 13px !important;
      }
     }
  }
  .ex-tax {
    height: 40px;
    width: 50px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    background: #d6e1e5;
    border: none;
    outline: none;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
  }

  .trash-button {
    width: 40px;

    @media(max-width: 767px) {
      margin-right: 0 !important;
      height: 40px;
    }
  }
  .custombtnn {
    font-weight: 500 !important;
    font-size: 14px;
    text-decoration: initial;
  }

  .w-15 {
    width: 15%;
  }
  .w-25 {
    width: 25%;
  }
  .top {
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: 600;
    padding: 0.5em;
    vertical-align: top;
  }
  .tax_text {
    color: #212e33;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .popup-tooltip {
    font-size: 0.8em;
    text-transform: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.2em;
  }
  .custom_span {
    color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -25px;
    z-index: 9;
    border-radius: 5px;
  }
  .sec_row {
    input[type="checkbox"] {
      border: 1px solid #000;
      border-radius: 50%;
    }
  }

  .catBox_child {
    width: 98.5%;
    border: 1px solid #b4bebf;
    border-radius: 4px;
    padding: 5px 0px 0px !important;
    outline: none;
    button {
      border-bottom: 1px solid #c2c2c2;

     
    }
  }

  .bg-white {
    padding: 10px;
  }
  .buttons {
    padding: 0;
  }

  .row {
    position: relative;
    display: block;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    float: none;
  }
  .left {
    float: left;
  }
  .add-structure {
    background: #414141;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    padding: 0.6em 1.8em 0.6em 1.2em;
  }
  .show-item-adder {
    display: block;
    cursor: pointer;
    padding: 0.5em;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  a {
    color: #414141;
    text-decoration: none;
    outline: 0;
  }
  .color-white {
    color: white;
  }
  .add-structure .text {
    color: white;
    font-size: 1em;
  }
  .buttons .show-item-adder .text {
    font-size: 1.2em;
    text-decoration: underline;
    padding-left: 0.5em;
  }
  .buttons .show-item-adder {
    display: block;
    cursor: pointer;
    padding: 0.5em;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .item-adder.hide {
    min-height: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none !important;
    display: none;
  }
  .item-adder {
    overflow: hidden;
    -webkit-animation: height-show 0.5s forwards;
    animation: height-show 0.5s forwards;
    
    position: relative;
  }
  .item-adder .close {
    position: absolute;
    top: 1em;
    right: 2em;
    cursor: pointer;
    z-index: 5;
  }
  .item-adder h2 {
    font-size: 1.1em;
    text-transform: uppercase;
    margin: 0;
  }
  .col {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    position: relative;
    min-height: 1px;
    line-height: 1em;
    width: 100%;
    float: none;
  }
  .col:last-child {
    margin-right: 0 !important;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
  h2 {
    font: 1.5em "Open Sans", Helvetica, sans-serif;
  }
  div,
  span,
  form {
    border: 0;
    font-size: 100%;
    font: inherit;
    line-height: 1.4em;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
  }
  .item-adder .create-form {
    margin-top: 1em;
  }

  button,
  .button,
  .row .button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: #f7941c;
    border: 1px solid #08527d;
    border-radius: 3px;
    padding: 0.8em 1em;
  }
  button,
  .button,
  .row .button {
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    background: #d6e1e5;
    border: none;
    outline: none;
    border-radius: 3px;
    padding: 0.6em 0.8em;
    box-sizing: border-box;
  }
  button.small,
  .button.small,
  .row .button.small {
    font-size: 0.8em;
    padding: 0.5em 1.2em;
  }
  .item-adder .create-form button {
    padding: 0.7em 1em;
  }
`;
