import React,{useState, useEffect , useRef} from 'react'
import styled from 'styled-components';
import {Accordion, Card, Button, Table, Row, Col} from 'react-bootstrap';
import Select,{components} from 'react-select';
import {Link} from 'gatsby';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import EdiText from 'react-editext';
import { FaPlus, FaPencilAlt, FaWindowClose, FaCheck, FaSearch, FaGripVertical, FaTrashAlt, FaUpload, FaFolder, FaCaretDown, FaCircleNotch } from "react-icons/fa";
import Check from "../../assets/checkmark1.svg";
import Remove from "../../assets/remove.svg";
import PEdit from '../../assets/p-edit.svg';
import Cross from "../../assets/cross.svg";
import searchImg from '../../assets/search.svg';
import { updateContactInfox, updateContactAddress, deleteContact, deleteAddress, createContactInfo, createContactAddress, updateDocContactId, updateTempDescription, getDefaultTemplates, getDefaultCategories,getCategoryByUserID,getDocumentByID,updateDocumentLabel,updateInvoiceLabel,updateDocumentOptions,getTemplate, updateDocOptions, getFiles, uploadFile, deleteFile, getSearchedContactsByClientID} from '../../graphql/queries';
import { useSelector, useDispatch } from "react-redux";
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as queryString from "query-string";
import Cancel from '../../assets/cancel_line.svg';
import makeAnimated from 'react-select/animated';
import { FileDrop } from 'react-file-drop';
import ImageUploading from 'react-images-uploading';
import Loader from "./../../assets/loading.gif";
import GeneralPopup from '../Modals/GeneralPopup';
import  secureLocalStorage  from  "react-secure-storage";
const animatedComponents = makeAnimated();
const CaretDownIcon = () => {
    return <FaCaretDown color={'#000'} />;
  };
  
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
export default function DocSettings(props) {
    const {consoleLog} = require('../commonFunctions.js');
    var dc_id = '';
    consoleLog("DocSettings props: ", props);
    if(typeof props.url_params.doc_id != 'undefined'){
        dc_id = props.url_params.doc_id;
    }
    const [docID, setDocID] = React.useState(dc_id);
    const [tempID, setTempID] = React.useState("");
    consoleLog('dc_id', dc_id);
    consoleLog('middlestart',secureLocalStorage.getItem('client_data'));

    const [templateInfo, setDocType] = React.useState(useSelector((state) => state.templateInfo));
    const [stData, setStData] = React.useState(useSelector((state) => state.templateStructure));
    const [showDocLabel, setShowDocLabel] = React.useState(true);
    const [showTemplateLabel, setShowTemplateLabel] = React.useState(true);
    const [showInvoiceLabel, setShowInvoiceLabel] = React.useState(true);
    const [showContactLabel, setShowContactLabel] = React.useState(true);
    const [docLabel, setDocLabel] = React.useState("");
    const [templateDescription, setTemplateDescription] = React.useState("");
    const [invoiceLabel, setInvoiceLabel] = React.useState("");
    const [showAddNewContactLabel, setShowAddNewContactLabel] = React.useState(false);
    const fileInputRef = useRef(null);
    const [docOptions, setDocOptions] = React.useState([]);
    
    const [templateJson,setTemplateJson] = React.useState({});
    const [templateData,setTemplateData] = React.useState({});
    const [contactId,setContactId] = React.useState(stData.contact_id);

    const [loadingMoreContacts, setLoadingMoreContacts] = React.useState(false);

    const [isUpdate,setIsUpdate] = React.useState(false);
    const [defContactFirstName , setdefContactFirstName] = useState("")
    const [defContactLastName , setdefContactLastName] = useState("")
    const [defContactEmail , setdefContactEmail] = useState("")
    const [defContactPhoneNo , setdefContactPhoneNo] = useState("")
    const [companyName , setCompanyName] = useState("")

    const [color, setColor] = useState('#da8227');
    const [logo, setLogo] = useState('//roofscope.com/app/view/theme/polyscope/image/logo.png#file_id=8');

    useEffect(() => {
        if(JSON.parse(secureLocalStorage.getItem("client_data")).logo){
            setLogo(JSON.parse(secureLocalStorage.getItem("client_data")).logo);
        }
        if(JSON.parse(secureLocalStorage.getItem("client_data")).color){
            setColor(JSON.parse(secureLocalStorage.getItem("client_data")).color);
        }
        if(templateInfo.doc_type_id == 1) {
            setdefContactFirstName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_first_name)
            setdefContactLastName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_last_name)
            setdefContactEmail(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_email)
            setdefContactPhoneNo(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_estimate_contact_phone)
            setCompanyName(JSON.parse(secureLocalStorage.getItem("client_data")).company)
        } 
        else {
            setdefContactFirstName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_work_order_contact_first_name)
            setdefContactLastName(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_work_order_contact_last_name)
            setdefContactEmail(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_work_order_contact_email)
            setdefContactPhoneNo(JSON.parse(secureLocalStorage.getItem("client_data")).prodocs_work_order_contact_phone)
            setCompanyName(JSON.parse(secureLocalStorage.getItem("client_data")).company)
        }
    },[])

    const dispatch = useDispatch();

    const getDateTime = () => {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
    
        let date = [year, month, day].join('-');
    
        let hour = d.getHours();
        let minutes = d.getMinutes();
    
        if (hour.length < 2)
            hour = '0' + hour;
        if (minutes.length < 2)
            minutes = '0' + minutes;
    
        let time = [hour, minutes].join(':');
    
        return date+' '+time+':00'; 
    }

    async function handleDisplayStructurePrice(){
        if(typeof props.url_params.doc_id != 'undefined'){
            consoleLog('handleDisplayStructurePrice, its a doc!', docOptions);
            let num = 1 - props.displayStructurePrice;
            consoleLog('handleDisplayStructurePrice num:', num);
            let newDocOptions = {...docOptions};
            newDocOptions.show_structure_price = num;
            consoleLog('handleDisplayStructurePrice newDocOptions:', newDocOptions);
            setDocOptions(newDocOptions);
            props.setDisplayStructurePrice(num);
            handleDocOptionsUpdate(newDocOptions);
        }else{
            let newJson={...templateJson};
            consoleLog("handleDisplayStructurePrice newJson:", newJson);
            let newDocOptions = {...docOptions};
            let num= 1 - props.displayStructurePrice;
            consoleLog("handleDisplayStructurePrice num:", num);
            newDocOptions.show_structure_price = num;
            consoleLog("handleDisplayStructurePrice newDocOptions:", newDocOptions);
            
            newJson.options = newDocOptions;
            setDocOptions(newDocOptions);
            setTemplateJson(newJson);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newJson });
            props.setDisplayStructurePrice(num);
        }
    }

    async function handleDisplayCategories() {
        if(typeof props.url_params.doc_id != 'undefined'){
            consoleLog('handleDisplayCategories, its a doc!', docOptions);
            let num = 1 - props.displayCategories;
            consoleLog('handleDisplayCategories, num:', num);
            let newDocOptions = {...docOptions};
            newDocOptions.show_categories = num;
            consoleLog('handleDisplayCategories, newDocOptions:', newDocOptions);
            setDocOptions(newDocOptions)
            props.setDisplayCategories(num);
            handleDocOptionsUpdate(newDocOptions);
        }else{
            let newJson={...templateJson};
            consoleLog("handleDisplayCategories newJson:", newJson);
            let newDocOptions = {...docOptions};
            let num= 1 - props.displayCategories;
            consoleLog("handleDisplayCategories num:", num);
            newDocOptions.show_categories = num;
            consoleLog("handleDisplayCategories newDocOptions:", newDocOptions);
            
            newJson.options = newDocOptions;
            setDocOptions(newDocOptions);
            setTemplateJson(newJson);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newJson });
            props.setDisplayCategories(num);
        }
    }

    async function handleDisplayCategoryPrice() {
        if(typeof props.url_params.doc_id != 'undefined'){
            consoleLog('handleDisplayCategoryPrice, its a doc!',docOptions);
            let num = 1 - props.displayCategoryPrice;
            consoleLog('handleDisplayCategoryPrice, num:',num);
            let newDocOptions = {...docOptions};
            newDocOptions.show_category_price = num;
            consoleLog('handleDisplayCategoryPrice, newDocOptions:',newDocOptions);
            setDocOptions(newDocOptions);
            props.setDisplayCategoryPrice(num);
            handleDocOptionsUpdate(newDocOptions);

        }else{
            let newJson={...templateJson};
            consoleLog("handleDisplayCategoryPrice newJson:", newJson);
            let newDocOptions = {...docOptions};
            let num= 1 - props.displayCategoryPrice;
            consoleLog("handleDisplayCategoryPrice num:", num);
            newDocOptions.show_category_price = num;
            consoleLog("handleDisplayCategoryPrice newDocOptions:", newDocOptions);

            newJson.options = newDocOptions;
            setDocOptions(newDocOptions);
            setTemplateJson(newJson);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newJson });
            props.setDisplayCategoryPrice(num);
        }
    }

    async function getDocDetailx(){
        let customerId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
        let customerIds = [6348, customerId];
        getFileList(customerIds.join(','));
        consoleLog("getDocDetailx, stData: ", stData);
        if(docID != ""){
            if(typeof stData.team_id != 'undefined' && typeof stData.user_id != 'undefined'){
                let options = JSON.parse(stData.options);
                consoleLog("getDocDetailx, options: ", options);
                if(!options || typeof options != 'object' || options == 'null' || options == null || options == ''){
                    consoleLog('getDocDetailx ', 'null');
                    options = {"logo":"","color":"","show_structures":1,"show_structure_price":0,"show_categories":1,"show_category_price":0};
                }
                setDocOptions(options);
                consoleLog("getDocDetailx, options: ", options);
                props.setDisplayStructurePrice(options.show_structure_price);
                consoleLog("getDocDetailx, options.show_structure_price: ", options.show_structure_price);
                props.setDisplayCategories(options.show_categories);
                consoleLog("getDocDetailx, options.show_categories: ", options.show_categories);
                props.setDisplayCategoryPrice(options.show_category_price);
                consoleLog("getDocDetailx, options.show_category_price: ", options.show_category_price);
                if(options.color){
                    setColor(options.color);
                }
                consoleLog("getDocDetailx, options.color: ", options.color);
                setDocLabel(stData.label);
                consoleLog("getDocDetailx, label: ", stData.label);
                setInvoiceLabel(stData.invoice_label);
                consoleLog("getDocDetailx, invoice_label: ", stData.invoice_label);
                setContactId(stData.contact_id);
                consoleLog("getDocDetailx, contact_id: ", stData.contact_id);
                consoleLog("getDocDetailx, contact_info: ", stData.contact_info);
                if(options.logo){
                    setLogo(options.logo);
                }
                consoleLog("getDocDetailx, logo: ", options.logo);
            }else{
                const get_doc_details = await API.graphql({
                    query: getDocumentByID,
                    variables: {
                        doc_id: docID
                    }
                })
                consoleLog("getDocDetailx result:> ", JSON.parse(get_doc_details.data.getDocumentByID.options));
                let options = JSON.parse(get_doc_details.data.getDocumentByID.options);
                consoleLog("getDocDetailx result:> ", options);
                if(!options || typeof options != 'object' || options == 'null' || options == null || options == ''){
                    consoleLog('getDocDetailx', 'null');
                    options = {"logo":"","color":"","show_structures":1,"show_structure_price":0,"show_categories":1,"show_category_price":0};
                }
                setDocOptions(options);
                props.setDisplayStructurePrice(options.show_structure_price);
                props.setDisplayCategories(options.show_categories);
                props.setDisplayCategoryPrice(options.show_category_price);
                if(options.color){
                    setColor(options.color);
                }
                if(options.logo){
                    setLogo(options.logo);
                }
                setDocLabel(get_doc_details.data.getDocumentByID.label);
                setInvoiceLabel(get_doc_details.data.getDocumentByID.invoice_label);
                setContactId(get_doc_details.data.getDocumentByID.contact_id);
            }
        }
        else{
            consoleLog('xxxxx s>',stData);
            consoleLog('xxxxx t>',templateInfo);
            if(typeof stData.team_id != 'undefined' && typeof stData.user_id != 'undefined'){
                setTemplateJson(stData);
                consoleLog('uuu setTemplateJson>', stData);

                let options;
                if(typeof stData.options != 'undefined'){
                    options = stData.options;
                    if(!options || typeof options != 'object' || options == 'null' || options == null || options == ''){
                        consoleLog('getDocDetailx', 'null');
                        options = {"logo":"","color":"","show_structures":1,"show_structure_price":0,"show_categories":1,"show_category_price":0};   
                    }
                }else{
                    options = {"logo":"","color":"","show_structures":1,"show_structure_price":0,"show_categories":1,"show_category_price":0};
                }
                consoleLog("uuu options: ", options);

                setDocOptions(options);
                consoleLog('uuu options>', options);
                props.setDisplayStructurePrice(options.show_structure_price);
                consoleLog('uuu options.show_structure_price>', options.show_structure_price);
                props.setDisplayCategories(options.show_categories);
                consoleLog('uuu options.show_categories>', options.show_categories);
                props.setDisplayCategoryPrice(options.show_category_price);
                consoleLog('uuu options.show_category_price>', options.show_category_price);
                if(options.color){
                    setColor(options.color);
                }
                consoleLog('uuu options.color>', options.color);
                if(options.logo){
                    setLogo(options.logo);
                }
                consoleLog('uuu options.logo>', options.logo);

                setDocLabel(stData.label);
                consoleLog('uuu label>', stData.label);
                setInvoiceLabel(stData.invoice_label);
                consoleLog('uuu invoice_label>', stData.invoice_label);
                setTemplateDescription(stData.description);
                consoleLog('uuu description>', stData.description);
                setContactId(stData.contact_id);
                consoleLog('uuu contact_id>', stData.contact_id);
                consoleLog('uuu contact_info>', stData.contact_info);
            }else{
                const get_doc_details = await API.graphql({
                    query: getTemplate,
                    variables: {
                        template_id: props.url_params.template_id
                    }
                });
                
                consoleLog(" middle2 docOptions result: temp> ", JSON.parse(get_doc_details.data.getTemplate.data));
            
                setTemplateJson(JSON.parse(get_doc_details.data.getTemplate.data));
                setDocOptions(JSON.parse(get_doc_details.data.getTemplate.data).options);
                props.setDisplayStructurePrice(JSON.parse(get_doc_details.data.getTemplate.data).options.show_structure_price);
                consoleLog('nwwwwww',JSON.parse(get_doc_details.data.getTemplate.data));
                props.setDisplayCategories(JSON.parse(get_doc_details.data.getTemplate.data).options.show_categories);
                props.setDisplayCategoryPrice(JSON.parse(get_doc_details.data.getTemplate.data).options.show_category_price);
                if(JSON.parse(get_doc_details.data.getTemplate.data).options.color){    
                    setColor(JSON.parse(get_doc_details.data.getTemplate.data).options.color);
                }
                if(JSON.parse(get_doc_details.data.getTemplate.data).options.logo){
                    setLogo(JSON.parse(get_doc_details.data.getTemplate.data).options.logo);
                }
                setDocLabel(JSON.parse(get_doc_details.data.getTemplate.data).label);
                setInvoiceLabel(JSON.parse(get_doc_details.data.getTemplate.data).invoice_label)
                setTemplateDescription(get_doc_details.data.getTemplate.description);
                setContactId(JSON.parse(get_doc_details.data.getTemplate.data).contact_id);
                consoleLog('middleofsomething',get_doc_details.data.getTemplate)
            }
        }
    }

    async function loadMoreContacts(){
        setLoadingMoreContacts(true);
        let newLimit = contactsLimit + parseInt(process.env.GATSBY_LIST_SIZE_LOAD_MORE ?? 10);
        setContactsLimit(newLimit);
        await getSearchedContacts('', newLimit);
        setLoadingMoreContacts(false);
    }

    consoleLog("middle2 docOptions result: yyy> ");
    // getDocDetailx();

    async function handleDocOptionsUpdate(ops){
        let data = JSON.stringify(ops);
        consoleLog("handleDocOptionsUpdate ops>", ops);

        let stDataCopy = stData;
        stDataCopy.options = data;
        consoleLog("handleDocOptionsUpdate stDataCopy>", stDataCopy);
        consoleLog("handleDocOptionsUpdate stData>", stData);

        setStData(stDataCopy);

        const update_doc_options = await API.graphql({
            query: updateDocOptions,
            variables: {
                doc_id: docID,
                options: data
            }
        });
        consoleLog("handleDocOptionsUpdate", update_doc_options);
        
        dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });
        props.updateDocOnElasticSearch();

    }

    useEffect(() => {
        getDocDetailx();
        getSearchedContacts();
    }, [])

    const [contacts, setContacts] = React.useState([]);
    const [selectedContact, setSelectedContact] = React.useState({});
    const [totalContacts, setTotalContacts] = React.useState(0);
    const [contactsLimit, setContactsLimit] = React.useState(parseInt(process.env.GATSBY_DEFAULT_LIST_SIZE ?? 10));

    const COUNTRIES = [
        { "label" : "Select Country", "value" : "" },
        { "label" : "Canada", "value" : "38" },
        { "label" : "United States", "value" : "223" }
    ];

    const A_STATES = [
        { "label" : "Select State", "value" : "" },
        { "label" : "Alabama", "value":"3613"},
        { "label" : "Alaska", "value":"3614"},
        { "label" : "American Samoa", "value":"3615"},
        { "label" : "Arizona", "value":"3616"},
        { "label" : "Arkansas", "value":"3617"},
        { "label" : "Armed Forces Africa", "value":"3618"},
        { "label" : "Armed Forces Americas", "value":"3619"},
        { "label" : "Armed Forces Canada", "value":"3620"},
        { "label" : "Armed Forces Europe", "value":"3621"},
        { "label" : "Armed Forces Middle East", "value":"3622"},
        { "label" : "Armed Forces Pacific", "value":"3623"},
        { "label" : "California", "value":"3624"},
        { "label" : "Colorado", "value":"3625"},
        { "label" : "Connecticut", "value":"3626"},
        { "label" : "Delaware", "value":"3627"},
        { "label" : "District of Columbia", "value":"3628"},
        { "label" : "Federated States Of Micronesia", "value":"3629"},
        { "label" : "Florida", "value":"3630"},
        { "label" : "Georgia", "value":"3631"},
        { "label" : "Guam", "value":"3632"},
        { "label" : "Hawaii", "value":"3633"},
        { "label" : "Idaho", "value":"3634"},
        { "label" : "Illinois", "value":"3635"},
        { "label" : "Indiana", "value":"3636"},
        { "label" : "Iowa", "value":"3637"},
        { "label" : "Kansas", "value":"3638"},
        { "label" : "Kentucky", "value":"3639"},
        { "label" : "Louisiana", "value":"3640"},
        { "label" : "Maine", "value":"3641"},
        { "label" : "Marshall Islands", "value":"3642"},
        { "label" : "Maryland", "value":"3643"},
        { "label" : "Massachusetts", "value":"3644"},
        { "label" : "Michigan", "value":"3645"},
        { "label" : "Minnesota", "value":"3646"},
        { "label" : "Mississippi", "value":"3647"},
        { "label" : "Missouri", "value":"3648"},
        { "label" : "Montana", "value":"3649"},
        { "label" : "Nebraska", "value":"3650"},
        { "label" : "Nevada", "value":"3651"},
        { "label" : "New Hampshire", "value":"3652"},
        { "label" : "New Jersey", "value":"3653"},
        { "label" : "New Mexico", "value":"3654"},
        { "label" : "New York", "value":"3655"},
        { "label" : "North Carolina", "value":"3656"},
        { "label" : "North Dakota", "value":"3657"},
        { "label" : "Northern Mariana Islands", "value":"3658"},
        { "label" : "Ohio", "value":"3659"},
        { "label" : "Oklahoma", "value":"3660"},
        { "label" : "Oregon", "value":"3661"},
        { "label" : "Palau", "value":"3662"},
        { "label" : "Pennsylvania", "value":"3663"},
        { "label" : "Puerto Rico", "value":"3664"},
        { "label" : "Rhode Island", "value":"3665"},
        { "label" : "South Carolina", "value":"3666"},
        { "label" : "South Dakota", "value":"3667"},
        { "label" : "Tennessee", "value":"3668"},
        { "label" : "Texas", "value":"3669"},
        { "label" : "Utah", "value":"3670"},
        { "label" : "Vermont", "value":"3671"},
        { "label" : "Virgin Islands", "value":"3672"},
        { "label" : "Virginia", "value":"3673"},
        { "label" : "Washington", "value":"3674"},
        { "label" : "West Virginia", "value":"3675"},
        { "label" : "Wisconsin", "value":"3676"},
        { "label" : "Wyoming", "value":"3677"}
    ];

    const C_STATES = [
        { "label" : "Select State", "value" : "" },
        { "label" : "Alberta", "value" : "602" },
        { "label" : "British Columbia", "value" : "603" },
        { "label" : "Manitoba", "value" : "604" },
        { "label" : "New Brunswick", "value" : "605" },
        { "label" : "Newfoundland and Labrador", "value" : "606" },
        { "label" : "Northwest Territories", "value" : "607" },
        { "label" : "Nova Scotia", "value" : "608" },
        { "label" : "Nunavut", "value" : "609" },
        { "label" : "Ontario", "value" : "610" },
        { "label" : "Prince Edward Island", "value" : "611" },
        { "label" : "Québec", "value" : "612" },
        { "label" : "Saskatchewan", "value" : "613" },
        { "label" : "Yukon Territory", "value" : "614" }
    ];

    function handleOnChangeDocLabel(e){
        consoleLog("handleOnChangeDocLabel e.target: ", e.target.value);
        setDocLabel(e.target.value);
    }
    
    async function updateDocLabel(){
        if(typeof props.url_params.doc_id != 'undefined'){
            consoleLog('updateDocLabel',' its a doc!');
            setShowDocLabel(true);
            const update_doc_label = await API.graphql({
                query: updateDocumentLabel,
                variables: {
                    doc_id: docID,
                    label:docLabel
                }
            });
            let stDataCopy = stData;
            stDataCopy.label = docLabel;
            consoleLog("updateDocLabel stDataCopy>", stDataCopy);

            setStData(stDataCopy);

            dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });
            props.updateDocOnElasticSearch();
        }
        else{
            setShowDocLabel(true);
            let newJson={...templateJson};
            consoleLog('updateDocLabel template newJson>', newJson);

            newJson.label = docLabel;
            consoleLog('updateDocLabel>>>', newJson);
            setTemplateJson(newJson);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newJson });
        }
    }

    function handleOnChangeTemplateDescription(e){
        consoleLog("handleOnChangeTemplateDescription e.target: ", e.target.value);
        setTemplateDescription(e.target.value);
    }

    async function updateTemplateDescription(){
        if(typeof props.url_params.doc_id != 'undefined'){
            consoleLog('updateTemplateDescription','its a doc!');
            setShowTemplateLabel(true); //Its only in template so no need here
        }else{
            setShowTemplateLabel(true);
            consoleLog('updateTemplateDescription','its a template!');
            const save_template_desc = await API.graphql({
                query: updateTempDescription,
                variables: {
                    template_id: props.url_params.template_id,
                    description: templateDescription
                }
            });
            consoleLog('updateTemplateDescription>', save_template_desc);
            let newJson={...templateJson};
            consoleLog('updateTemplateDescription>>', newJson);
            newJson.description = templateDescription;
            consoleLog('updateTemplateDescription>>>', newJson);
            setTemplateJson(newJson);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newJson });
        }
    }

    function handleOnChangeInvoiceLabel(e){
        consoleLog("handleOnChangeInvoiceLabel e.target: ", e.target.value);
        setInvoiceLabel(e.target.value);
    }

    async function updateInvLabel(){
        if(typeof props.url_params.doc_id != 'undefined'){
            consoleLog('updateInvLabel', 'its a doc!');
            setShowInvoiceLabel(true);
            const update_inv_label = await API.graphql({
                query: updateInvoiceLabel,
                variables: {
                    doc_id: docID,
                    label:invoiceLabel
                }
            });
            let stDataCopy = stData;
            stDataCopy.invoice_label = invoiceLabel;
            consoleLog("updateInvLabel stDataCopy>", stDataCopy);

            setStData(stDataCopy);

            dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });
            props.updateDocOnElasticSearch();
        }
        else{
            setShowInvoiceLabel(true);
            let newJson={...templateJson};
            consoleLog('updateInvLabel template newJson>', newJson);

            newJson.invoice_label = invoiceLabel;
            consoleLog('updateInvLabel>>>', newJson);
            setTemplateJson(newJson);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newJson });
        }
    }

    async function updateContactId(id, contact){
        if(typeof props.url_params.doc_id != 'undefined'){
            consoleLog('updateContactId, its a doc!', id);
            consoleLog('updateContactId, its a doc!', contact);
            setContactId(id);
            setSelectedContact(contact);
            const update_con_id = await API.graphql({
                query: updateDocContactId,
                variables: {
                    doc_id: docID,
                    contact_id:id
                }
            });
            consoleLog('updateContactId>', update_con_id);

            let stDataCopy = stData;
            stDataCopy.contact_id = id;
            stDataCopy.contact_info = JSON.stringify(contact);
            consoleLog("updateContactId stDataCopy>", stDataCopy);

            setStData(stDataCopy);

            dispatch({ type: "TEMPLATE_STRUCTURE", value: stDataCopy });
            props.updateDocOnElasticSearch();
        }
        else{
            let newJson={...templateJson};
            consoleLog('updateContactId', 'its a template!');
            setContactId(id);
            setSelectedContact(contact);
            newJson.contact_id=id;
            let data_to_save = JSON.stringify(newJson);
            setTemplateJson(newJson);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newJson });
            consoleLog('updateContactId, its a template!', data_to_save);
        }
    }

    const [addressIdToUpdate, setAddressIdToUpdate] = useState(0);
    const [contactIdToUpdate, setContactIdToUpdate] = useState(0);

    const EditContactInfo = (id) => {
        setIsUpdate(true);
        consoleLog("EditContactInfo: ", id);
        let contacts_copy = [...contacts];
        contacts_copy = contacts_copy.filter((t) => t.address_id == id);
        consoleLog("EditContactInfo: ", contacts_copy);
        consoleLog("EditContactInfo: ", contacts_copy[0].company);

        if(contacts_copy[0].country_id == 223){
            setContactStates(A_STATES);
        }else{
            setContactStates(C_STATES);
        }
        setAddressIdToUpdate(contacts_copy[0].address_id);
        setContactIdToUpdate(contacts_copy[0].contact_id);
        setcontactCompany_name(contacts_copy[0].company);
        setcontactFirst_name(contacts_copy[0].first_name);
        setcontactLast_name(contacts_copy[0].last_name);
        setcontactEmail(contacts_copy[0].email);
        setcontactPhone(contacts_copy[0].phone);
        setcontactAddress(contacts_copy[0].address);
        setcontactBox(contacts_copy[0].address_2);
        setcontactCity(contacts_copy[0].city);
        setcontactCode(contacts_copy[0].postcode);
        setContactCountry(contacts_copy[0].country_id);
        setContactState(contacts_copy[0].zone_id);

        setShowAddNewContactLabel(false);

    }

    async function removeContactInfo(contact) {
        let cid = contact.contact_id;
        let aid = contact.address_id;
        consoleLog("removeContactInfo: cid", cid);
        consoleLog("removeContactInfo: aid", aid);

        let contacts_copy = [...contacts];
        contacts_copy = contacts_copy.filter((t) => t.address_id !== aid);
        setContacts(contacts_copy);

        const delete_address = await API.graphql({
            query: deleteAddress,
            variables: {
                address_id: aid
            }
        });

        consoleLog('removeContactInfo delete_address', delete_address);

        const delete_contact = await API.graphql({
            query: deleteContact,
            variables: {
                contact_id: cid
            }
        });
        if(cid == contactId){
            updateContactId(0,{});
        }
        consoleLog('removeContactInfo delete_contact', delete_contact);
    }

    function renderContactSection(){
        consoleLog("renderContactSection", "()")
        showContactLabel ? setShowContactLabel(false) : setShowContactLabel(true);
        (contacts.length > 0) ? setShowAddNewContactLabel(true) : setShowAddNewContactLabel(false);
    }

    function setColorOptions(color){
        if(typeof props.url_params.doc_id != 'undefined'){
            consoleLog('setColorOptions, its a doc!', docOptions);
            consoleLog('setColorOptions, color:', color);
            let newDocOptions = {...docOptions};
            newDocOptions.color = color;
            consoleLog('setColorOptions, newDocOptions:', newDocOptions);
            setDocOptions(newDocOptions);
            setColor(color);
            handleDocOptionsUpdate(newDocOptions);
        }
        else{
            let newJson={...templateJson};
            let newDocOptions = {...docOptions};
            newDocOptions.color = color;
            newJson.options=newDocOptions;
            consoleLog("displayLabel", newJson);
            setDocOptions(newDocOptions);
            setTemplateJson(newJson);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newJson });
            setColor(color);
        }
    }

    function setLogoOptions(logo){
        if(typeof props.url_params.doc_id != 'undefined'){
            consoleLog('setLogoOptions, its a doc!', docOptions);
            consoleLog('setLogoOptions, logo:', logo);
            let newDocOptions = {...docOptions};
            newDocOptions.logo = logo;
            consoleLog('setLogoOptions, newDocOptions:', newDocOptions);
            setDocOptions(newDocOptions);
            setLogo(logo);
            handleDocOptionsUpdate(newDocOptions);
        }
        else{
            let newJson={...templateJson};
            let newDocOptions = {...docOptions};
            newDocOptions.logo = logo;
            newJson.options = newDocOptions;
            consoleLog("displayLabel", newJson);
            setDocOptions(newDocOptions);
            setTemplateJson(newJson);
            dispatch({ type: "TEMPLATE_STRUCTURE", value: newJson });
            setLogo(logo);
        }
    }

    async function updateOpt(){
        let updatedOptions={...docOptions};
        updatedOptions=JSON.stringify(updatedOptions).replace(/"/g, '\\"');
        const update_doc_opt = await API.graphql({
            query: updateDocumentOptions,
            variables: {
                doc_id: docID,
                options:updatedOptions
            }
        })
    }

    let customerId = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
    const [showAU, setShowAU] = useState(false);
    const [imageManger, setImageManger] = React.useState(false);

    const [folderId, setFolderId] = React.useState(() => {
        const { template_id = '0', doc_id = '0' } = props.url_params;
        // Concatenate template_id and doc_id as strings
        const folder = `${template_id}${doc_id}`;
        console.log("folder>", folder);
        return folder; // Return the concatenated string
    });      
      
    const [images, setImages] = React.useState([]);
    const [imageIndex, setImageIndex] = React.useState(0);
    const [deleteImageIndex, setDeleteImageIndex] = React.useState([]);
    const [deleteImageInfo, setDeleteImageInfo] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showDeleteModal, setShowDeleteModal] =  React.useState(false);
    const maxNumber = 100;

    const handleImageUpload = (imageList, addUpdateIndex) => {
        fileManager(imageList, addUpdateIndex);
    };

    const fileManager = async (imageList, addUpdateIndex) => {
        if(typeof addUpdateIndex !== 'undefined') {

            setImages(imageList);
            setUploading(true);
            
            for (const index of addUpdateIndex) {
                const image = imageList[index];
                const file = {};
                file.name = image.file.name;
                file.size = image.file.size;
                file.type = image.file.type;
                file.data_url = image.data_url;

                const response = await uploadImage(folderId, customerId, file);
                if(response.statusCode === 200) {
                    imageList[index] = response.message;

                    setImageIndex(index);
                    setImages(imageList);
                }else {
                    setErrorMessage(response.message);
                }
            }
            setUploading(false);
        }else {
            setShowDeleteModal(true);
            let fileIds = imageList.map(list => list.file_id);
            let image = images.filter(function(image) { return !fileIds.includes(image.file_id) });
            let fileInfo = image && image[0];

            setDeleteImageInfo(fileInfo);
        } 
    };

    const updateFolder = async (folderId, defaultFolder) => {
        setFolderId(folderId);
        setShowAU(defaultFolder);
    }

    const [contactCompany_name, setcontactCompany_name] = useState("");
    const [contactFirst_name, setcontactFirst_name] = useState("");
    const [contactLast_name, setcontactLast_name] = useState("");
    const [contactEmail, setcontactEmail] = useState("");
    const [contactPhone, setcontactPhone] = useState("");
    const [contactAddress, setcontactAddress] = useState("");
    const [contactBox, setcontactBox] = useState("");
    const [contactCity, setcontactCity] = useState("");
    const [contactCode, setcontactCode] = useState("");
    const [contactCountry, setContactCountry] = useState(0);
    const [contactState, setContactState] = useState(0);
    const [contactStates, setContactStates] = useState(A_STATES);
    const [contactSearch, setContactSearch] = useState("");

    const contactCompany_name__ref = useRef(0);
    const contactFirst_name__ref = useRef(0);
    const contactLast_name__ref = useRef(0);
    const contactEmail__ref = useRef(0);
    const contactPhone__ref = useRef(0);
    const contactAddress__ref = useRef(0);
    const contactBox__ref = useRef(0);
    const contactCity__ref = useRef(0);
    const contactCountry__ref = useRef(0);
    const contactState__ref = useRef(0);
    const contactCode__ref = useRef(0);


    const handleCompanyNameContact = ({ target }) => {
        setcontactCompany_name(target.value)
    }
    const handleFirstNameContact = ({ target }) => {
        setcontactFirst_name(target.value)
    }
    const handleLastNameContact = ({ target }) => {
        setcontactLast_name(target.value)
    }
    
    const handleEmailContact = ({ target }) => {
        setcontactEmail(target.value)
    }
    const handlePhoneContact = ({ target }) => {
        setcontactPhone(target.value)
    }
    
    const handleAddressContact = ({ target }) => {
        setcontactAddress(target.value)
    }
    const handleBoxContact = ({ target }) => {
        setcontactBox(target.value)
    }
    const handleCityContact = ({ target }) => {
        setcontactCity(target.value)
    }
    const handleCodeContact = ({ target }) => {
        setcontactCode(target.value)
    }
    const handleCountryContact = e => {
        consoleLog('handleCountryContact',e.value);
        setContactCountry(e.value);
        setContactState("");
        if(e.value == 223){
            setContactStates(A_STATES);
        }else{
            setContactStates(C_STATES);
        }
    }
    const handleStateContact = e => {
        consoleLog('handleStateContact',e.value);
        setContactState(e.value);
    }
    const [isPickerActive, setPickerActive] = useState(false);

    // When the color picker is focused
    const handleFocus = () => {
      setPickerActive(true);
    };
  
    // When the color picker changes or loses focus
    const handleBlur = () => {
      setPickerActive(false);
    };

    async function updateContactInfo(){
        consoleLog('updateContactInfo, addressIdToUpdate>', addressIdToUpdate);
        consoleLog('updateContactInfo contactIdToUpdate>', contactIdToUpdate);
        setShowAddNewContactLabel(true);
        let nam = contactFirst_name+' '+contactLast_name;
        let con = {};

        let contacts_copy = [...contacts];
        contacts_copy = contacts_copy.filter((t) => t.address_id != addressIdToUpdate);
        consoleLog("updateContactInfo contacts_copy>", contacts_copy);

        let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;

        con.name = nam;
        con.company= contactCompany_name;
        con.address= contactAddress;
        con.address_2= contactBox;
        con.city= contactCity;
        con.postcode= contactCode;
        con.country_id= contactCountry;
        con.zone_id= contactState;
        con.locked= 0;
        con.status= 1;
        con.contact_id=contactIdToUpdate;
        con.customer_id= userId;
        con.type= 'contact';
        con.company= contactCompany_name;
        con.first_name= contactFirst_name;
        con.last_name= contactLast_name;
        con.email= contactEmail;
        con.phone= contactPhone;
        con.address_id= addressIdToUpdate;
        con.template_id= 0;
        con.used_default= 1;

        contacts_copy.push(con);
        let selected_contact = contacts_copy.find(con => con.contact_id == contactId);
        setContacts(contacts_copy);
        if(selected_contact){
            setSelectedContact(selected_contact);
        }

        const update_address = await API.graphql({
            query: updateContactAddress,
            variables: {
                address_id:addressIdToUpdate,
                name: nam,
                company: contactCompany_name,
                address: contactAddress,
                address_2: contactBox,
                city: contactCity,
                postcode: contactCode,
                country_id: contactCountry,
                zone_id: contactState
            }
        });
        consoleLog('updateContactInfo, update_address>', update_address);

        const update_contact = await API.graphql({
            query: updateContactInfox,
            variables: {
                contact_id:contactIdToUpdate,
                company: contactCompany_name,
                first_name: contactFirst_name,
                last_name: contactLast_name,
                email: contactEmail,
                phone: contactPhone
            }
        });
        consoleLog('updateContactInfo, update_address>', update_contact);

        setcontactCompany_name("");
        setcontactFirst_name("");
        setcontactLast_name("");
        setcontactEmail("");
        setcontactPhone("");
        setcontactAddress("");
        setcontactBox("");
        setcontactCity("");
        setcontactCode("");
        setContactCountry(0);
        setContactState(0);
        setIsUpdate(false);
    }

    async function handleClick() {

        consoleLog('handleClick company name', contactCompany_name);
        consoleLog('handleClick first name', contactFirst_name);
        consoleLog('handleClick last name', contactLast_name);
        consoleLog('handleClick email', contactEmail);
        consoleLog('handleClick phone', contactPhone);
        consoleLog('handleClick address', contactAddress);
        consoleLog('handleClick po bcx', contactBox);
        consoleLog('handleClick city', contactCity);
        consoleLog('handleClick zip code', contactCode);
        consoleLog('handleClick country', contactCountry);
        consoleLog('handleClick state', contactState);

        setShowAddNewContactLabel(true);

        let nam = contactFirst_name+' '+contactLast_name;

        let con = {};

        const create_address = await API.graphql({
            query: createContactAddress,
            variables: {
                name: nam,
                company: contactCompany_name,
                address: contactAddress,
                address_2: contactBox,
                city: contactCity,
                postcode: contactCode,
                country_id: contactCountry,
                zone_id: contactState,
                locked: 0,
                status: 1
            }
        });
        consoleLog('handleClick create_address', create_address);
        let address_id = JSON.parse(create_address.data.createContactAddress).insertId;
        consoleLog('handleClick address_id', address_id);

        let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;

        const create_contact = await API.graphql({
            query: createContactInfo,
            variables: {
                customer_id: userId,
                type: 'contact',
                company: contactCompany_name,
                first_name: contactFirst_name,
                last_name: contactLast_name,
                email: contactEmail,
                phone: contactPhone,
                address_id: address_id,
                template_id: 0,
                used_default: 1
            }
        });
        consoleLog('handleClick create_contact', create_contact);
        let contact_id = JSON.parse(create_contact.data.createContactInfo).insertId;
        consoleLog('handleClick contact_id', contact_id);

        con.name = nam;
        con.company= contactCompany_name;
        con.address= contactAddress;
        con.address_2= contactBox;
        con.city= contactCity;
        con.postcode= contactCode;
        con.country_id= contactCountry;
        con.zone_id= contactState;
        con.locked= 0;
        con.status= 1;
        con.contact_id=contact_id;
        con.customer_id= userId;
        con.type= 'contact';
        con.company= contactCompany_name;
        con.first_name= contactFirst_name;
        con.last_name= contactLast_name;
        con.email= contactEmail;
        con.phone= contactPhone;
        con.address_id= address_id;
        con.template_id= 0;
        con.used_default= 1;

        let contacts_copy = [...contacts];
        contacts_copy.push(con);
        setContacts(contacts_copy);
        console.log("contacts_copy", contacts_copy);

        setcontactCompany_name("");
        setcontactFirst_name("");
        setcontactLast_name("");
        setcontactEmail("");
        setcontactPhone("");
        setcontactAddress("");
        setcontactBox("");
        setcontactCity("");
        setcontactCode("");
        setContactCountry(0);
        setContactState(0);
    }

    function closeAddContact(){
        setShowAddNewContactLabel(true);
        setcontactCompany_name("");
        setcontactFirst_name("");
        setcontactLast_name("");
        setcontactEmail("");
        setcontactPhone("");
        setcontactAddress("");
        setcontactBox("");
        setcontactCity("");
        setcontactCode("");
        setContactCountry(0);
        setContactState(0);
        setIsUpdate(false);
    }

    function openAddContact(){
        setShowAddNewContactLabel(false);
        setIsUpdate(false);
    }

    async function handleContactSearch(e){
        let value = e.target.value;
        consoleLog('handleContactSearch value', value);
        setContactSearch(value);
        getSearchedContacts(value);
    }

    async function getSearchedContacts(value = '', limit = contactsLimit){

        let client_id = JSON.parse(secureLocalStorage.getItem('client_data')).client_id;
        const results = await API.graphql({
            query: getSearchedContactsByClientID,
            variables: {
                client_id: client_id,
                type: "contact",
                search: value,
                limit: limit,
                selected_contact: contactId
            }
        })
        let data = JSON.parse(results.data.getSearchedContactsByClientID);
        let searched_contacts = data.body?.contacts;
        let total_contacts = data.body?.total_contacts?.[0]?.count;

        consoleLog("onSearchFromContact results", results);
        consoleLog("onSearchFromContact data", data);
        consoleLog("onSearchFromContact searched_contacts", searched_contacts);
        consoleLog("onSearchFromContact total_contacts", total_contacts);

        if(searched_contacts){
            setContacts(searched_contacts);
            let selected_contact = searched_contacts.find(contact=>contact.contact_id==contactId);
            if(selected_contact){
                setSelectedContact(selected_contact);
            }
        }
        if(total_contacts){
            setTotalContacts(total_contacts);
        }
    }

    async function showImageManager() {
        setImageManger(!imageManger);
    }

    async function getFileList(customerIds) {
        setLoading(true);
        const getFileList = await API.graphql({
            query: getFiles,
            variables: {
                customerIds: customerIds
            }
        })

        let images = getFileList.data.getFiles ? JSON.parse(getFileList.data.getFiles) : [];
        setImages(images);
        setLoading(false);
    }

    async function uploadImage(folderId, customerId, fileList) {
        
        const getFile = await API.graphql({
            query: uploadFile,
            variables: {
                folderId: folderId,
                customerId: customerId,
                fileList: JSON.stringify(fileList)
            }
        })

        return getFile.data.uploadFile ? JSON.parse(getFile.data.uploadFile) : [];
    }

    async function deleteImage() {
        
        const file = deleteImageInfo;

        deleteImageIndex.push(file.file_id);
      
        setImageIndex(file.file_id);
        setDeleteImageIndex(deleteImageIndex);

        const result = await API.graphql({
            query: deleteFile,
            variables: {
                fileName: file.name,
                customerId: customerId,
                fileId: file.file_id
            }
        });

        const response = result.data.deleteFile ? JSON.parse(result.data.deleteFile) : [];

        if(response.statusCode === 200) {
            let updatedImages = images.filter(function(image) { return !deleteImageIndex.includes(image.file_id) }); 
            
            setImageIndex(file.file_id);
            setImages(updatedImages);

        }else {
            setErrorMessage(response.message);
        }
    }

    function checkContactFormValidity(){

        let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;   
        
        if(contactCompany_name == "" && contactFirst_name == "" && contactLast_name == "" && contactEmail == "" && contactPhone == "" && contactAddress == "" && contactCity == "" && contactCountry == "" && contactState == "" && contactCode == ""){
            console.log("All fields empty");
            return false;
        }
        
        if(contactEmail != "" && emailRegex.test(contactEmail) == false){
            contactEmail__ref.current.setCustomValidity("Please enter a valid Email");
            contactEmail__ref.current.reportValidity();
            return false;
        }

        return true;
    }

    return (
        <DocSettingSection>
            {
                showDeleteModal &&
            <GeneralPopup 
            titleText="Are You Sure?"
            bodyText={<>Are you sure you want to <b>delete</b> this file?</>}
            showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                confirm={deleteImage}
                />
            }
            { (templateInfo.doc_type_id == 1) &&
            <div className="detail_heading_row p-5">
                <p className="doc_setting_heading mb-2">DISPLAY OPTIONS</p>
                <p className="doc_setting_description mb-4 fs-inherit">Choose which sections of your Line Items you want to display on the current document</p>
                { docOptions &&
                <Row>
                    <Col xs={12} sm={12} md={6} lg={4}>
                        <div className="field name">
                            <input type="checkbox" name="show_structure_price" checked={props.displayStructurePrice == 1 ? true : false} onChange={handleDisplayStructurePrice}/>
                            <span className="detail_heading_label"> Display Structure Price</span>
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={4}>
                        <div className="field location">
                            <input type="checkbox" name="show_categories" checked={props.displayCategories == 1 ? true : false} onChange={handleDisplayCategories}/>
                            <span className="detail_heading_label"> Display Categories</span>
                        </div>

                        <div className="field ref-id mt-4">
                            <input type="checkbox" name="show_category_price" checked={props.displayCategoryPrice == 1 ? true : false} onChange={handleDisplayCategoryPrice}/>
                            <span className="detail_heading_label"> Display Category Price</span>
                        </div>
                    </Col>
                </Row>
                }
            </div>
            }

            { !props.is_doc &&
            <div className={`detail_heading_row pr-5 pl-5 pb-5 ${ (templateInfo.doc_type_id == 1) ? "pt-5  border-top" : ""}`}>
                <p className="doc_setting_heading mb-2">TEMPLATE DESCRIPTION</p>
                <p className="doc_setting_description mb-4 fs-inherit">Enter a description for your template.</p>
                
                <Row className="m-0">
                    { showTemplateLabel 
                    ? <div className="doc_lbl w-50">
                        <p className="mr-4 overflow-text">{templateDescription}</p>
                        <span className="cursor-pointer" onClick={() => setShowTemplateLabel(false)}><FaPencilAlt /></span>
                     </div>
                    : <div className="doc_lbl w-50">
                        <input type="text" name="doc_label" value={templateDescription} className="setting-input" onChange={handleOnChangeTemplateDescription}/>
                        <span className="cursor-pointer ml-3 mt-2" onClick={updateTemplateDescription}><FaCheck /></span>
                     </div>
                    }
                </Row>
            </div>
            }

            <div className="detail_heading_row p-5 border-top">
                <p className="doc_setting_heading mb-2">DOCUMENT LABEL</p>
                <p className="doc_setting_description mb-4 fs-inherit">Choose a label for your current document such as "Estimate", "Bid", or "Claim"</p>
                
                <Row className="m-0">
                    { showDocLabel 
                    ? <div className="doc_lbl">
                        <p className="mr-4 overflow-text">{docLabel}</p>
                        <span className="cursor-pointer" onClick={() => setShowDocLabel(false)}><FaPencilAlt /></span>
                     </div>
                    : <div className="doc_lbl">
                        <input type="text" name="doc_label" value={docLabel} className="setting-input"  onChange={handleOnChangeDocLabel}/>
                        <span className="cursor-pointer ml-3 mt-2" onClick={updateDocLabel}><FaCheck /></span>
                     </div>
                    }
                </Row>
            </div>

            <div className="detail_heading_row p-5 border-top">
                <p className="doc_setting_heading mb-2">INVOICE LABEL</p>
                <p className="doc_setting_description mb-4 fs-inherit">Choose a label to appear on the Invoice document such as "Labor Invoice" or "Project Invoice"</p>
                
                <Row className="m-0">
                    { showInvoiceLabel 
                    ? <div className="doc_lbl">
                        <p className="mr-4 overflow-text">{invoiceLabel}</p>
                        <span className="cursor-pointer" onClick={() => setShowInvoiceLabel(false)}><FaPencilAlt /></span>
                     </div>
                    : <div className="doc_lbl">
                        <input type="text" name="doc_label" value={invoiceLabel} className="setting-input"  onChange={handleOnChangeInvoiceLabel}/>
                        <span className="cursor-pointer ml-3 mt-2" onClick={updateInvLabel}><FaCheck /></span>
                     </div>
                    }
                </Row>
            </div>

            <div className="detail_heading_row p-5 border-top">
                <p className="doc_setting_heading mb-4">
                    CONTACT INFO
                    { showContactLabel
                    ? <a className="s_nav_item ml-3" onClick={renderContactSection}><span><img src={PEdit} style={{width:'26px', height:'auto', marginBottom:'5px'}}/></span> </a>
                    : <a className="s_nav_item ml-3" onClick={renderContactSection}><span><img src={Check} style={{width:'26px', height:'auto', marginBottom:'5px'}}/></span> </a>
                    }
                </p>
                
                <div className="project-info-fields on-always amp-toggle-content is-dormant">
                    <div id="contact-info">
                        <div className="field first_name">
                            <span className="detail_heading_label">Company:</span>
                            <span className="detail_title_text ml-3">{selectedContact.company ? selectedContact.company :  companyName}</span>
                        </div>

                        <div className="field last_name mt-4">
                            <span className="detail_heading_label">Name:</span>
                            <span className="detail_title_text ml-3">{selectedContact.first_name ? selectedContact.first_name : defContactFirstName} {selectedContact.last_name ? selectedContact.last_name : defContactLastName}</span> 

                        </div>

                        <div className="field location mt-4">
                            <span className="detail_heading_label">Email:</span>
                            <span className="detail_title_text ml-3">{selectedContact.email ? selectedContact.email : defContactEmail}</span> :
                        </div>

                        <div className="field ref-id mt-4">
                            <span className="detail_heading_label">Phone:</span>
                            <span className="detail_title_text ml-3" >{selectedContact.phone ? selectedContact.phone : defContactPhoneNo}</span>
                        </div>

                        <div className="field ref-id mt-4">
                            <span className="detail_heading_label">Address:</span>
                            <span className="detail_title_text ml-3 address">
                                Street address : {selectedContact.address ? selectedContact.address : '-'} <br />
                                Apt # / P.O Box : {selectedContact.address_2 ? selectedContact.address_2 : '-'}<br />
                                City : {selectedContact.city ? selectedContact.city : '-'}<br />
                                Zip Code : {selectedContact.postcode ? selectedContact.postcode : '-'}<br />
                            </span>
                        </div>
                    </div>
                    { !showContactLabel &&
                    
                    <>
                        <div className="tempInputx mt-4">
                            <p  className="fs-inherit" style={{marginBottom:"5px"}}>Search for an existing contact by company, name, email or phone.</p>
                            <div style={{display:"flex", width:"100%", paddingBottom:"0px"}}>
                                <input type="text" className="setting-input m-0" placeholder='Search by any field' onChange={handleContactSearch} value={contactSearch}/>
                                <a className="srchBtn"><img src={searchImg} /></a>
                            </div>
                        </div>
                        <Row className="contact_info_box ml-0 mr-0 mt-3">
                            { showAddNewContactLabel 
                                ? <h5 className="cursor-pointer border-bottom pb-3 w-100" style={{color:'#212E33', fontFamily:'Roboto'}} onClick={openAddContact}><span className="ml-3 mr-2"><FaPlus color={'#F7941C'} size={16}/></span>Add New Contact</h5>
                                : <h5 className="cursor-pointer border-bottom pb-3 w-100" style={{color:'#212E33', fontFamily:'Roboto'}} onClick={closeAddContact}><span className="ml-3 mr-2"><img src={Cross} style={{width:'22px', height:'auto'}}/></span>Cancel</h5>
                            }
                            <div className="w-100 contact_info_add_cancel">

                                { !showAddNewContactLabel 
                                ? 
                                    <Row className="m-0">
                                        <Col xs={12} sm={12} md={6}>
                                            <input type="text"  placeholder="Company Name" onChange={handleCompanyNameContact} ref={contactCompany_name__ref} value={contactCompany_name} className="setting-input mb-2"/>
                                            <input type="text" placeholder="First Name" onChange={handleFirstNameContact} ref={contactFirst_name__ref} value={contactFirst_name} className="setting-input mb-2"/>
                                            <input type="text" placeholder="Last Name" onChange={handleLastNameContact} ref={contactLast_name__ref} value={contactLast_name} className="setting-input mb-2"/>
                                            <input type="text" placeholder="Email" onChange={handleEmailContact} ref={contactEmail__ref} value={contactEmail} className="setting-input mb-2"/>
                                            <input type="text" placeholder="Phone" onChange={handlePhoneContact} ref={contactPhone__ref} value={contactPhone} className="setting-input mb-2"/>
                                           
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <input type="text" placeholder="Street Address" onChange={handleAddressContact} ref={contactAddress__ref} value={contactAddress} className="setting-input mb-2"/>
                                            <input type="text" placeholder="Apt # / P.O Box" onChange={handleBoxContact} ref={contactBox__ref} value={contactBox} className="setting-input mb-2"/>
                                            <input type="text" placeholder="City" onChange={handleCityContact} ref={contactCity__ref} value={contactCity} className="setting-input mb-2"/>

                                            <Col xs={12} sm={12} md={6} className="p-0 mb-3">
                                                <Select
                                                    value={COUNTRIES.find(obj => obj.value == contactCountry)}
                                                    ref={contactCountry__ref}
                                                    options={COUNTRIES} 
                                                    onChange={handleCountryContact}
                                                    components={{DropdownIndicator}}
                                                    className="w-95 float-left"
                                                    defaultValue=""
                                                    styles={{
                                                        control: (provided, state) => ({
                                                          ...provided,
                                                          boxShadow: "none",
                                                          border: state.isFocused && "#B4BEBF"
                                                        }),
                                                        menu: (provided, state) => ({
                                                          ...provided,
                                                          border:"1px solid #B4BEBF",
                                                          boxShadow: "none",
                                                          border: state.isFocused && "1px solid #B4BEBF"
                                                        }),
                                                        placeholder:(provided, state) => ({
                                                            ...provided,
                                                            color:'#B4BEBF',
                                                            fontStyle:'italic'
                                                        }),
                                                        option: (provided, state) => ({
                                                           ...provided,
                                                           backgroundColor: state.isFocused && "#F7F9FA",
                                                           color: state.isFocused && "#212E33"
                                                        })
                                                      }}


                                                />
                                            </Col>
                                            
                                            <Col xs={12} sm={12} md={6} className="p-0 mb-3">
                                                <Select
                                                    value={contactStates.find(obj => obj.value == contactState )}
                                                    ref={contactState__ref}
                                                    options={contactStates} 
                                                    onChange={handleStateContact}
                                                    components={{DropdownIndicator}}
                                                    className="w-95 float-right"
                                                    defaultValue=""
                                                    styles={{
                                                        control: (provided, state) => ({
                                                          ...provided,
                                                          boxShadow: "none",
                                                          border: state.isFocused && "#B4BEBF"
                                                        }),
                                                        menu: (provided, state) => ({
                                                          ...provided,
                                                          border:"1px solid #B4BEBF",
                                                          boxShadow: "none",
                                                          border: state.isFocused && "1px solid #B4BEBF"
                                                        }),
                                                        placeholder:(provided, state) => ({
                                                            ...provided,
                                                            color:'#B4BEBF',
                                                            fontStyle:'italic'
                                                        }),
                                                        option: (provided, state) => ({
                                                           ...provided,
                                                           backgroundColor: state.isFocused && "#F7F9FA",
                                                           color: state.isFocused && "#212E33"
                                                        })
                                                      }}
                                                />
                                            </Col>
                                            
                                            <input type="text" placeholder="Zip Code" onChange={handleCodeContact} ref={contactCode__ref} value={contactCode} className="setting-input mb-2" />
                                        </Col>
                                        <div className="w-100 d-flex align-item-center justify-content-center">
                                              <Button className="btn_save_contact mt-4 mb-3" onClick={ ()=>{
                                                if(checkContactFormValidity()){
                                                    if(isUpdate){
                                                        updateContactInfo();
                                                    }else{
                                                        handleClick()
                                                    }
                                                }
                                              }}>SAVE CONTACT </Button>             
                                        </div>
                                       
                                    </Row>
                                :
                                    contacts.length > 0 && contacts.sort((a, b) => a.company.localeCompare(b.company)).map((contact, i) => (
                                        <Row className={`m-0 p-2 pl-5 pr-5 border-bottom ci-row ${contact.contact_id == contactId && "contact_info_list_selected"}`}>
                                            <div style={{width:"2% !important"}}>
                                                { (contact.contact_id == contactId) &&
                                                    <FaCheck style={{ position:"relative", color:"#00daa1", top:"90%", left:"1%"}}/>
                                                }
                                            </div>
                                            <div className="contact_info_list w-100 pl-5">
                                                
                                                <div className="field ref-id mt-1 float-right">
                                                    <span className="detail_heading_label fs-small" style={{cursor:"pointer"}} onClick={ () => removeContactInfo(contact)}><FaTrashAlt /></span>
                                                </div>

                                                <div onClick={()=>updateContactId(contact.contact_id, contact)} style={{cursor: "pointer"}}>
                                                    <div className="field first_name">
                                                        <span className="detail_heading_label fs-small">{contact.company}</span>
                                                    </div>

                                                    <div className="field last_name mt-1">
                                                        <span className="detail_heading_label fs-small">Name:</span>
                                                        <span className="detail_title_text ml-1 fs-smaller">{contact.first_name + " " + contact.last_name}</span>
                                                    </div>

                                                    <div className="field location mt-1">
                                                        <span className="detail_heading_label fs-small">Email:</span>
                                                        <span className="detail_title_text ml-1 fs-smaller">{contact.email}</span>
                                                    </div>

                                                    <div className="field ref-id mt-1">
                                                        <span className="detail_heading_label fs-small">Phone:</span>
                                                        <span className="detail_title_text ml-1 fs-smaller" >{contact.phone}</span>
                                                    </div>

                                                    <div className="field ref-id mt-1">
                                                        <span className="detail_heading_label fs-small">Address:</span>
                                                        <span className="detail_title_text ml-3 address">
                                                        Street address : {contact.address ? contact.address : '-'} <br />
                                                        Apt # / P.O Box : {contact.address_2 ? contact.address_2 : '-'}<br />
                                                        City : {contact.city ? contact.city : '-'}<br />
                                                        Zip Code : {contact.postcode ? contact.postcode : '-'}<br />
                                                        </span>
                                                    </div>
                                                </div>
        
                                            </div>
                                            <h5 className="cursor-pointer fs-smaller" onClick={() => EditContactInfo(contact.address_id)}>
                                                <span className="ml-5 mr-1"><FaPencilAlt/></span>
                                                Edit Contact Info
                                            </h5>
                                        </Row>
                                    ))
                                }
                            </div>
                            {
                                showAddNewContactLabel && (totalContacts > contactsLimit) && <div className="border-top w-100">
                                    <button className="load-more-btn" onClick={loadMoreContacts}>
                                        {loadingMoreContacts
                                        ? <>Loading <span style={{marginLeft: '10px'}}><FaCircleNotch size={20} className="awesome_spinner"/></span></>
                                        : 'Load More'}
                                    </button>
                                </div>
                            }
                        </Row>
                    </>
                    }

                </div>
            </div>
        
            <div className="detail_heading_row p-5 border-top">
                <p className="doc_setting_heading mb-2">BRANDING</p>
                <p className="doc_setting_description mb-4 fs-inherit">Choose a logo and color for your current document.</p>
                <div className="project-info-fields on-always amp-toggle-content is-dormant">
                    <div id="contact-info">
                        <div className="field color d-flex flex-column align-item-center mb-2">
                            <span className="detail_heading_label mb-2">Your color:</span>
                            <input type="color" className="f w-25" value={color} onChange={e => setColorOptions(e.target.value)}  
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            />
                        </div>
                        <div className={`field logo  ${isPickerActive ? 'logomargin' : ''}`}>
                            <span className="detail_heading_label">Your logo:</span>
                            <div className="mb-3 doc_logo">
                                <img src={logo} />
                            </div> 

                            <div class="edit_logo">
                                <a className="s_nav_item mt-4 d-inline-flex align-items-center" onClick={showImageManager}>
                                    <span className='image-spacing'>{imageManger ? <img src={Check} style={{width:'26px', height:'auto'}} /> : <FaPencilAlt/> }</span> 
                                    {imageManger ? 'Done' : 'Change Logo'}  
                                </a>

                                <a className="s_nav_item mt-4 d-inline-flex align-items-center" onClick={() => setLogoOptions(`/`)} >
                                    <span className='image-spacing'><img src={Remove} style={{width:'14px', height:'auto'}} /></span> Remove Logo
                                </a>
                            </div>                           
                            
                            {imageManger && <div className="upload_img">
                                {loading && <div className="amp_fm_loading">
                                    <div className="align_middle"></div>
                                    <img className="loader" src={Loader} />		
                                </div> }
                                <a  href="javascript:;" className="m-0 text-left uploader_text"  onClick={() => updateFolder(props.url_params.doc_id, false)}>Home </a>
                                {(!uploading && showAU) && <a  href="javascript:;" className="m-0 text-left uploader_text">&nbsp;/ Logos</a>}       
                                <div className="align">
                                    {errorMessage && <div class="alert alert-danger" role="alert">
                                        <strong>Error!</strong> { errorMessage }.
                                    </div>}
                                    <ImageUploading
                                        multiple
                                        value={images}
                                        onChange={handleImageUpload}
                                        maxNumber={maxNumber}
                                        acceptType={['jpg', 'png']}
                                        dataURLKey="data_url"
                                        resolutionType="more"  // Enforce resolution based on height
                                        resolutionHeight={200}   // Minimum height is set to 200px
                                        resolutionWidth={1}
                                    >
                                        {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                        errors
                                        }) => (
                                            // write your building 
                                            <div className="upload__image-wrapper" {...dragProps}>
                                              
                                                <button
                                                    className="uploader"
                                                    onClick={onImageUpload}
                                                    >
                                                    <p className="uploader_text"><b><u>Click Here</u></b> or <b>Drag and Drop</b> to add your logo.</p>
                                                    <p className="uploader_desc">.png or.jpg files with a minimum height of 200px</p>
                                                </button>

                                                {errors && errors.resolution && setErrorMessage("Image height must be atleast 200px")}
                                                {!errors && setErrorMessage("")}
                                                    

                                                {Array.isArray(imageList) && imageList.map((image, index) => {
                                                    if(!image.file_id && !image.folder_id) {
                                                        image.folder_id = folderId;
                                                    }
                                                    
                                                    if(image.folder_id == folderId) {
                                                        return(<div key={index} className="box2">
                                                            {image.file_id ?
                                                                <>
                                                                    {deleteImageIndex.includes(image.file_id) ? <FaCircleNotch size={100} className="awesome_spinner" style={{color:'#414141'}}/> :
                                                                    <>
                                                                        <a style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center'}} href="javascript:;" onClick={() => setLogoOptions(`${image.url}#file_id=${image.file_id}`)} >
                                                                            <img src={image.file_id ? image.url : image['data_url']} alt="" /> 
                                                                        </a>
                                                                        {image.customer_id === customerId && <button onClick={() => onImageRemove(index)}><FaTrashAlt size={15}/></button>}
                                                                    </>}
                                                                </> : 
                                                               <FaCircleNotch size={100} className="awesome_spinner" style={{color:'#414141'}}/>}
                                                        </div>)
                                                    }
                                                })}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

        </DocSettingSection>
    )
}



 


const DocSettingSection = styled.div`
    color: #414141;
    .css-tlfecz-indicatorContainer{
        background:none !important;
    }
    .dropdown-menu.show{
        @media (max-width: 600px)
            {
                left: -3px !important;
                top: 0px !important;
                margin-left:40px;
            }
    }
    .edit_logo {
        display: flex;
        gap: 25px;
        font-family: 'Roboto';
    }
    .tempInputx{
        
        @media(max-width:600px){
            width:100%;
        }
    }
    .logomargin{
        margin-top:270px;
    }
    .uploader_text{
        color: #212E33;
        font-family: Rajdhani;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 0;
    }
    .uploader_desc{
        color: #212E33;
        font-family: Rajdhani;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 8px;
    }
    .upload_img{
        width:100%;
        min-height:300px;
        border:1px solid #B4BEBF;
        display:flex;
        align-items:flex-start;
        justify-content:flex-start;
        position: relative;
        flex-wrap:wrap;
        padding:10px;
        margin:10px 0;

        @media(max-width:600px){
            min-height:460px;
        }

        .amp_fm_loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            background: rgba(255,255,255,0.8);
            text-align: center;
        }

        .align_middle {
            display: inline-block;
            vertical-align: middle;
            height: 100%;
            width: 0;
            margin-right: -.3em !important;
        }

       .align{
           width:100%;
           display:flex;
           align-items:center;
           justify-content:center;
           flex-wrap:wrap;
        
           .upload__image-wrapper{
               width:100%;
               display:flex;
               align-items:center;
               justify-content:center;
               flex-wrap:wrap;
               margin-bottom: 6%;
               overflow-y: scroll;
               height: 370px;
               overflow: hidden; 
           }

           .uploader{
            position: absolute;
            bottom:0;
            background:transparent;
            text-align:center;
            border:none;
            outline:none;
            width:100%;

             .uploader_text{
                font-size: 16px;
                margin-bottom: 15px
            }
           }
       }

        .box{
            height:200px;
            width:200px;
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column;
            border:1px solid #bdbdbd;
            border-radius:5px;
            margin:20px;
            position: relative;

            &:hover{
            background:rgba(0,0,0,0.1);
        }
        }

        .box2{
            height:200px;
            width:200px;
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column;
            border:1px solid #bdbdbd;
            border-radius:5px;
            margin:10px;
            padding:10px;
            position: relative;
            &:hover{
            background:rgba(0,0,0,0.1);
             }

         img{
             width:150px;
             height:auto;
         }    
        button{
                position: absolute;
                bottom:1px;
                right:1px;
                border:none;
                outline:none;
                background:transparent;
            }
        }
    }

    .css-tlfecz-indicatorContainer{
        background: #414141;
        color: #ffffff;
    }

    .css-yk16xz-control{
        border-color: #000000;
        border-radius: 0px;
    }

    .contact_info_list{
        font-size: small !important; 
    }
    .detail_heading_label{
        color: #212E33;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
    }

    .image-spacing {
        display: flex;
        align-items: center;
        margin-right: 5px;
    }
    .w-95{
        width: 95% !important;
    }
    .w-50{
        width: 50% !important;
    }
    .w-25{
        width: 25% !important;

        @media(max-width:600px){
            width: 50% !important;
        }
    }
    .w-15{
        width: 15% !important;
    }
    .color-red {
        color: red !important;
    }
    .doc_setting_heading{
        color: #212E33;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: -1.24px;
    }
    .doc_setting_heading_label {
        display: inline;
        line-height: 1;
        text-align: left;
        white-space: nowrap;
        vertical-align: baseline;
        color: #6a6e6f;
        border-radius: .25em;
        font-size: medium;
    }
    .fs-inherit{
        color: #212E33;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom:34px;
    }
    .setting-input{
        height:40px;
        width:100%;
        outline:none;
        padding:10px;
        border-radius:4px;
        border:1px solid #B4BEBF;
        color:#212E33;
        font-style: normal;
        font-size:14px;
    }
    .fs-medium{
        font-size: medium;
    }
    .fs-small{
        font-size: small !important;
    }
    .fs-smaller{
        font-size: smaller !important;
    }
    .border-top{
        border-top: 1px solid #bdbdbd;
    }
    .border-bottom{
        border-bottom: 1px solid #bdbdbd;
    }
    .contact_info_list_selected{
        background: #c7f9ee;
    }
    .ci-row:hover{
        background:#f2f9f2;
    }
    .doc_lbl{
        display: flex;
    }

    .overflow-text {
        overflow-wrap: anywhere;
    }
    .cursor-pointer{
        cursor: pointer;

        &:hover  {
            color: #F7941C !important;
          }
    }
    .contact_info_box{
        border: 1px solid #B4BEBF;
        border-radius: 5px;
    }
    .ip{
        width:100%;
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border-color: #B4BEBF !important;
        border-radius: 0px !important;
        border-style: solid;
        border-width: 1px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0!important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;
        padding:2px 8px;
        margin: 1rem 0px;
    }
    .btn_save_contact{
        height: 50px;
        width: 50%;
        color: #212E33;
        font-family: Rajdhani;
        background-color: #F7941C !important;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.6px;
        line-height: 21px;
        text-align: center;
        outline:none;
        border:none;
        &:hover{
            background-color:#000 !important;
            color:#fff !important;
        }
    }
   
    .address{
        display: inline-block;
        vertical-align: top;
    }

    .s_nav_item {
        &:hover  {
            color: #F7941C !important;
          }
    }

    .doc_logo {
        height: 50px;
        min-width: 150px;

        img {
            max-height: 100%;
            max-width: 90%;
        }
    }
    .load-more-btn{
        height: 44px;
        width: 169px;
        border-radius: 3px;
        background-color: #F7941C;
        color: #212E33;
        border: none;
        outline: none;
        font-family: Rajdhani;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.6px;
        line-height: 21px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px auto;
    }
    .load-more-btn:hover {
        color: white;
        background-color: black;
    }
    .contact_info_add_cancel{
        max-height: 400px;
        overflow-y: auto;
    }
`
